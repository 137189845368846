import { PiniaPluginContext } from 'pinia'
import { I18n } from 'vue-i18n'

declare module 'pinia' {
  export interface PiniaCustomProperties {
    $i18n: I18n
  }
}

export function createPiniaI18nPlugin(i18n: I18n) {
  return function piniaI18nPlugin(context: PiniaPluginContext) {
    context.store.$i18n = i18n
  }
}
