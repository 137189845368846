import { TaskModel } from '@ctr-ecs/common/model/task-model'
import { TaskUserModel } from '@ctr-ecs/common/model/task-user-model'
import { TaskDependencyModel } from '@ctr-ecs/common/model/task-dependency-model'
import { TaskDependencyDto, TaskDependencyEvaluationDto, TaskDependencyValidationDto, TaskDto, TaskLinkDto, TaskUserDto } from '../apis/openapi/generated'
import { TaskDependencyEvaluationModel } from '@ctr-ecs/common/model/task-dependency-evaluation-model'
import { TaskDependencyValidationModel } from '@ctr-ecs/common/model/task-dependency-validation-model'
import { TaskLinkModel } from '@ctr-ecs/common/model/task-link-model'

export class TaskMapper {
  mapDtoToModel(dto: TaskDto): TaskModel {
    return dto
  }

  mapTaskUserDtoToModel(dto: TaskUserDto): TaskUserModel {
    return dto
  }

  mapTaskDependencyDtoToModel(dto: TaskDependencyDto): TaskDependencyModel {
    return {
      id: dto.id,
      title: dto.title,
      done: dto.done,
      dueDate: dto.dueDate,
      assignedTo: dto.assignedTo,
      ethicsCommission: dto.ethicsCommission,
      meeting: dto.meeting,
      status: dto.status,
      origin: dto.origin,
      memberStates: dto.memberStates,
      applicationId: dto.applicationId,
      applicationName: dto.applicationName,
      trialId: dto.trialId,
      isObsolete: dto.isObsolete,
      isFaultyDependency: dto.isFaultyDependency,
      externalAssignee: dto.externalAssignee
    }
  }

  mapToDependencyEvaluationModel(dto: TaskDependencyEvaluationDto): TaskDependencyEvaluationModel {
    return {
      id: dto.taskId,
      done: dto.done,
      evaluation: dto.evaluation,
      title: dto.title,
      assignedTo: dto.assignedTo,
      description: dto.description
    }
  }

  mapToValidationModel(dto: TaskDependencyValidationDto): TaskDependencyValidationModel {
    return {
      task: this.mapDtoToModel(dto.task),
      blockingPredecessors: dto.blockingPredecessors.map((p) => this.mapTaskLinkDtoToModel(p))
    }
  }

  mapTaskLinkDtoToModel(dto: TaskLinkDto): TaskLinkModel {
    return dto
  }
}
