<template>
  <component :is="layout">
    <slot></slot>
  </component>
</template>

<script lang="ts">
import { Component, DefineComponent, defineComponent, Ref, shallowRef, watch } from 'vue'
import { useRoute } from 'vue-router'
import DefaultLayout from './default.vue'

export default defineComponent({
  setup() {
    const route = useRoute()

    let layout: Ref<Component | DefineComponent> = shallowRef(DefaultLayout)

    watch(() => route.meta.layout, () => {
      if (typeof route.meta.layout === 'function') {
        loadAndSetLayout(route.meta.layout)
      } else {
        layout.value = DefaultLayout
      }
    }, { deep: true, immediate: true })

    async function loadAndSetLayout(loader: () => Promise<unknown>) {
      const hasSymbol = typeof Symbol === 'function' && typeof Symbol.toStringTag === 'symbol'

      try {
        let loadedModule = await loader()
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const moduleDefaultExport = '__esModule' in loadedModule || (hasSymbol && loadedModule[Symbol.toStringTag] === 'Module') ? loadedModule.default : loadedModule

        // a vue component has a render function
        if (moduleDefaultExport && 'render' in moduleDefaultExport && typeof moduleDefaultExport.render === 'function') {
          layout.value = moduleDefaultExport
        } else {
          console.warn('The imported module is not a Vue component.', loadedModule)
        }
      } catch (e) {
        layout.value = DefaultLayout
        console.error('Failed to load layout of route "' + route.fullPath + '". Falling back to default layout.', e)
      }
    }

    return {
      layout
    }
  }
})
</script>
