<template>
  <div>
    <h1>404</h1>
    <p>{{ route.fullPath }} ist nicht erreichbar</p>
    <router-link :to="homeRoutePath">Zurück zur Startseite</router-link>
  </div>
</template>

<script lang="ts" setup>
import { useRoute } from 'vue-router'
import { ROUTE } from '@ctr-ecs/common/config/route'

const route = useRoute()
const homeRoutePath = ROUTE.TASKS
</script>
