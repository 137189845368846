import { CommonUserRepository } from '@ctr-ecs/common/repository'
import { UserControllerApi } from '../apis/openapi/generated'
import { rethrowAsRepositoryError } from '../error/rethrow-as-repository-error'
import { UserDetailModel } from '@ctr-ecs/common/model/user-detail-model'
import { UserMapper } from '@ctr-ecs/repository/mapper/user-mapper'
import { NotificationModel } from '@ctr-ecs/common/model/notification-model'

export class UserRepositoryImpl implements CommonUserRepository {
  constructor(private userRepositoryApi: UserControllerApi, private userMapper: UserMapper) {
  }

  async getCurrentUserDetails(): Promise<UserDetailModel> {
    return rethrowAsRepositoryError(async () => {
      const response = await this.userRepositoryApi.getCurrentUserDetails()
      return this.userMapper.mapToModel(response.data)
    })
  }

  async getCurrentUserNotifications(): Promise<NotificationModel[]> {
    return rethrowAsRepositoryError(async () => {
      const response = await this.userRepositoryApi.getUnreadNotifications()
      return response.data
    })
  }

  async markNotificationAsRead(notificationId: number): Promise<void> {
    return rethrowAsRepositoryError(async () => {
      await this.userRepositoryApi.markNotificationRead(notificationId)
    })
  }

  async markNotificationAsUnread(notificationId: number): Promise<void> {
    return rethrowAsRepositoryError(async () => {
      await this.userRepositoryApi.markNotificationUnread(notificationId)
    })
  }

  async getCurrentUserNotificationArchive(): Promise<NotificationModel[]> {
    return rethrowAsRepositoryError(async () => {
      const response = await this.userRepositoryApi.getArchivedNotifications()
      return response.data
    })
  }
}

