import { CreateUserModel } from '@ctr-ecs/admin/model/create-user'
import { GroupModel } from '@ctr-ecs/admin/model/group'
import { UpdateUserModel } from '@ctr-ecs/admin/model/update-user'
import { UserAdminModel } from '@ctr-ecs/admin/model/user-admin'
import { AdminRepository } from '@ctr-ecs/admin/repository'
import { Ref, unref } from 'vue'
import { useMutation, useQuery, useQueryClient } from 'vue-query'
import { RepositoryError } from '../common/model/exception/repository-exception'
import { useNotifications } from '../notifications/use/useNotifications'
import { PageableUsersQueryModel } from '@ctr-ecs/admin/model/pageable-users-query-model'
import { PageableModel } from '@ctr-ecs/common/model/pageable-model'

let _repository: AdminRepository

export function initialize(repository: AdminRepository) {
  _repository = repository
}

export function useAdminUsersPageableQuery(pageableQuery: PageableQueryQueryParam<PageableUsersQueryModel>) {
  const queryKey = ['admin-users', pageableQuery]
  const queryClient = useQueryClient()

  return useQuery<PageableModel<UserAdminModel>, RepositoryError>(queryKey, () => {
    return _repository.getUsers(unref(pageableQuery))
  }, {
    staleTime: 1000 * 60,
    keepPreviousData: true,
    retry: 3,
    onError: (err) => {
      useNotifications().warning(err.message)
    },
    onSuccess(data) {
      data.items.forEach(user => {
        queryClient.setQueryData(['admin-user', user.id], user)
      })
    }
  })
}


export function useAdminUserQuery(id: string | Ref<string>) {
  const queryKey = ['admin-user', id]

  return useQuery<UserAdminModel, RepositoryError>(queryKey, () => {
    return _repository.getUser(unref(id))
  }, {
    enabled: Boolean(unref(id)).valueOf(),
    onError: (err) => {
      useNotifications().warning(err.message)
    }
  })
}

export function useGroupsQuery() {
  return useQuery<GroupModel[], RepositoryError>('groups', () => {
    return _repository.getGroups()
  }, {
    staleTime: 1000 * 60,
    onError: (err) => {
      useNotifications().warning(err.message)
    }
  })
}

export function useCreateUserMutation() {
  const queryClient = useQueryClient()

  return useMutation<UserAdminModel, RepositoryError, CreateUserModel>('admin-create-user', (model) => {
    return _repository.createUser(model)
  }, {
    onSuccess(data) {
      queryClient.invalidateQueries('admin-users')
      queryClient.setQueryData(['admin-user', data.id], data)
    }
  })
}

export function useUpdateUserMutation() {
  const queryClient = useQueryClient()

  return useMutation<UserAdminModel, RepositoryError, UpdateUserModel>('admin-update-user', (model) => {
    return _repository.updateUser(model)
  }, {
    onSuccess(data) {
      queryClient.invalidateQueries('admin-users')
      queryClient.setQueryData(['admin-user', data.id], data)
    }
  })
}

export function useNotificationTypesQuery() {
  return useQuery<string[], RepositoryError>('notification-types', () => {
    return _repository.getNotificationTypes()
  }, {
    staleTime: 1000 * 60,
    onError: (err) => {
      useNotifications().warning(err.message)
    }
  })
}

export function useSendResetPasswordMailMutation() {
  return useMutation<void, RepositoryError, string>('send-invite-mail', (userId) => {
    return _repository.sendResetPasswordMail(userId)
  })
}

export function useSoftDeleteUserMutation(userId: string | Ref<string>) {
  const queryClient = useQueryClient()
  const queryKey = ['admin-user', userId]

  return useMutation<void, RepositoryError>(queryKey, () => {
    return _repository.softDeleteUser(unref(userId))
  }, {
    retry: 2,
    onError: (err) => {
      useNotifications().warning(err.message)
    },
    async onSuccess() {
      await queryClient.invalidateQueries(['admin-users'])
      await queryClient.invalidateQueries(['admin-user', userId])
    }
  })
}
