import { ApplicationMenuNode } from '@ctr-ecs/common/model/application-menu-model'
import { DocumentModel } from '@ctr-ecs/common/model/document-model'
import { RepositoryError } from '@ctr-ecs/common/model/exception/repository-exception'
import { Node } from '@ctr-ecs/common/model/node'
import { PageableModel } from '@ctr-ecs/common/model/pageable-model'
import { TrialModel } from '@ctr-ecs/common/model/trial-model'
import { TrialSummaryModel } from '@ctr-ecs/common/model/trial-summary-model'
import { useProgressStore } from '@ctr-ecs/common/store/progressStore'
import { useNotifications } from '@ctr-ecs/notifications/use/useNotifications'
import { ApplicationMeta } from '@ctr-ecs/trials/model/application-meta'
import { ConsiderationModel, ExternalConsiderationModel } from '@ctr-ecs/trials/model/consideration'
import { CreateConsiderationModel } from '@ctr-ecs/trials/model/create-consideration'
import { CreateDocumentCommentModel } from '@ctr-ecs/trials/model/create-document-comment'
import { EvaluationDocumentCommentModel, EvaluationDocumentModel } from '@ctr-ecs/trials/model/evaluation-document'
import { TrialSettingsModel } from '@ctr-ecs/trials/model/trial-settings'
import { UpdateConsiderationModel } from '@ctr-ecs/trials/model/update-consideration'
import { UpdateDocumentCommentModel } from '@ctr-ecs/trials/model/update-document-comment'
import { UpdateTrialSettingsModel } from '@ctr-ecs/trials/model/update-trial-settings'
import { UploadEvaluationDocumentModel, UploadEvaluationDocumentVersionModel } from '@ctr-ecs/trials/model/upload-evaluation-document'
import { ApplicationConsiderationsQueryParam, ApplicationDetailsQueryParam, ApplicationDocumentsQueryParam, EvaluationProcess, EvaluationProcessSection } from '@ctr-ecs/trials/types'
import { ref, Ref, unref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useMutation, useQuery, useQueryClient } from 'vue-query'
import { TrialRepository } from './repository'
import { UpdateDocumentNameModel } from '@ctr-ecs/trials/model/update-document-name'
import { PageableTrialQueryModel } from '@ctr-ecs/trials/model/pageable-trial-query-model'
import { UploadApplicationDocumentModel } from './model/upload-application-document'
import { DeleteApplicationDocumentModel } from '@ctr-ecs/trials/model/delete-application-document-model'
import { ApplicationAssigneeTypeModel } from '@ctr-ecs/trials/model/application-assignee-type-model'
import { ApplicationAssigneeModel } from '@ctr-ecs/trials/model/application-assignee-model'
import { UpdateApplicationAssigneeRequestModel } from '@ctr-ecs/trials/model/update-application-assignee-request-model'
import { ConfirmTrialBiasedModel } from '@ctr-ecs/trials/model/confirm-trial-biased'

let _repository: TrialRepository

export function initialize(repository: TrialRepository) {
  _repository = repository
}

export function useTrialsQuery(pageable: PageableQueryQueryParam<PageableTrialQueryModel>) {
  return useQuery<PageableModel<TrialModel>, RepositoryError>(['trials', pageable], async () => {
    return _repository.getTrials(unref(pageable))
  }, {
    retry: 3,
    keepPreviousData: true,
    onError: (err) => {
      useNotifications().warning(err.message)
    }
  })
}

export function useTrialQuery(trialId: string | Ref<string>, opts?: { enabled: boolean | Ref<boolean> }) {
  const queryKey = ['trial', trialId]
  return useQuery<TrialModel, RepositoryError>(queryKey, () => {
    return _repository.getTrial(unref(trialId))
  }, {
    staleTime: 1000 * 60,
    retry: 3,
    enabled: opts?.enabled,
    onError: (err) => {
      useNotifications().warning(err.message)
    }
  })
}

export function useTrialSummaryQuery(trialId: string | Ref<string>) {
  const queryKey = ['trial-summary', trialId]
  return useQuery<TrialSummaryModel, RepositoryError>(queryKey, () => {
    return _repository.getTrialSummary(unref(trialId))
  }, {
    staleTime: 1000 * 60,
    retry: 3,
    onError: (err) => {
      useNotifications().warning(err.message)
    }
  })
}

export function useApplicationMetaQuery(id: string | Ref<string>) {
  const queryKey = ['application-meta', id]
  return useQuery<ApplicationMeta, RepositoryError>(queryKey, () => {
    return _repository.getApplicationMeta(unref(id))
  }, {
    staleTime: 1000 * 60,
    retry: 3,
    onError: (err) => {
      useNotifications().warning(err.message)
    }
  })
}

export function useApplicationSectionsQuery(id: string | Ref<string>) {
  const queryKey = ['application-sections', id]
  return useQuery<ApplicationMenuNode[], RepositoryError>(queryKey, () => {
    return _repository.getApplicationSections(unref(id))
  }, {
    staleTime: 1000 * 60,
    retry: 3,
    onError: (err) => {
      useNotifications().warning(err.message)
    }
  })
}

export function useApplicationDetailsQuery(params: ApplicationDetailsQueryParam | Ref<ApplicationDetailsQueryParam>) {
  const queryKey = ['application-information-mappings', params]
  return useQuery<Node[], RepositoryError>(queryKey, () => {
    return _repository.getApplicationDetails(unref(params))
  }, {
    staleTime: 1000 * 60,
    retry: 3,
    onError: (err) => {
      useNotifications().warning(err.message)
    }
  })
}

export function useApplicationDocumentsQuery(params: ApplicationDocumentsQueryParam | Ref<ApplicationDocumentsQueryParam>) {
  const queryKey = ['application-documents', params]
  return useQuery<DocumentModel[], RepositoryError>(queryKey, () => {
    return _repository.getApplicationDocuments(unref(params).applicationId, unref(params).preferredLanguages, unref(params).preferredPart)
  }, {
    staleTime: 1000 * 60,
    retry: 3,
    onError: (err) => {
      useNotifications().warning(err.message)
    }
  })
}

export function useExternalApplicationConsiderationsQuery(payload: ApplicationConsiderationsQueryParam | Ref<ApplicationConsiderationsQueryParam>) {
  const queryKey = ['external-application-considerations', payload]
  return useQuery<ExternalConsiderationModel[], RepositoryError>(queryKey, () => {
    const payloadData = unref(payload)
    return _repository.getExternalApplicationConsiderations(payloadData.applicationId, payloadData.evaluationProcess, payloadData.filteredMSCs, payloadData.applicationPart)
  }, {
    onError: (err) => {
      useNotifications().warning(err.message)
    }
  })
}

export function useApplicationConsiderationsQuery(payload: ApplicationConsiderationsQueryParam | Ref<ApplicationConsiderationsQueryParam>) {
  const queryKey = ['application-considerations', payload]
  return useQuery<ConsiderationModel[], RepositoryError>(queryKey, () => {
    const payloadData = unref(payload)
    return _repository.getApplicationConsiderations(payloadData.applicationId, payloadData.evaluationProcess, payloadData.applicationPart)
  }, {
    onError: (err) => {
      useNotifications().warning(err.message)
    }
  })
}

export function useTrialSettingsQuery(id: string | Ref<string>) {
  const queryKey = ['trial-settings', id]
  return useQuery<TrialSettingsModel, RepositoryError>(queryKey, () => {
    return _repository.getTrialSettings(unref(id))
  }, {
    staleTime: 1000 * 60 * 5,
    retry: 3,
    onError: (err) => {
      useNotifications().warning(err.message)
    }
  })
}

export interface EvaluationDocumentsQueryPayload {
  applicationId: string
  evaluationProcess: EvaluationProcess
  evaluationProcessSection: EvaluationProcessSection,
  preferredLanguages: string[] | undefined
}

export function useEvaluationDocumentsQuery(payload: EvaluationDocumentsQueryPayload | Ref<EvaluationDocumentsQueryPayload>) {
  const queryKey = ['evaluation-documents', payload]
  return useQuery<EvaluationDocumentModel[], RepositoryError>(queryKey, () => {
    const unwrappedPayload = unref(payload)
    return _repository.getEvaluationDocuments(unwrappedPayload.applicationId, unwrappedPayload.evaluationProcess, unwrappedPayload.evaluationProcessSection, unwrappedPayload.preferredLanguages)
  }, {
    staleTime: 1000 * 60 * 5,
    onError: (err) => {
      useNotifications().warning(err.message)
    }
  })
}

export function useTrialSettingsUpdateMutation() {
  const queryClient = useQueryClient()

  return useMutation<TrialSettingsModel, RepositoryError, UpdateTrialSettingsModel>('trial-settings-update', (model: UpdateTrialSettingsModel) => {
    return _repository.updateTrialSettings(model)
  }, {
    onSuccess: (data, variables) => {
      queryClient.setQueryData(['trial-settings', variables.trialId], data)
      queryClient.invalidateQueries(['trial', variables.trialId])
    },
    onError: (err) => {
      useNotifications().warning(err.message)
    }
  })
}

export function useTrialSynchronizationMutation() {
  const queryClient = useQueryClient()

  return useMutation<TrialModel, RepositoryError, string>('trial-synchronization', (id: string) => {
    return _repository.synchronizeTrial(id)
  }, {
    onSuccess: async (data, variables) => {
      queryClient.setQueryData(['trial', variables], data)
      await queryClient.invalidateQueries(['trial-summary', variables])
    },
    onError: (err) => {
      useNotifications().warning(err.message)
    }
  })
}

export function useConsiderationCreationMutation() {
  const queryClient = useQueryClient()

  return useMutation<ConsiderationModel, RepositoryError, CreateConsiderationModel>('consideration-create', (model: CreateConsiderationModel) => {
    return _repository.createConsideration(model)
  }, {
    onSuccess: async () => {
      await queryClient.invalidateQueries('application-considerations')
    },
    onError: (err) => {
      useNotifications().warning(err.message)
    }
  })
}

export function useConsiderationUpdateMutation() {
  const queryClient = useQueryClient()

  return useMutation<ConsiderationModel, RepositoryError, UpdateConsiderationModel>('consideration-update', (model: UpdateConsiderationModel) => {
    return _repository.updateConsideration(model)
  }, {
    onSuccess: async () => {
      await queryClient.invalidateQueries('application-considerations')
    },
    onError: (err) => {
      useNotifications().warning(err.message)
    }
  })
}

export function useUploadEvaluationDocumentMutation() {
  const queryClient = useQueryClient()
  const progress = ref(0)
  const progressStore = useProgressStore()
  const i18n = useI18n()

  return {
    ...useMutation<EvaluationDocumentModel, RepositoryError, UploadEvaluationDocumentModel | UploadEvaluationDocumentVersionModel>('evaluation-document-upload', (model) => {
      progress.value = 0
      progressStore.addOrUpdateProgress(model.file.name, 0)
      return _repository.uploadNewEvaluationDocument(model, function (progressEvent) {
        progress.value = progressEvent.loaded / progressEvent.total
        progressStore.addOrUpdateProgress(model.file.name, progress.value)
      })
    }, {
      onSettled(data, error, vars) {
        progressStore.remove(vars.file.name)
      },
      onSuccess: async (data, vars) => {
        await queryClient.invalidateQueries('evaluation-documents')
        useNotifications().success(i18n.t('trials.applicationEvaluation.evaluationSections.upload.success', { fileName: vars.file.name }))
      },
      onError: (err, vars) => {
        useNotifications().warning(i18n.t('trials.applicationEvaluation.evaluationSections.upload.error', {
          fileName: vars.file.name,
          error: err.message
        }))
      }
    }),
    progress
  }
}

export function useCreateDocumentCommentMutation() {
  const queryClient = useQueryClient()

  return useMutation<EvaluationDocumentCommentModel, RepositoryError, CreateDocumentCommentModel>('consideration-create', (model) => {
    return _repository.createDocumentComment(model)
  }, {
    onSuccess: async () => {
      await queryClient.invalidateQueries('evaluation-documents')
    },
    onError: (err) => {
      useNotifications().warning(err.message)
    }
  })
}

export function useUpdateDocumentCommentMutation() {
  const queryClient = useQueryClient()

  return useMutation<EvaluationDocumentCommentModel, RepositoryError, UpdateDocumentCommentModel>('consideration-create', (model) => {
    return _repository.updateDocumentComment(model)
  }, {
    onSuccess: async () => {
      await queryClient.invalidateQueries('evaluation-documents')
    },
    onError: (err) => {
      useNotifications().warning(err.message)
    }
  })
}

export function useUpdateDocumentNameMutation() {
  const queryClient = useQueryClient()

  return useMutation<EvaluationDocumentModel, RepositoryError, UpdateDocumentNameModel>('consideration-create', (model) => {
    return _repository.updateEvaluationDocumentName(model)
  }, {
    onSuccess: async () => {
      await queryClient.invalidateQueries('evaluation-documents')
    },
    onError: (err) => {
      useNotifications().warning(err.message)
    }
  })
}

export function useUploadApplicationDocumentMutation() {
  const queryClient = useQueryClient()
  const progress = ref(0)
  const progressStore = useProgressStore()
  const i18n = useI18n()

  return {
    ...useMutation<DocumentModel[], RepositoryError, UploadApplicationDocumentModel>('application-document-upload', (model) => {
      progress.value = 0
      progressStore.addOrUpdateProgress(model.files[0].name, 0)
      return _repository.uploadApplicationDocument(model, function (progressEvent) {
        progress.value = progressEvent.loaded / progressEvent.total
        progressStore.addOrUpdateProgress(model.files[0].name, progress.value)
      })
    }, {
      onSettled(data, error, vars) {
        progressStore.remove(vars.files[0].name)
      },
      onSuccess: async (data, vars) => {
        await queryClient.invalidateQueries(['application-documents', vars.applicationId])
        useNotifications().success(i18n.t('trials.applications.documents.upload.success'))
      },
      onError: (err) => {
        useNotifications().warning(i18n.t('trials.applications.documents.upload.error', { error: err.message }))
      }
    }),
    progress
  }
}

export function useDeleteApplicationDocumentsMutation() {
  const queryClient = useQueryClient()

  return useMutation<void, RepositoryError, DeleteApplicationDocumentModel>('consideration-create', (model: DeleteApplicationDocumentModel) => {
    return _repository.deleteApplicationDocument(model)
  }, {
    onSuccess: async (data, vars) => {
      await queryClient.invalidateQueries(['application-documents', vars.applicationId])
    },
    onError: (err) => {
      useNotifications().warning(err.message)
    }
  })
}

export function useConsiderationDeleteMutation() {
  const queryClient = useQueryClient()

  return useMutation<void, RepositoryError, number>('consideration-update', (considerationId: number) => {
    return _repository.deleteConsideration(considerationId)
  }, {
    onSuccess: async () => {
      await queryClient.invalidateQueries('application-considerations')
    },
    onError: (err) => {
      useNotifications().warning(err.message)
    }
  })
}

export function useTransitionalRevokationMutation() {
  const queryClient = useQueryClient()

  return useMutation<void, RepositoryError, string>('revoke-transition', (trialId: string) => {
    return _repository.revokeTransitionalState(trialId)
  }, {
    onSuccess: async (_, trialId) => {
      await queryClient.invalidateQueries(['trial', trialId])
    },
    onError: (err) => {
      useNotifications().warning(err.message)
    }
  })
}

export function useApplicationAssigneeTypesQuery(applicationId: string | Ref<string>, opts?: { enabled: boolean | Ref<boolean> }) {
  const queryKey = ['application-assignee-types', applicationId]
  return useQuery<ApplicationAssigneeTypeModel[], RepositoryError>(queryKey, () => {
    return _repository.getApplicationAssigneeTypes(unref(applicationId))
  }, {
    enabled: opts?.enabled,
    onError: (err) => {
      useNotifications().warning(err.message)
    }
  })
}

export function useApplicationAssigneesQuery(applicationId: string | Ref<string>, opts?: { enabled: boolean | Ref<boolean> }) {
  const queryKey = ['application-assignees', applicationId]
  return useQuery<ApplicationAssigneeModel[], RepositoryError>(queryKey, () => {
    return _repository.getApplicationAssignees(unref(applicationId))
  }, {
    enabled: opts?.enabled,
    onError: (err) => {
      useNotifications().warning(err.message)
    }
  })
}

export function useApplicationAssigneeUpdateMutation() {
  const queryClient = useQueryClient()

  return useMutation<ApplicationAssigneeModel[], RepositoryError, UpdateApplicationAssigneeRequestModel>('update-application-assignees', (model: UpdateApplicationAssigneeRequestModel) => {
    return _repository.updateApplicationAssignees(model.applicationId, model.assignees)
  }, {
    onSuccess: async (data, vars) => {
      await queryClient.invalidateQueries(['application-assignees', vars.applicationId])
    },
    onError: (err) => {
      useNotifications().warning(err.message)
    }
  })
}

export function useTrialUserNeedsBiasConfirmationQuery(trialId: string | Ref<string>, opts?: { enabled: boolean | Ref<boolean> }) {
  const queryKey = ['trial-user-bias', trialId]
  return useQuery<boolean, RepositoryError>(queryKey, () => {
    return _repository.getUserNeedsBiasConfirmation(unref(trialId))
  }, {
    enabled: opts?.enabled,
    onError: (err) => {
      useNotifications().warning(err.message)
    }
  })
}

export function useTrialConfirmBiasedMutation() {
  return useMutation<void, RepositoryError, ConfirmTrialBiasedModel>('confirm-trial-biased', (model: ConfirmTrialBiasedModel) => {
    return _repository.confirmTrialBiased(model)
  }, {
    onError: (err) => {
      useNotifications().warning(err.message)
    }
  })
}

export function useTrialConfirmNotBiasedMutation() {
  const queryClient = useQueryClient()

  return useMutation<void, RepositoryError, string>('confirm-trial-not-biased', (trialId: string) => {
    return _repository.confirmTrialNotBiased(trialId)
  }, {
    onSuccess: (_, trialId) => {
      queryClient.invalidateQueries(['trial-user-bias', trialId])
    },
    onError: (err) => {
      useNotifications().warning(err.message)
    }
  })
}
