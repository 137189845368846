import { Plugin } from 'vue'
import AspectRatio from '../components/aspect-ratio.vue'
import QrCodeScanner from '../components/qr-code-scanner/index.vue'

declare module '@vue/runtime-core' {
  export interface GlobalComponents {
    AspectRatio: typeof AspectRatio
    QrCodeScanner: typeof QrCodeScanner
  }
}

let installed = false

export const commonComponents: Plugin = {
  install(app) {
    if (!installed) {
      app.component('aspect-ratio', AspectRatio)
      app.component('qr-code-scanner', QrCodeScanner)

      installed = true
    }
  }
}
