// eslint-disable-next-line boundaries/element-types
import { useNotifications } from '@ctr-ecs/notifications/use/useNotifications'
import { WebStorageStateStore } from 'oidc-client-ts'
import { defineComponent, h, Plugin } from 'vue'
import { Router, useRouter } from 'vue-router'
import { AFTER_LOGIN_REDIRECT_FIELD_NAME } from '../config/constants'
import { PermissionsModel } from '../models/permissions-model'
import { checkIfRoutingCanBeDone } from '../router/check-if-routing-can-be-done'
import { getDefaultRouteForLoggedInUser } from '../router/get-default-route-for-logged-in-user'
import { initializeUseAuth, useAuth } from '../use/useAuth'
import { Auth } from './auth'

declare module 'vue-router' {
  interface RouteMeta {
    /**
     * @member auth
     * undefined: any authenticated and non authenticated user can access this page
     * true: any authenticated user can access this page
     * 'guest': any non-authenticated user can access this page
     * (keyof Permissions)[]: any user having at least one of the listed permissions
     */
    auth?: true | 'guest' | (keyof PermissionsModel)[]
    layout?: () => Promise<unknown>
  }
}

const BASE_URL = window.location.origin
const CALLBACK_PATH = '/authorization-callback'
let auth: Auth | null = null
let isInitialRoutingAfterPageLoad = true

/**
 * Initializes Authentication
 * Must be called and awaited before creating Vue instance
 *
 * Note: Since Vue plugins cannot be installed asynchronously, this code must be executed before the Vue instance is created
 */
async function createAuth(): Promise<Plugin> {
  auth = new Auth({
    oidc: {
      authority: import.meta.env.VITE_AUTH_AUTHORITY,
      metadataUrl: import.meta.env.VITE_AUTH_METADATA_URL,
      client_id: import.meta.env.VITE_AUTH_CLIENT_ID,
      redirect_uri: BASE_URL + CALLBACK_PATH,
      scope: 'offline_access openid',
      automaticSilentRenew: false,

      // Store tokens in localStorage in order to stay logged-in in multiple tabs and after reopening the browser
      // Remove this line to stay logged-in in just one tab and get logged out when closing the browser/tab
      userStore: new WebStorageStateStore({ store: window.localStorage })
    },
    gateway: {
      baseUrl: import.meta.env.VITE_API_GATEWAY_URL
    }
  })

  if (window.location.pathname !== CALLBACK_PATH) {
    await auth.initialize()
  }

  initializeUseAuth(auth)

  return authPluginInstaller
}

const authPluginInstaller: Plugin = {
  /**
   * Installs callback route and auth router middleware
   */
  install: (app, options: { router: Router }) => {
    // only add authorization callback if user is currently on that route
    // this route can only be accessed directly after oauth login
    if (window.location.pathname === CALLBACK_PATH) {
      options.router.addRoute({
        path: CALLBACK_PATH,
        component: defineComponent({
          setup() {
            const router = useRouter()
            const auth = useAuth()

            auth.handleCallback().then(() => {
              const redirectPathOrUri = window.localStorage.getItem(AFTER_LOGIN_REDIRECT_FIELD_NAME)
              window.localStorage.removeItem(AFTER_LOGIN_REDIRECT_FIELD_NAME)

              // remove persisted 'tasks.', 'trials.', 'user.', 'meetings.', 'workflow.' items
              for (let i = 0; i < localStorage.length; i++) {
                const key = localStorage.key(i)
                if (key != null && /^(tasks\.)|(trials\.)|(user\.)|(trial\.)|(meetings\.)|(workflow\.)/.test(key)) {
                  localStorage.removeItem(key)
                }
              }

              if (redirectPathOrUri) {
                if (redirectPathOrUri.startsWith('http')) {
                  window.location.href = redirectPathOrUri
                } else if (router.resolve(redirectPathOrUri)) {
                  router.replace(redirectPathOrUri)
                } else {
                  router.replaceTasks()
                }
              } else {
                router.replaceTasks()
              }
            }).catch(() => {
              useNotifications().error('Login failed. Please try again later.')
              router.replace(router.resolveLogin())
            })
          },
          render() {
            return h('div')
          }
        })
      })
    }

    options.router.beforeEach(async (to, from, next) => {
      const auth = useAuth()

      try {
        const routingPermissionResult = checkIfRoutingCanBeDone(to.meta, auth.user.value)
        if (routingPermissionResult) {
          next()
        } else {
          // if a client wants to load a page he isn't allowed to, he is redirected to a page he is allowed to, 2 cases:
          // -> 1. if the page is already loaded and the user already navigated through the app than it is enough to just not route to requested route
          // -> 2. otherwise if the user just freshly loaded the page he should be redirected to a page he is allowed to see

          // case 2
          if (isInitialRoutingAfterPageLoad) {
            if (auth.user.value) {
              next({ name: getDefaultRouteForLoggedInUser(auth.user.value) })
            } else {
              window.localStorage.setItem(AFTER_LOGIN_REDIRECT_FIELD_NAME, to.fullPath)
              next(options.router.resolveLogin())
            }
          }
          // case 1
          else {
            console.warn('routing not permitted', to)
            next(false)
          }
        }
      } catch (e) {
        next(e as Error)
      } finally {
        isInitialRoutingAfterPageLoad = false
      }
    })
  }
}

export {
  createAuth
}
