import { WorkflowControllerApi } from '@ctr-ecs/repository/apis/openapi/generated'
import { rethrowAsRepositoryError } from '../error/rethrow-as-repository-error'
import { WorkflowModel } from '@ctr-ecs/common/model/workflow-model'
import { TaskModel } from '@ctr-ecs/common/model/task-model'
import { GenerateWorkflowModel } from '@ctr-ecs/common/model/generate-workflow-model'
import { CommonWorkflowRepository } from '@ctr-ecs/common/repository'
import { PageableModel } from '@ctr-ecs/common/model/pageable-model'
import { PageableWorkflowsQueryModel } from '@ctr-ecs/common/model/pageable-workflows-query-model'
import { UpdateWorkflowModel } from '@ctr-ecs/workflows/model/update-workflow-model'
import { WorkflowRepository } from '@ctr-ecs/workflows/repository'
import { UpdateWorkflowTaskModel } from '@ctr-ecs/workflows/model/update-workflow-task-model'
import { WorkflowTaskModel } from '@ctr-ecs/common/model/workflow-task-model'

export class WorkflowRepositoryImpl implements WorkflowRepository, CommonWorkflowRepository {
  constructor(private workflowApi: WorkflowControllerApi) {
  }

  async getWorkflows(pageable: PageableWorkflowsQueryModel): Promise<PageableModel<WorkflowModel>> {
    return rethrowAsRepositoryError(async () => {
      const response = (await this.workflowApi.getWorkflows(pageable.page, pageable.size, pageable.search, pageable.sortBy, pageable.sortDesc, pageable.ethicsCommissionIds))
      return response.data
    })
  }

  async generateTasks(model: GenerateWorkflowModel): Promise<TaskModel[]> {
    return rethrowAsRepositoryError(async () => {
      return (await this.workflowApi.generateTasks({
        workflowId: model.workflowId,
        concludeAt: model.concludesAt,
        concludeWithTaskId: model.concludesWithTaskId,
        trialId: Array.isArray(model.trialId) ? undefined : model.trialId,
        applicationId: Array.isArray(model.applicationId) ? undefined : model.applicationId,
        ethicsCommissionId: Array.isArray(model.ethicsCommissionId) ? undefined : model.ethicsCommissionId
      })).data
    })
  }

  async getWorkflow(id: number): Promise<WorkflowModel> {
    return rethrowAsRepositoryError(async () => {
      return (await this.workflowApi.getWorkflow(id)).data
    })
  }

  async updateWorkflow(model: UpdateWorkflowModel): Promise<WorkflowModel> {
    return rethrowAsRepositoryError(async () => {
      return (await this.workflowApi.updateWorkflow(model.id, model)).data
    })
  }

  async updateWorkflowTask(model: UpdateWorkflowTaskModel): Promise<WorkflowTaskModel> {
    return rethrowAsRepositoryError(async () => {
      return (await this.workflowApi.updateWorkflowTask(model.workflowId, model.id, model)).data
    })
  }

  async createWorkflowTask(workflowId: number): Promise<WorkflowTaskModel> {
    return rethrowAsRepositoryError(async () => {
      return (await this.workflowApi.createWorkflowTask(workflowId)).data
    })
  }

  async createWorkflow(): Promise<WorkflowModel> {
    return rethrowAsRepositoryError(async () => {
      return (await this.workflowApi.createWorkflow()).data
    })
  }

  async deleteWorkflow(id: number): Promise<void> {
    return rethrowAsRepositoryError(async () => {
      await this.workflowApi.deleteWorkflow(id)
    })
  }

  async deleteWorkflowTask(workflowId: number, taskId: number): Promise<void> {
    return rethrowAsRepositoryError(async () => {
      await this.workflowApi.deleteWorkflowTask(workflowId, taskId)
    })
  }

  async duplicateWorkflow(workflowId: number): Promise<WorkflowModel> {
    return rethrowAsRepositoryError(async () => {
      return (await this.workflowApi.copyWorkflow(workflowId)).data
    })
  }
}
