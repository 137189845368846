import { computed, Ref, ref } from 'vue'

export function useFPSCalculator() {
  const frameTimes: Ref<number[]> = ref([])
  const fps = computed(() => frameTimes.value.length)

  const updateFPS = () => {
    const now = window.performance.now()
    while (frameTimes.value.length > 0 && frameTimes.value[0] <= now - 1000) {
      frameTimes.value.shift()
    }
    frameTimes.value.push(now)
  }

  return { fps, updateFPS }
}