import { ROUTE, ROUTE_NAME } from '@ctr-ecs/common/config/route'
import { EvaluationProcess } from '@ctr-ecs/trials/types'
import { h, resolveComponent } from 'vue'
import { Router, RouteRecordRaw } from 'vue-router'
import NotFoundPage from '../components/404.vue'

const layouts = {
  loggedIn: () => Promise.resolve(import('@ctr-ecs/common/layouts/logged-in.vue'))
}

const routes: RouteRecordRaw[] = [
  {
    path: ROUTE.TRIALS,
    component: {
      render() {
        return h(resolveComponent('router-view'))
      }
    },
    meta: {
      auth: true
    },
    children: [
      {
        path: '',
        name: ROUTE_NAME.TRIALS,
        component: () => Promise.resolve(import('@ctr-ecs/trials/trials.vue')),
        meta: {
          layout: layouts.loggedIn
        }
      },
      {
        path: ROUTE.TRIAL_DETAIL,
        name: ROUTE_NAME.TRIAL_DETAIL,
        component: () => Promise.resolve(import('@ctr-ecs/trials/trial-detail.vue')),
        meta: {
          layout: layouts.loggedIn
        },
        children: [
          {
            path: '',
            name: ROUTE_NAME.TRIAL_DETAIL_SUMMARY,
            component: () => Promise.resolve(import('@ctr-ecs/trials/trial-detail-summary.vue')),
            meta: {
              layout: layouts.loggedIn
            }
          },
          {
            path: ROUTE.TRIAL_DETAIL_NOTIFICIATION,
            name: ROUTE_NAME.TRIAL_DETAIL_NOTIFICIATION,
            component: () => Promise.resolve(import('@ctr-ecs/trials/trial-detail-notification.vue')),
            meta: {
              layout: layouts.loggedIn
            }
          },
          {
            path: ROUTE.TRIAL_DETAIL_ALLDOCS,
            name: ROUTE_NAME.TRIAL_DETAIL_ALLDOCS,
            component: () => Promise.resolve(import('@ctr-ecs/trials/trial-detail-docs.vue')),
            meta: {
              layout: layouts.loggedIn
            }
          }
        ]
      },
      {
        path: ROUTE.TRIAL_APPLICATION,
        name: ROUTE_NAME.TRIAL_APPLICATION,
        redirect: { name: ROUTE_NAME.TRIAL_APPLICATION_DETAIL, params: { sections: ['form'] } },
        component: () => Promise.resolve(import('@ctr-ecs/trials/trial-application.vue')),
        meta: {
          layout: layouts.loggedIn
        },
        children: [
          {
            path: ROUTE.TRIAL_APPLICATION_DETAIL,
            name: ROUTE_NAME.TRIAL_APPLICATION_DETAIL,
            component: () => Promise.resolve(import('@ctr-ecs/trials/trial-application-detail.vue')),
            meta: {
              layout: layouts.loggedIn
            }
          },
          {
            path: ROUTE.TRIAL_APPLICATION_EVALUATION,
            name: ROUTE_NAME.TRIAL_APPLICATION_EVALUATION,
            component: () => Promise.resolve(import('@ctr-ecs/trials/trial-application-evaluation.vue')),
            meta: {
              layout: layouts.loggedIn
            }
          },
          {
            path: ROUTE.TRIAL_APPLICATION_DOCUMENTS,
            name: ROUTE_NAME.TRIAL_APPLICATION_DOCUMENTS,
            component: () => Promise.resolve(import('@ctr-ecs/trials/trial-application-documents.vue')),
            meta: {
              layout: layouts.loggedIn
            }
          },
          {
            path: ROUTE.TRIAL_APPLICATION_TASK,
            name: ROUTE_NAME.TRIAL_APPLICATION_TASK,
            component: () => Promise.resolve(import('@ctr-ecs/trials/trial-application-task.vue')),
            meta: {
              layout: layouts.loggedIn
            }
          },
          {
            path: ROUTE.TRIAL_APPLICATION_ASSIGNEES,
            name: ROUTE_NAME.TRIAL_APPLICATION_ASSIGNEES,
            component: () => Promise.resolve(import('@ctr-ecs/trials/trial-application-assignees.vue')),
            meta: {
              layout: layouts.loggedIn
            }
          }
        ]
      }
    ]
  },
  {
    path: ROUTE.LOGIN,
    name: ROUTE_NAME.LOGIN,
    component: () => Promise.resolve(import('@ctr-ecs/auth/login.vue')),
    meta: {
      auth: 'guest'
    }
  },
  {
    path: ROUTE.ADMIN,
    name: ROUTE_NAME.ADMIN,
    component: {
      render() {
        return h(resolveComponent('router-view'))
      }
    },
    meta: {
      layout: layouts.loggedIn,
      auth: true
    },
    children: [
      {
        path: '',
        name: ROUTE_NAME.ADMIN,
        component: () => Promise.resolve(import('@ctr-ecs/admin/admin.vue')),
        meta: {
          layout: layouts.loggedIn,
          auth: ['canViewUsers']
        }
      },
      {
        path: ROUTE.ADMIN_USER_CREATE,
        name: ROUTE_NAME.ADMIN_USER_CREATE,
        component: () => Promise.resolve(import('@ctr-ecs/admin/admin-user-create.vue')),
        meta: {
          layout: layouts.loggedIn,
          auth: ['canCreateUsers']
        }
      },
      {
        path: ROUTE.ADMIN_USER_EDIT,
        name: ROUTE_NAME.ADMIN_USER_EDIT,
        component: () => Promise.resolve(import('@ctr-ecs/admin/admin-user-edit.vue')),
        meta: {
          layout: layouts.loggedIn,
          auth: ['canEditUsers']
        }
      }
    ]
  },
  {
    path: ROUTE.TASKS,
    component: {
      render() {
        return h(resolveComponent('router-view'))
      }
    },
    meta: {
      auth: true
    },
    children: [
      {
        path: '',
        name: ROUTE_NAME.TASKS,
        component: () => Promise.resolve(import('@ctr-ecs/tasks/tasks.vue')),
        meta: {
          layout: layouts.loggedIn
        }
      },
      {
        path: ROUTE.TASK_CREATE,
        name: ROUTE_NAME.TASK_CREATE,
        component: () => Promise.resolve(import('@ctr-ecs/tasks/task-create.vue')),
        meta: {
          layout: layouts.loggedIn,
          auth: true
        }
      },
      {
        path: ROUTE.TASK_EDIT,
        name: ROUTE_NAME.TASK_EDIT,
        component: () => Promise.resolve(import('@ctr-ecs/tasks/task-edit.vue')),
        meta: {
          layout: layouts.loggedIn,
          auth: true
        }
      }
    ]
  },
  {
    path: ROUTE.MEETINGS,
    component: {
      render() {
        return h(resolveComponent('router-view'))
      }
    },
    children: [
      {
        path: '',
        name: ROUTE_NAME.MEETINGS,
        component: () => Promise.resolve(import('@ctr-ecs/meetings/meetings.vue')),
        meta: {
          auth: ['canViewMeetings'],
          layout: layouts.loggedIn
        }
      },
      {
        path: ROUTE.MEETING_CREATE,
        name: ROUTE_NAME.MEETING_CREATE,
        component: () => Promise.resolve(import('@ctr-ecs/meetings/meeting-create.vue')),
        meta: {
          auth: ['canCreateMeetings'],
          layout: layouts.loggedIn
        }
      },
      {
        path: ROUTE.MEETING_EDIT,
        name: ROUTE_NAME.MEETING_EDIT,
        component: () => Promise.resolve(import('@ctr-ecs/meetings/meeting-edit.vue')),
        meta: {
          auth: ['canViewMeetings'],
          layout: layouts.loggedIn
        }
      }
    ]
  },
  {
    path: ROUTE.WORKFLOWS,
    component: {
      render() {
        return h(resolveComponent('router-view'))
      }
    },
    children: [
      {
        path: '',
        name: ROUTE_NAME.WORKFLOWS,
        component: () => Promise.resolve(import('@ctr-ecs/workflows/workflows.vue')),
        meta: {
          auth: true,
          layout: layouts.loggedIn
        }
      },

      {
        path: ROUTE.WORKFLOW_EDIT,
        name: ROUTE_NAME.WORKFLOW_EDIT,
        component: () => Promise.resolve(import('@ctr-ecs/workflows/workflow-edit.vue')),
        meta: {
          auth: true,
          layout: layouts.loggedIn
        }
      },
      {
        path: ROUTE.WORKFLOW_TASK_EDIT,
        name: ROUTE_NAME.WORKFLOW_TASK_EDIT,
        component: () => Promise.resolve(import('@ctr-ecs/workflows/workflow-task-edit.vue')),
        meta: {
          auth: true,
          layout: layouts.loggedIn
        }
      }
    ]
  },
  {
    path: '/:pathMath(.*)*',
    component: NotFoundPage
  }
]

// all routes which are called with additional params or query information should be placed inside here
function registerRouterMethods(router: Router) {
  router.resolveLogin = function () {
    return router.resolve({ name: ROUTE_NAME.LOGIN })
  }
  router.pushTrials = function () {
    return router.push({ name: ROUTE_NAME.TRIALS })
  }
  router.resolveTrials = function () {
    return router.resolve({ name: ROUTE_NAME.TRIALS })
  }
  router.pushTrialDetail = function (id: string) {
    return router.push({ name: ROUTE_NAME.TRIAL_DETAIL, params: { id } })
  }
  router.resolveTrialDetail = function (id: string) {
    return router.resolve({ name: ROUTE_NAME.TRIAL_DETAIL, params: { id } })
  }
  router.pushTrialDetailNotification = function (id: string) {
    return router.push({ name: ROUTE_NAME.TRIAL_DETAIL_NOTIFICIATION, params: { id } })
  }
  router.resolveTrialDetailNotification = function (id: string) {
    return router.resolve({ name: ROUTE_NAME.TRIAL_DETAIL_NOTIFICIATION, params: { id } })
  }
  router.pushTrialDetailAllDocs = function (id: string) {
    return router.push({ name: ROUTE_NAME.TRIAL_DETAIL_ALLDOCS, params: { id } })
  }
  router.resolveTrialDetailAllDocs = function (id: string) {
    return router.resolve({ name: ROUTE_NAME.TRIAL_DETAIL_ALLDOCS, params: { id } })
  }
  router.pushApplication = function (id: string, ...sections: string[]) {
    return router.push({ name: ROUTE_NAME.TRIAL_APPLICATION_DETAIL, params: { id, sections } })
  }
  router.resolveApplication = function (id: string, ...sections: string[]) {
    return router.resolve({ name: ROUTE_NAME.TRIAL_APPLICATION_DETAIL, params: { id, sections } })
  }
  router.pushApplicationEvaluation = function (id: string, evaluationSection?: EvaluationProcess) {
    return router.push({ name: ROUTE_NAME.TRIAL_APPLICATION_EVALUATION, params: { id, evaluationSection } })
  }
  router.resolveApplicationEvaluation = function (id: string, evaluationSection?: EvaluationProcess) {
    return router.resolve({ name: ROUTE_NAME.TRIAL_APPLICATION_EVALUATION, params: { id, evaluationSection } })
  }
  router.pushApplicationDocuments = function (id: string) {
    return router.push({ name: ROUTE_NAME.TRIAL_APPLICATION_DOCUMENTS, params: { id } })
  }
  router.resolveApplicationDocuments = function (id: string) {
    return router.resolve({ name: ROUTE_NAME.TRIAL_APPLICATION_DOCUMENTS, params: { id } })
  }
  router.pushApplicationTask = function (id: string) {
    return router.push({ name: ROUTE_NAME.TRIAL_APPLICATION_TASK, params: { id } })
  }
  router.resolveApplicationTask = function (id: string) {
    return router.resolve({ name: ROUTE_NAME.TRIAL_APPLICATION_TASK, params: { id } })
  }
  router.pushApplicationAssignees = function (id: string) {
    return router.push({ name: ROUTE_NAME.TRIAL_APPLICATION_ASSIGNEES, params: { id } })
  }
  router.resolveApplicationAssignees = function (id: string) {
    return router.resolve({ name: ROUTE_NAME.TRIAL_APPLICATION_ASSIGNEES, params: { id } })
  }
  router.replaceTasks = function () {
    return router.push({ name: ROUTE_NAME.TASKS })
  }
  router.resolveTasks = function () {
    return router.resolve({ name: ROUTE_NAME.TASKS })
  }
  router.pushTaskEdit = function (id: number) {
    return router.push({ name: ROUTE_NAME.TASK_EDIT, params: { id } })
  }
  router.resolveTaskEdit = function (id: number) {
    return router.resolve({ name: ROUTE_NAME.TASK_EDIT, params: { id } })
  }
  router.pushMeetings = function () {
    return router.push({ name: ROUTE_NAME.MEETINGS })
  }
  router.resolveMeetings = function () {
    return router.resolve({ name: ROUTE_NAME.MEETINGS })
  }
  router.pushMeetingCreate = function () {
    return router.push({ name: ROUTE_NAME.MEETING_CREATE })
  }
  router.resolveMeetingCreate = function () {
    return router.resolve({ name: ROUTE_NAME.MEETING_CREATE })
  }
  router.pushMeetingEdit = function (id: number) {
    return router.push({ name: ROUTE_NAME.MEETING_EDIT, params: { id } })
  }
  router.resolveMeetingEdit = function (id: number) {
    return router.resolve({ name: ROUTE_NAME.MEETING_EDIT, params: { id } })
  }
  router.resolveAdmin = function () {
    return router.resolve({ name: ROUTE_NAME.ADMIN })
  }
  router.resolveAdminUserEdit = function (id: string) {
    return router.resolve({ name: ROUTE_NAME.ADMIN_USER_EDIT, params: { id } })
  }
  router.resolveAdminUserCreate = function () {
    return router.resolve({ name: ROUTE_NAME.ADMIN_USER_CREATE })
  }
  router.pushAdminUserEdit = function (id: string) {
    return router.push({ name: ROUTE_NAME.ADMIN_USER_EDIT, params: { id } })
  }
  router.resolveWorkflows = function () {
    return router.resolve({ name: ROUTE_NAME.WORKFLOWS })
  }
  router.resolveWorkflowEdit = function (id: string) {
    return router.resolve({ name: ROUTE_NAME.WORKFLOW_EDIT, params: { id } })
  }
  router.pushWorkflowEdit = function (id: string) {
    return router.push({ name: ROUTE_NAME.WORKFLOW_EDIT, params: { id } })
  }
  router.resolveWorkflowTaskEdit = function (id: string, taskId: string) {
    return router.resolve({ name: ROUTE_NAME.WORKFLOW_TASK_EDIT, params: { id, taskId } })
  }
  router.resolveTaskCreate = function (trialId?: string, applicationId?: string) {
    return router.resolve({ name: ROUTE_NAME.TASK_CREATE, query: { trialId, applicationId } })
  }
}

export default routes
export {
  registerRouterMethods
}
