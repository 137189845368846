import { Auth } from '../plugin/auth'

let _auth: Auth | null = null

export function initializeUseAuth(auth: Auth) {
  _auth = auth
}

export function useAuth() {
  if (!_auth) {
    throw new Error('Auth instance must be initialized in useAuth.ts')
  }

  return {
    user: _auth.user,
    isAuthenticated: _auth.isAuthenticated,
    accessToken: _auth.accessToken,
    login: () => (_auth as Auth).login(),
    logout: () => (_auth as Auth).logout(),
    handleCallback: () => (_auth as Auth).handleCallback()
  }
}