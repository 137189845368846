import * as HeadlessUI from '@headlessui/vue'
import {ErrorMessage, Field, Form} from 'vee-validate'
import {Plugin} from 'vue'
import DwAlert from '../alerts/dw-alert.vue'
import DwBadge from '../badges/dw-badge.vue'
import DwLabel from '../badges/dw-label.vue'
import Dw2WayToggle from '../buttons/dw-2-way-toggle.vue'
import DwBtn from '../buttons/dw-btn.vue'
import DwFilterBtn from '../buttons/dw-filter-btn.vue'
import DwIndeterminateBtn from '../buttons/dw-indeterminate-btn.vue'
import DwCollapse from '../elements/dw-collapse.vue'
import DwDataCard from '../elements/dw-data-card.vue'
import DwNoDocument from '../elements/dw-no-document.vue'
import DwPageHeader from '../elements/dw-page-header.vue'
import DwCheckbox from '../form/dw-checkbox.vue'
import DwRadio from '../form/dw-radio.vue'
import DwCombobox from '../form/dw-combobox.vue'
import DwInput from '../form/dw-input.vue'
import DwSearchInput from '../form/dw-search-input.vue'
import DwTextarea from '../form/dw-textarea.vue'
import DwArrowIcon from '../icons/dw-arrow-icon.vue'
import DwArrowTopRightIcon from '../icons/dw-arrow-top-right-icon.vue'
import DwDuplicateIcon from '@ctr-ecs/ui/icons/dw-duplicate-icon.vue'
import DwCheckIcon from '../icons/dw-check-icon.vue'
import DwCopyIcon from '../icons/dw-copy-icon.vue'
import DwDragHandleIcon from '../icons/dw-drag-handle-icon.vue'
import DwFileIcon from '../icons/dw-file-icon.vue'
import DwFilterIcon from '../icons/dw-filter-icon.vue'
import DwHammerIcon from '../icons/dw-hammer-icon.vue'
import DwEditPenIcon from '../icons/dw-edit-pen-icon.vue'
import DwLoadingIndicator from '../icons/dw-loading-indicator.vue'
import DwLogoIcon from '../icons/dw-logo-icon.vue'
import DwMatIcon from '../icons/dw-mat-icon.vue'
import DwPicturePlusIcon from '../icons/dw-picture-plus-icon.vue'
import DwPlusOutlinedIcon from '../icons/dw-plus-outlined-icon.vue'
import DwRefreshIcon from '../icons/dw-refresh-icon.vue'
import DwTaskAddIcon from '../icons/dw-task-add-icon.vue'
import DwTrashIcon from '../icons/dw-trash-icon.vue'
import DwUserIcon from '../icons/dw-user-icon.vue'
import DwWaterMelonSeedsIcon from '../icons/dw-watermelon-seeds-icon.vue'
import DwCheckOutlineIcon from '../icons/dw-check-outline-icon.vue'
import DwInformationIcon from '../icons/dw-information-icon.vue'
import DwWarningIcon from '../icons/dw-warning-icon.vue'
import DwCircleOutlineIcon from '../icons/dw-circle-outline-icon.vue'
import DwCircleAddOutlineIcon from '../icons/dw-circle-add-outline-icon.vue'
import DwUserAssignIcon from '../icons/dw-user-assign-icon.vue'
import DwUserUnassignIcon from '../icons/dw-user-unassign-icon.vue'
import DwEvaluationIcon from '@ctr-ecs/ui/icons/dw-evaluation-icon.vue'
import DwTrashRestoreIcon from '@ctr-ecs/ui/icons/dw-trash-restore-icon.vue'
import DwObsolescenceIcon from '@ctr-ecs/ui/icons/dw-obsolescence-icon.vue'
import DwDependencyIcon from '@ctr-ecs/ui/icons/dw-dependency-icon.vue'
import DwShootingStarIcon from '@ctr-ecs/ui/icons/dw-shooting-star-icon.vue'
import DwEditCalendarIcon from '@ctr-ecs/ui/icons/dw-calendar-edit-icon.vue'
import DwLinkedIcon from '@ctr-ecs/ui/icons/dw-linked-icon.vue'
import DwUnlinkedIcon from '@ctr-ecs/ui/icons/dw-unlinked-icon.vue'
import DwCalendarIcon from '@ctr-ecs/ui/icons/dw-calendar-icon.vue'
import DwBellIcon from '@ctr-ecs/ui/icons/dw-bell-icon.vue'
import DwContainer from '../layout/dw-container.vue'
import DwLinkOrSpan from '../links/dw-link-or-span.vue'
import DwLink from '../links/dw-link.vue'
import DwTertiaryNavigationItem from '../links/dw-tertiary-navigation-item.vue'
import DwModal from '../modal/dw-modal.vue'
import DwBreadcrumbs from '../navigation/dw-breadcrumbs.vue'
import DwNotification from '../notifications/dw-notification.vue'
import DwProgressBar from '../progress/dw-progress-bar.vue'
import DwDataTable from '../tables/data-table/dw-data-table.vue'
import DwSimpleTable from '../tables/dw-simple-table.vue'
import DwFadeTransition from '../transitions/dw-fade-transition.vue'
import DwScaleTransition from '../transitions/dw-scale-transition.vue'
import DwSlideFadeTransition from '../transitions/dw-slide-fade-transition.vue'
import DwTaskBlockedIcon from '@ctr-ecs/ui/icons/dw-task-blocked-icon.vue'
import DwUnlinkIcon from '@ctr-ecs/ui/icons/dw-unlink-icon.vue'
import DwExpandableText from '@ctr-ecs/ui/elements/dw-expandable-text.vue'
import DwCopyBtn from '@ctr-ecs/ui/buttons/dw-copy-btn.vue'
import DwTooltip from '@ctr-ecs/ui/elements/dw-tooltip.vue'
import DwTransitionalIcon from '@ctr-ecs/ui/icons/dw-transitional-icon.vue'

declare module '@vue/runtime-core' {
    export interface GlobalComponents {
        // vee-validate
        VvForm: typeof Form
        VvField: typeof Field
        VvErrorMessage: typeof ErrorMessage

        // headless-ui
        HMenu: typeof HeadlessUI.Menu
        HMenuItem: typeof HeadlessUI.MenuItem
        HMenuItems: typeof HeadlessUI.MenuItems
        HMenuButton: typeof HeadlessUI.MenuButton
        HListbox: typeof HeadlessUI.Listbox
        HListboxLabel: typeof HeadlessUI.ListboxLabel
        HListboxButton: typeof HeadlessUI.ListboxButton
        HListboxOption: typeof HeadlessUI.ListboxOption
        HListboxOptions: typeof HeadlessUI.ListboxOptions
        HCombobox: typeof HeadlessUI.Combobox
        HComboboxInput: typeof HeadlessUI.ComboboxInput
        HComboboxLabel: typeof HeadlessUI.ComboboxLabel
        HComboboxButton: typeof HeadlessUI.ComboboxButton
        HComboboxOption: typeof HeadlessUI.ComboboxOption
        HComboboxOptions: typeof HeadlessUI.ComboboxOptions
        HSwitch: typeof HeadlessUI.Switch
        HSwitchLabel: typeof HeadlessUI.SwitchLabel
        HSwitchGroup: typeof HeadlessUI.SwitchGroup
        HSwitchDescription: typeof HeadlessUI.SwitchDescription
        HDisclosure: typeof HeadlessUI.Disclosure
        HDisclosurePanel: typeof HeadlessUI.DisclosurePanel
        HDisclosureButton: typeof HeadlessUI.DisclosureButton
        HDialog: typeof HeadlessUI.Dialog
        HDialogTitle: typeof HeadlessUI.DialogTitle
        HDialogPanel: typeof HeadlessUI.DialogPanel
        HDialogOverlay: typeof HeadlessUI.DialogOverlay
        HDialogBackdrop: typeof HeadlessUI.DialogBackdrop
        HDialogDescription: typeof HeadlessUI.DialogDescription
        HRadioGroup: typeof HeadlessUI.RadioGroup
        HRadioGroupLabel: typeof HeadlessUI.RadioGroupLabel
        HRadioGroupOption: typeof HeadlessUI.RadioGroupOption
        HRadioGroupDescription: typeof HeadlessUI.RadioGroupDescription
        HPopover: typeof HeadlessUI.Popover
        HPopoverPanel: typeof HeadlessUI.PopoverPanel
        HPopoverGroup: typeof HeadlessUI.PopoverGroup
        HPopoverButton: typeof HeadlessUI.PopoverButton
        HPopoverOverlay: typeof HeadlessUI.PopoverOverlay
        HTabs: typeof HeadlessUI.Tab
        HTabsList: typeof HeadlessUI.TabList
        HTabsPanel: typeof HeadlessUI.TabPanel
        HTabsGroup: typeof HeadlessUI.TabGroup
        HTabsPanels: typeof HeadlessUI.TabPanels
        HTransitionRoot: typeof HeadlessUI.TransitionRoot
        HTransitionChild: typeof HeadlessUI.TransitionChild
        HFocusTrap: typeof HeadlessUI.FocusTrap
        HPortal: typeof HeadlessUI.Portal
        HPortalGroup: typeof HeadlessUI.PortalGroup

        DwArrowTopRightIcon: typeof DwArrowTopRightIcon
        DwUnlinkIcon: typeof DwUnlinkIcon
        DwArrowIcon: typeof DwArrowIcon
        DwCheckIcon: typeof DwCheckIcon
        DwCopyIcon: typeof DwCopyIcon
        DwDragHandleIcon: typeof DwDragHandleIcon
        DwMatIcon: typeof DwMatIcon
        DwFilterIcon: typeof DwFilterIcon
        DwLogoIcon: typeof DwLogoIcon
        DwLoadingIndicator: typeof DwLoadingIndicator
        DwTaskAddIcon: typeof DwTaskAddIcon
        DwTrashIcon: typeof DwTrashIcon
        DwHammerIcon: typeof DwHammerIcon
        DwEditPenIcon: typeof DwEditPenIcon
        DwPicturePlusIcon: typeof DwPicturePlusIcon
        DwPlusOutlinedIcon: typeof DwPlusOutlinedIcon
        DwRefreshIcon: typeof DwRefreshIcon
        DwWatermelonSeedsIcon: typeof DwWaterMelonSeedsIcon
        DwTaskBlockedIcon: typeof DwTaskBlockedIcon
        DwCheckOutlineIcon: typeof DwCheckOutlineIcon
        DwInformationIcon: typeof DwInformationIcon
        DwWarningIcon: typeof DwWarningIcon
        DwCircleOutlineIcon: typeof DwCircleOutlineIcon
        DwCircleAddOutlineIcon: typeof DwCircleAddOutlineIcon
        DwUserAssignIcon: typeof DwUserAssignIcon
        DwUserUnassignIcon: typeof DwUserUnassignIcon
        DwEvaluationIcon: typeof DwEvaluationIcon
        DwTrashRestoreIcon: typeof DwTrashRestoreIcon
        DwObsolescenceIcon: typeof DwObsolescenceIcon
        DwDependencyIcon: typeof DwDependencyIcon
        DwShootingStarIcon: typeof DwShootingStarIcon
        DwEditCalendarIcon: typeof DwEditCalendarIcon
        DwLinkedIcon: typeof DwLinkedIcon
        DwUnlinkedIcon: typeof DwUnlinkedIcon
        DwDuplicateIcon: typeof DwDuplicateIcon
        DwCalendarIcon: typeof DwCalendarIcon
        DwBellIcon: typeof DwBellIcon
        DwTransitionalIcon: typeof DwTransitionalIcon

        DwFadeTransition: typeof DwFadeTransition
        DwScaleTransition: typeof DwScaleTransition
        DwSlideFadeTransition: typeof DwSlideFadeTransition
        DwBtn: typeof DwBtn
        DwFilterBtn: typeof DwFilterBtn
        DwIndeterminateBtn: typeof DwIndeterminateBtn
        'dw-2-way-toggle': typeof Dw2WayToggle
        DwAlert: typeof DwAlert
        DwBadge: typeof DwBadge
        DwLabel: typeof DwLabel
        DwInput: typeof DwInput
        DwSearchInput: typeof DwSearchInput
        DwTextarea: typeof DwTextarea
        DwCheckbox: typeof DwCheckbox
        DwRadio: typeof DwRadio
        DwCombobox: typeof DwCombobox
        DwContainer: typeof DwContainer
        DwNotification: typeof DwNotification
        DwPageHeader: typeof DwPageHeader
        DwLink: typeof DwLink
        DwLinkOrSpan: typeof DwLinkOrSpan
        DwTertiaryNavigationItem: typeof DwTertiaryNavigationItem
        DwDataTable: typeof DwDataTable
        DwSimpleTable: typeof DwSimpleTable
        DwBreadcrumbs: typeof DwBreadcrumbs
        DwModal: typeof DwModal
        DwDataCard: typeof DwDataCard
        DwNoDocument: typeof DwNoDocument
        DwFileIcon: typeof DwFileIcon
        DwUserIcon: typeof DwUserIcon
        DwCollapse: typeof DwCollapse
        DwProgressBar: typeof DwProgressBar
        DwExpandableText: typeof DwExpandableText
        DwCopyBtn: typeof DwCopyBtn
        DwTooltip: typeof DwTooltip
    }
}

let installed = false

export const uiComponents: Plugin = {
    install(app) {
        if (!installed) {
            // vee-validate
            app.component('vv-form', Form)
            app.component('vv-field', Field)
            app.component('vv-error-message', ErrorMessage)

            // headless-ui
            app.component('h-menu', HeadlessUI.Menu)
            app.component('h-menu-item', HeadlessUI.MenuItem)
            app.component('h-menu-items', HeadlessUI.MenuItems)
            app.component('h-menu-button', HeadlessUI.MenuButton)
            app.component('h-listbox', HeadlessUI.Listbox)
            app.component('h-listbox-label', HeadlessUI.ListboxLabel)
            app.component('h-listbox-button', HeadlessUI.ListboxButton)
            app.component('h-listbox-option', HeadlessUI.ListboxOption)
            app.component('h-listbox-options', HeadlessUI.ListboxOptions)
            app.component('h-combobox', HeadlessUI.Combobox)
            app.component('h-combobox-input', HeadlessUI.ComboboxInput)
            app.component('h-combobox-label', HeadlessUI.ComboboxLabel)
            app.component('h-combobox-button', HeadlessUI.ComboboxButton)
            app.component('h-combobox-option', HeadlessUI.ComboboxOption)
            app.component('h-combobox-options', HeadlessUI.ComboboxOptions)
            app.component('h-switch', HeadlessUI.Switch)
            app.component('h-switch-label', HeadlessUI.SwitchLabel)
            app.component('h-switch-group', HeadlessUI.SwitchGroup)
            app.component('h-switch-description', HeadlessUI.SwitchDescription)
            app.component('h-disclosure', HeadlessUI.Disclosure)
            app.component('h-disclosure-panel', HeadlessUI.DisclosurePanel)
            app.component('h-disclosure-button', HeadlessUI.DisclosureButton)
            app.component('h-dialog', HeadlessUI.Dialog)
            app.component('h-dialog-panel', HeadlessUI.DialogPanel)
            app.component('h-dialog-title', HeadlessUI.DialogTitle)
            app.component('h-dialog-overlay', HeadlessUI.DialogOverlay)
            app.component('h-dialog-backdrop', HeadlessUI.DialogBackdrop)
            app.component('h-dialog-description', HeadlessUI.DialogDescription)
            app.component('h-radio-group', HeadlessUI.RadioGroup)
            app.component('h-radio-group-label', HeadlessUI.RadioGroupLabel)
            app.component('h-radio-group-option', HeadlessUI.RadioGroupOption)
            app.component('h-radio-group-description', HeadlessUI.RadioGroupDescription)
            app.component('h-popover', HeadlessUI.Popover)
            app.component('h-popover-panel', HeadlessUI.PopoverPanel)
            app.component('h-popover-group', HeadlessUI.PopoverGroup)
            app.component('h-popover-button', HeadlessUI.PopoverButton)
            app.component('h-popover-overlay', HeadlessUI.PopoverOverlay)
            app.component('h-tabs', HeadlessUI.Tab)
            app.component('h-tabs-list', HeadlessUI.TabList)
            app.component('h-tabs-group', HeadlessUI.TabGroup)
            app.component('h-tabs-panel', HeadlessUI.TabPanel)
            app.component('h-tabs-panels', HeadlessUI.TabPanels)
            app.component('h-transition-root', HeadlessUI.TransitionRoot)
            app.component('h-transition-child', HeadlessUI.TransitionChild)
            app.component('h-focus-trap', HeadlessUI.FocusTrap)
            app.component('h-portal', HeadlessUI.Portal)
            app.component('h-portal-group', HeadlessUI.PortalGroup)

            app.component('dw-arrow-top-right-icon', DwArrowTopRightIcon)
            app.component('dw-arrow-icon', DwArrowIcon)
            app.component('dw-check-icon', DwCheckIcon)
            app.component('dw-copy-icon', DwCopyIcon)
            app.component('dw-drag-handle-icon', DwDragHandleIcon)
            app.component('dw-mat-icon', DwMatIcon)
            app.component('dw-logo-icon', DwLogoIcon)
            app.component('dw-filter-icon', DwFilterIcon)
            app.component('dw-task-add-icon', DwTaskAddIcon)
            app.component('dw-trash-icon', DwTrashIcon)
            app.component('dw-loading-indicator', DwLoadingIndicator)
            app.component('dw-file-icon', DwFileIcon)
            app.component('dw-hammer-icon', DwHammerIcon)
            app.component('dw-edit-pen-icon', DwEditPenIcon)
            app.component('dw-picture-plus-icon', DwPicturePlusIcon)
            app.component('dw-plus-outlined-icon', DwPlusOutlinedIcon)
            app.component('dw-refresh-icon', DwRefreshIcon)
            app.component('dw-user-icon', DwUserIcon)
            app.component('dw-watermelon-seeds-icon', DwWaterMelonSeedsIcon)
            app.component('dw-task-blocked-icon', DwTaskBlockedIcon)
            app.component('dw-unlink-icon', DwUnlinkIcon)
            app.component('dw-check-outline-icon', DwCheckOutlineIcon)
            app.component('dw-information-icon', DwInformationIcon)
            app.component('dw-warning-icon', DwWarningIcon)
            app.component('dw-circle-outline-icon', DwCircleOutlineIcon)
            app.component('dw-circle-add-outline-icon', DwCircleAddOutlineIcon)
            app.component('dw-user-assign-icon', DwUserAssignIcon)
            app.component('dw-user-unassign-icon', DwUserUnassignIcon)
            app.component('dw-evaluation-icon', DwEvaluationIcon)
            app.component('dw-trash-restore-icon', DwTrashRestoreIcon)
            app.component('dw-obsolescence-icon', DwObsolescenceIcon)
            app.component('dw-dependency-icon', DwDependencyIcon)
            app.component('dw-shooting-star-icon', DwShootingStarIcon)
            app.component('dw-calendar-edit-icon', DwEditCalendarIcon)
            app.component('dw-linked-icon', DwLinkedIcon)
            app.component('dw-unlinked-icon', DwUnlinkedIcon)
            app.component('dw-duplicate-icon', DwDuplicateIcon)
            app.component('dw-calendar-icon', DwCalendarIcon)
            app.component('dw-bell-icon', DwBellIcon)
            app.component('dw-transitional-icon', DwTransitionalIcon)

            app.component('dw-fade-transition', DwFadeTransition)
            app.component('dw-scale-transition', DwScaleTransition)
            app.component('dw-slide-fade-transition', DwSlideFadeTransition)

            app.component('dw-btn', DwBtn)
            app.component('dw-filter-btn', DwFilterBtn)
            app.component('dw-indeterminate-btn', DwIndeterminateBtn)
            app.component('dw-2-way-toggle', Dw2WayToggle)
            app.component('dw-alert', DwAlert)
            app.component('dw-badge', DwBadge)
            app.component('dw-label', DwLabel)
            app.component('dw-input', DwInput)
            app.component('dw-search-input', DwSearchInput)
            app.component('dw-textarea', DwTextarea)
            app.component('dw-checkbox', DwCheckbox)
            app.component('dw-radio', DwRadio)
            app.component('dw-combobox', DwCombobox)
            app.component('dw-modal', DwModal)
            app.component('dw-container', DwContainer)
            app.component('dw-notification', DwNotification)
            app.component('dw-page-header', DwPageHeader)

            app.component('dw-link', DwLink)
            app.component('dw-link-or-span', DwLinkOrSpan)
            app.component('dw-tertiary-navigation-item', DwTertiaryNavigationItem)

            app.component('dw-data-table', DwDataTable)
            app.component('dw-simple-table', DwSimpleTable)

            app.component('dw-breadcrumbs', DwBreadcrumbs)

            app.component('dw-data-card', DwDataCard)
            app.component('dw-no-document', DwNoDocument)
            app.component('dw-collapse', DwCollapse)

            app.component('dw-progress-bar', DwProgressBar)
            app.component('dw-expandable-text', DwExpandableText)

            app.component('dw-copy-btn', DwCopyBtn)

            app.component('dw-tooltip', DwTooltip)

            installed = true
        }
    }
}
