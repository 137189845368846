import { EthicsCommissionModel } from '@ctr-ecs/common/model/ethics-commission-model'
import { EthicCommissionsRepository } from '@ctr-ecs/common/repository'
import { EthicsCommissionControllerApi } from '../apis/openapi/generated'
import { rethrowAsRepositoryError } from '../error/rethrow-as-repository-error'

export class EthicsCommissionRepositoryImpl implements EthicCommissionsRepository {
  constructor(private ethicsCommissionControllerApi: EthicsCommissionControllerApi) {
  }

  async getAllEthicCommissions(): Promise<EthicsCommissionModel[]> {
    return rethrowAsRepositoryError(async () => {
      const response = await this.ethicsCommissionControllerApi.getAllEthicCommissions()
      return response.data
    })
  }
}
