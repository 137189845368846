import { ApplicationMemberState } from '@ctr-ecs/common/model/application-member-state'
import { ApplicationMenuNode } from '@ctr-ecs/common/model/application-menu-model'
import { ApplicationModel } from '@ctr-ecs/common/model/application-model'
import { ApplicationNameModel } from '@ctr-ecs/common/model/application-name-model'
import { DocumentModel, DocumentVersionModel } from '@ctr-ecs/common/model/document-model'
import { DataNode, DocumentNode, HeadlineNode, LabelNode, ListNode, Node as NodeModel, NoDocumentNode, TableNode } from '@ctr-ecs/common/model/node'
import { TrialModel, TrialStatus } from '@ctr-ecs/common/model/trial-model'
import { TrialSummaryModel } from '@ctr-ecs/common/model/trial-summary-model'
import { ApplicationMeta } from '@ctr-ecs/trials/model/application-meta'
import { ApplicationStatus } from '@ctr-ecs/trials/model/application-status'
import { TrialSettingsModel } from '@ctr-ecs/trials/model/trial-settings'
import { ApplicationDto, ApplicationMemberStateDto, ApplicationMetaDto, ApplicationNameDto, ApplicationSectionItemDto, DocumentDto, DocumentVersionDto, Node, TrialDto, TrialSettingsDto, TrialSummaryDto } from '../apis/openapi/generated'

export class TrialMapper {
  mapDtoToModel(dto: TrialDto): TrialModel {
    return {
      id: dto.id,
      trialId: dto.trialId,
      autId: dto.autId,
      title: dto.title,
      status: dto.status as TrialStatus,
      memberStates: dto.memberStates,
      ethicsCommission: dto.ethicsCommission,
      nextDueTask: dto.nextDueTask,
      isTransitional: dto.isTransitional
    }
  }

  mapSummaryDtoToModel(dto: TrialSummaryDto): TrialSummaryModel {
    return {
      id: dto.id,
      trialId: dto.trialId,
      applications: dto.applications.map(app => this.mapApplicationDtoToModel(app)),
      title: dto.title,
      impNames: dto.impNames,
      eudraCtCode: dto.eudraCtCode
    }
  }

  mapApplicationDtoToModel(dto: ApplicationDto): ApplicationModel {
    return <ApplicationModel>{
      id: dto.id,
      applicationId: dto.applicationId,
      status: dto.status,
      submissionDate: dto.submissionDate,
      applicationType: dto.applicationType,
      decisionDate: dto.decisionDate,
      memberStates: dto.memberStates.map(m => this.mapToApplicationMemberState(m)),
      part1: dto.part1,
      part2s: dto.part2s,
      businessKey: dto.businessKey
    }
  }

  mapApplicationSectionItemDtoToModel(dto: ApplicationSectionItemDto[]): ApplicationMenuNode[] {
    return dto.map(node => this.mapSingleNodeToModel(node))
  }

  mapApplicationMetaDtoToModel(dto: ApplicationMetaDto): ApplicationMeta {
    return {
      id: dto.id,
      type: dto.type,
      businessKey: dto.businessKey,
      trialId: dto.trialId,
      trialTitle: dto.trialTitle,
      status: dto.status as ApplicationStatus,
      rms: dto.rms,
      nextDueTask: dto.nextDueTask
    }
  }

  mapNodeDtoToModel(dto: Node): NodeModel | undefined {
    switch (dto.type) {
      case 'table':
        return <TableNode>{
          title: (dto as Node & { title?: string }).title,
          type: dto.type,
          header: (dto as Node & { header: string[] }).header,
          content: (dto as Node & { content: string[][] }).content
        }
      case 'data':
        return <DataNode>{
          title: (dto as Node & { title?: string }).title,
          type: dto.type,
          content: (dto as Node & { content: { headline: string, content: string }[] }).content
        }
      case 'document':
        return <DocumentNode>{
          type: dto.type,
          content: (dto as Node & {
            content: { title: string, versions: { contentType: string, version: string, createdAt: string, downloadUrl: string }[] }
          }).content
        }
      case 'noDocument':
        return <NoDocumentNode>{
          type: dto.type
        }
      case 'headline':
        return <HeadlineNode>{
          text: (dto as Node & { text: string }).text,
          type: dto.type
        }
      case 'list':
        return <ListNode>{
          title: (dto as Node & { title?: string }).title,
          type: dto.type,
          children: (dto as Node & { children: Node[] }).children.map(child => this.mapNodeDtoToModel(child))
        }
      case 'label':
        return <LabelNode>{
          type: dto.type,
          text: (dto as Node & { text: string }).text,
          labelType: (dto as Node & { labelType: string }).labelType
        }
    }
  }

  mapToDocumentModel(dto: DocumentDto): DocumentModel {
    return {
      id: dto.id,
      title: dto.title,
      type: dto.type,
      section: dto.section,
      latestVersion: dto.latestVersion,
      origin: dto.origin,
      versions: dto.versions.map(v => this.mapToDocumentVersionModel(v))
    }
  }

  mapToApplicationNameModel(dto: ApplicationNameDto): ApplicationNameModel {
    return {
      id: dto.id,
      name: dto.name
    }
  }

  mapToSettingsModel(dto: TrialSettingsDto): TrialSettingsModel {
    return {
      ethicsCommissionId: dto.ethicsCommissionId
    }
  }

  private mapSingleNodeToModel(dto: ApplicationSectionItemDto): ApplicationMenuNode {
    return {
      title: dto.title,
      key: dto.key,
      anchor: dto.anchor,
      children: dto.children.map(child => this.mapSingleNodeToModel(child))
    }
  }

  private mapToApplicationMemberState(dto: ApplicationMemberStateDto): ApplicationMemberState {
    return {
      countryName: dto.countryName,
      countryCode: dto.countryCode,
      isRms: dto.isRms,
      isProposed: dto.isProposed,
      mscId: dto.mscId,
      reportingStatusCode: dto.reportingStatusCode?.text ?? ''
    }
  }

  private mapToDocumentVersionModel(dto: DocumentVersionDto): DocumentVersionModel {
    return {
      comment: dto.comment,
      downloadUrl: dto.downloadUrl,
      fromDate: dto.fromDate,
      version: dto.version
    }
  }
}
