import { ApplicationNameModel } from '@ctr-ecs/common/model/application-name-model'
import { ApplicationSearchModel } from '@ctr-ecs/common/model/application-search-model'
import { useNotifications } from '@ctr-ecs/notifications/use/useNotifications'
import { Ref, unref } from 'vue'
import { useQuery } from 'vue-query'
import { RepositoryError } from '../model/exception/repository-exception'
import { TrialModel } from '../model/trial-model'
import { CommonTrialRepository } from '../repository'
import { MemberState } from '@ctr-ecs/common/model/member-state'

let _repository: CommonTrialRepository

export function initialize(repository: CommonTrialRepository) {
  _repository = repository
}

export function useAllTrialsQuery() {
  return useQuery<TrialModel[], RepositoryError>('all-trials', async () => {
    return _repository.getAllTrials()
  }, {
    staleTime: 1000 * 60 * 5,
    retry: 3,
    onError: (err) => {
      useNotifications().warning(err.message)
    }
  })
}

export function useTrialApplicationsQuery(id: string | Ref<string>, opts: { enabled: boolean | Ref<boolean> }) {
  const queryKey = ['trial-applications', id]

  return useQuery<ApplicationNameModel[], RepositoryError>(queryKey, async () => {
    return _repository.getTrialApplications(unref(id))
  }, {
    staleTime: 1000 * 60 * 5,
    retry: 3,
    enabled: opts.enabled,
    onError: (err) => {
      useNotifications().warning(err.message)
    }
  })
}

export function useApplicationsQuery(search: string | Ref<string>, opts: { enabled: boolean | Ref<boolean> }) {
  const queryKey = ['applications-', search]

  return useQuery<ApplicationSearchModel[], RepositoryError>(queryKey, async () => {
    return _repository.getApplications(unref(search))
  }, {
    enabled: opts.enabled,
    onError: (err) => {
      useNotifications().warning(err.message)
    }
  })
}

export function useAllMSCQuery(applicationId: string | Ref<string>) {
  const queryKey = ['all-application-mscs', applicationId]
  return useQuery<MemberState[], RepositoryError>(queryKey, async () => {
    return _repository.getAllMSCs(unref(applicationId))
  }, {
    staleTime: 1000 * 60 * 5,
    retry: 3,
    onError: (err) => {
      useNotifications().warning(err.message)
    }
  })
}
