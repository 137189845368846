import { defineStore } from 'pinia'

interface ProgressState {
  progresses: Record<string, number>
}

export const useProgressStore = defineStore({
  id: 'progress',
  state: (): ProgressState => ({
    progresses: {}
  }),
  getters: {
    hasProgress(): boolean {
      return Object.values(this.progresses).length > 0
    },
    averageProgress(): number {
      const progresses = Object.values(this.progresses)
      return this.hasProgress ? progresses.reduce((partialSum, a) => partialSum + a, 0) / progresses.length : 0
    }
  },
  actions: {
    addOrUpdateProgress(id: string, progress: number) {
      this.progresses[id] = progress
    },
    remove(id: string) {
      delete this.progresses[id]
    }
  }
})
