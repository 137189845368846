import { PageableModel } from '@ctr-ecs/common/model/pageable-model'
import { TaskModel } from '@ctr-ecs/common/model/task-model'
import { TaskUserModel } from '@ctr-ecs/common/model/task-user-model'
import { PageableTasksQueryModel } from '@ctr-ecs/tasks/model/pageable-tasks-query-model'
import { TasksRepository } from '@ctr-ecs/tasks/repository'
import { CreateTaskModel } from '../../tasks/model/create-task-model'
import { UpdateTaskModel } from '../../tasks/model/update-task-model'
import { CreateTaskDto, TaskControllerApi, TaskUserDto, UpdateTaskDto } from '../apis/openapi/generated'
import { rethrowAsRepositoryError } from '../error/rethrow-as-repository-error'
import { PageableMapper } from '../mapper/pageable-mapper'
import { TaskMapper } from '../mapper/task-mapper'
import { TaskEvaluationModel } from '@ctr-ecs/tasks/model/task-evaluation-model'
import { UpdateTaskEvaluationModel } from '@ctr-ecs/tasks/model/update-task-evaluation-model'
import { CommonTaskRepository } from '@ctr-ecs/common/repository'
import { TaskDependencyModel } from '@ctr-ecs/common/model/task-dependency-model'
import { TaskDependencyEvaluationModel } from '@ctr-ecs/common/model/task-dependency-evaluation-model'
import { TaskDependencyValidationModel } from '@ctr-ecs/common/model/task-dependency-validation-model'
import { TaskActivityModel } from '@ctr-ecs/tasks/model/task-activity-model'
import { ApplicationTasksQueryModel } from '@ctr-ecs/common/model/application-tasks-query-model'

export class TasksRepositoryImpl implements TasksRepository, CommonTaskRepository {
  constructor(private taskApi: TaskControllerApi, private pageableMapper: PageableMapper, private taskMapper: TaskMapper) {
  }

  async getTasks(pageable: PageableTasksQueryModel): Promise<PageableModel<TaskDependencyValidationModel>> {
    return rethrowAsRepositoryError(async () => {
      const response = await this.taskApi.getTasks(pageable.page, pageable.size, pageable.searchTerms, pageable.sortBy, pageable.sortDesc, pageable.done, pageable.ethicCommissionIds, pageable.assignedToIds, pageable.minDate, pageable.maxDate, pageable.includeCaSpTasks, pageable.applicationId, pageable.autId, pageable.trialId, pageable.ctisStatus)
      return this.pageableMapper.mapDtoToModelWithMapper(response.data, (dto) => this.taskMapper.mapToValidationModel(dto))
    })
  }

  async getTask(id: number): Promise<TaskModel> {
    return rethrowAsRepositoryError(async () => {
      const response = await this.taskApi.getTask(id)
      return this.taskMapper.mapDtoToModel(response.data)
    })
  }

  async createTask(task: CreateTaskModel): Promise<TaskModel> {
    return rethrowAsRepositoryError(async () => {
      const data: CreateTaskDto = {
        assignedTo: task.assignedToIds,
        createdById: task.createdById,
        description: task.description,
        done: task.done,
        dueDate: task.dueDate,
        ethicsCommissionId: task.ethicsCommissionId,
        title: task.title,
        trialId: task.trialId,
        applicationId: task.applicationId
      }
      return this.taskMapper.mapDtoToModel((await this.taskApi.createTask(data)).data)
    })
  }

  async updateTask(task: UpdateTaskModel): Promise<TaskModel> {
    return rethrowAsRepositoryError(async () => {
      const data: UpdateTaskDto = {
        assignedTo: task.assignedToIds,
        applicationId: task.applicationId,
        description: task.description,
        done: task.done,
        dueDate: task.dueDate,
        ethicsCommissionId: task.ethicsCommissionId,
        title: task.title,
        trialId: task.trialId,
        meetingId: task.meetingId,
        requiresSession: task.requiresSession,
        requiredUsers: task.requiredUsersIds,
        shouldResolveConflicts: task.shouldResolveConflicts
      }
      return this.taskMapper.mapDtoToModel((await this.taskApi.updateTask(task.id, data)).data)
    })
  }

  async updateDone(id: number, done: boolean): Promise<TaskModel> {
    return rethrowAsRepositoryError(async () => {
      return this.taskMapper.mapDtoToModel((await this.taskApi.updateDone(id, { done: done })).data)
    })
  }

  async getAssignableUsers(ecId?: number): Promise<TaskUserModel[]> {
    return rethrowAsRepositoryError(async () => {
      let users: TaskUserDto[]

      if (typeof ecId === 'undefined') {
        const response = await this.taskApi.getAssignableUsers()
        users = response.data
      } else {
        const response = await this.taskApi.getAssignableUsersByEC(ecId)
        users = response.data
      }

      return users.map(this.taskMapper.mapTaskUserDtoToModel)
    })
  }

  async deleteTask(id: number): Promise<void> {
    return rethrowAsRepositoryError(async () => {
      await this.taskApi.deleteTask(id)
    })
  }

  async getPredecessors(taskId: number): Promise<TaskDependencyModel[]> {
    return rethrowAsRepositoryError(async () => {
      const response = await this.taskApi.getPredecessorsOfTask(taskId)
      return response.data.map(this.taskMapper.mapTaskDependencyDtoToModel)
    })
  }

  async getSuccessors(taskId: number): Promise<TaskDependencyModel[]> {
    return rethrowAsRepositoryError(async () => {
      const response = await this.taskApi.getSuccessorsOfTask(taskId)
      return response.data.map(this.taskMapper.mapTaskDependencyDtoToModel)
    })
  }

  async getTaskEvaluation(taskId: number): Promise<TaskEvaluationModel> {
    return rethrowAsRepositoryError(async () => {
      const response = await this.taskApi.getTaskEvaluation(taskId)
      return response.data
    })
  }

  async updateEvaluation(model: UpdateTaskEvaluationModel): Promise<TaskEvaluationModel> {
    return rethrowAsRepositoryError(async () => {
      const response = await this.taskApi.updateTaskEvaluation(model.id, { evaluation: model.evaluation })
      return response.data
    })
  }

  async getTasksPredecessors(tasks: number[]): Promise<TaskDependencyModel[]> {
    return rethrowAsRepositoryError(async () => {
      const response = await this.taskApi.getPredecessorsOfTasks(tasks)
      return response.data.map(this.taskMapper.mapTaskDependencyDtoToModel)
    })
  }

  async getApplicationTasks(request: ApplicationTasksQueryModel): Promise<TaskModel[]> {
    return rethrowAsRepositoryError(async () => {
      const response = await this.taskApi.getTasksForApplication(request.applicationId, request.includeObsolete, request.includeProjected)
      return response.data.map(this.taskMapper.mapDtoToModel)
    })
  }

  async updateSuccessorsOfTask(taskId: number, successorIds: number[]): Promise<TaskModel[]> {
    return rethrowAsRepositoryError(async () => {
      const response = await this.taskApi.updateSuccessorsOfTask(taskId, successorIds)
      return response.data.map(this.taskMapper.mapDtoToModel)
    })
  }

  async updatePredecessorsOfTask(taskId: number, predecessorIds: number[]): Promise<TaskModel[]> {
    return rethrowAsRepositoryError(async () => {
      const response = await this.taskApi.updatePredecessorsOfTask(taskId, predecessorIds)
      return response.data.map(this.taskMapper.mapDtoToModel)
    })
  }

  async getPrecedingEvaluations(taskId: number): Promise<TaskDependencyEvaluationModel[]> {
    return rethrowAsRepositoryError(async () => {
      const response = await this.taskApi.getPrecedingEvaluations(taskId)
      return response.data.map(this.taskMapper.mapToDependencyEvaluationModel)
    })
  }

  async getActivities(taskId: number): Promise<TaskActivityModel[]> {
    return rethrowAsRepositoryError(async () => {
      const response = await this.taskApi.getTaskActivities(taskId)
      return response.data
    })
  }

  async getCTRECSTasksToChange(taskId: number): Promise<TaskDependencyModel[]> {
    return rethrowAsRepositoryError(async () => {
      const response = await this.taskApi.getCTRECSTasksToChange(taskId)
      return response.data.map(this.taskMapper.mapTaskDependencyDtoToModel)
    })
  }

  async sendReminderToAssignedUsers(taskId: number): Promise<void> {
    return rethrowAsRepositoryError(async () => {
      await this.taskApi.sendReminderToAssignedUsers(taskId)
    })
  }
}
