<template>
  <div :class="[unref(expanded) ? '': 'truncate', 'transition duration-200']" v-on:click="switchExpandState">
    <span>{{ props.text }}</span>
  </div>
</template>

<script lang="ts" setup>
import { ref, unref } from 'vue'

interface Props {
  text: string
}

const props = withDefaults(defineProps<Props>(), {
  text: '-'
})

const expanded = ref(false)

const switchExpandState = () => {
  expanded.value = !expanded.value
}
</script>
