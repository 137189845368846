import { useNotifications } from '@ctr-ecs/notifications/use/useNotifications'
import { useQuery } from 'vue-query'
import { EthicsCommissionModel } from '../model/ethics-commission-model'
import { RepositoryError } from '../model/exception/repository-exception'
import { EthicCommissionsRepository } from '../repository'

let _repository: EthicCommissionsRepository

export function initialize(repository: EthicCommissionsRepository) {
  _repository = repository
}

export function useAllEthicsCommissionQuery() {
  return useQuery<EthicsCommissionModel[], RepositoryError>('all-ethics-commissions', async () => {
    return await _repository.getAllEthicCommissions()
  }, {
    staleTime: 1000 * 60 * 5,
    retry: 3,
    onError: (err) => {
      useNotifications().warning(err.message)
    }
  })
}
