import { RepositoryError } from '@ctr-ecs/common/model/exception/repository-exception'
import { MeetingModel } from '@ctr-ecs/common/model/meeting-model'
import { CreateMeetingModel } from '@ctr-ecs/meetings/model/create-meeting-model'
import { UpdateMeetingModel } from '@ctr-ecs/meetings/model/update-meeting-model'
import { useQuery, useQueryClient } from 'vue-query'
import { useMutation } from 'vue-query/esm'
import { MeetingsRepository } from './repository'
import { Ref, unref } from 'vue'
import { useNotifications } from '@ctr-ecs/notifications/use/useNotifications'
import { TaskModel } from '@ctr-ecs/common/model/task-model'

let _repository: MeetingsRepository

export function initialize(repository: MeetingsRepository) {
  _repository = repository
}

export function useCreateMeetingMutation() {
  const queryClient = useQueryClient()

  return useMutation<MeetingModel, RepositoryError, CreateMeetingModel>('createMeeting', (meeting: CreateMeetingModel) => {
    return _repository.createMeeting(meeting)
  }, {
    async onSuccess() {
      await queryClient.invalidateQueries(['meetings'])
    }
  })
}

export function useUpdateMeetingMutation() {
  const queryClient = useQueryClient()

  return useMutation<MeetingModel, RepositoryError, UpdateMeetingModel>('updateMeeting', (meeting: UpdateMeetingModel) => {
    return _repository.updateMeeting(meeting)
  }, {
    async onSuccess() {
      await queryClient.invalidateQueries(['meetings'])
      await queryClient.invalidateQueries(['meeting'])
    }
  })
}

export function useMeetingBlockingPredecessorsQuery(meetingId: number | Ref<number>, opts: { enabled: boolean | Ref<boolean> }) {
  const queryKey = ['meeting-blocking-predecessors', meetingId]

  return useQuery<TaskModel[], RepositoryError>(queryKey, () => {
    return _repository.getBlockingPredecessors(unref(meetingId))
  }, {
    staleTime: 1000 * 60,
    retry: 3,
    enabled: opts.enabled,
    onError: (err) => {
      useNotifications().warning(err.message)
    }
  })
}

export function useSendInvitationMutation() {
  const queryClient = useQueryClient()

  return useMutation<void, RepositoryError, number>('sendMeetingInvitation', (meetingId) => {
    return _repository.useInviteUsers(meetingId)
  }, {
    async onSuccess(_, meetingId) {
      await queryClient.invalidateQueries(['meetings'])
      await queryClient.invalidateQueries(['meeting', meetingId.toString()])
    }
  })
}
