import { UserAdminDto, UserDetailDto } from '../apis/openapi/generated'
import { UserDetailModel } from '@ctr-ecs/common/model/user-detail-model'
import { UserAdminModel } from '@ctr-ecs/admin/model/user-admin'

export class UserMapper {
  mapToModel(dto: UserDetailDto): UserDetailModel {
    return {
      id: dto.id,
      ethicsCommission: dto.ethicsCommission
    }
  }

  mapDtoToModel(dto: UserAdminDto): UserAdminModel {
    return dto
  }
}
