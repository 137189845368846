/* tslint:disable */
/* eslint-disable */
/**
 * ctrecs
 * Built at 2024-07-25T13:27:59.398Z UTC
 *
 * The version of the OpenAPI document: 0.7.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface ApplicationAssigneeDto
 */
export interface ApplicationAssigneeDto {
    /**
     * 
     * @type {string}
     * @memberof ApplicationAssigneeDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationAssigneeDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationAssigneeDto
     */
    'type': string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationAssigneeDto
     */
    'biasStatus': string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationAssigneeDto
     */
    'biasComment'?: string;
}
/**
 * 
 * @export
 * @interface ApplicationDto
 */
export interface ApplicationDto {
    /**
     * 
     * @type {number}
     * @memberof ApplicationDto
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof ApplicationDto
     */
    'applicationId': string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationDto
     */
    'applicationType': string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationDto
     */
    'businessKey': string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationDto
     */
    'status': string;
    /**
     * 
     * @type {Array<Part2Dto>}
     * @memberof ApplicationDto
     */
    'part2s': Array<Part2Dto>;
    /**
     * 
     * @type {Array<ApplicationMemberStateDto>}
     * @memberof ApplicationDto
     */
    'memberStates': Array<ApplicationMemberStateDto>;
    /**
     * 
     * @type {Part1Dto}
     * @memberof ApplicationDto
     */
    'part1'?: Part1Dto;
    /**
     * 
     * @type {string}
     * @memberof ApplicationDto
     */
    'submissionDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationDto
     */
    'decisionDate'?: string;
}
/**
 * 
 * @export
 * @interface ApplicationMemberStateDto
 */
export interface ApplicationMemberStateDto {
    /**
     * 
     * @type {number}
     * @memberof ApplicationMemberStateDto
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof ApplicationMemberStateDto
     */
    'mscId': string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationMemberStateDto
     */
    'countryName': string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationMemberStateDto
     */
    'countryCode': string;
    /**
     * 
     * @type {boolean}
     * @memberof ApplicationMemberStateDto
     */
    'isRms': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ApplicationMemberStateDto
     */
    'isProposed': boolean;
    /**
     * 
     * @type {WrappedEnumDto}
     * @memberof ApplicationMemberStateDto
     */
    'reportingStatusCode'?: WrappedEnumDto;
}
/**
 * 
 * @export
 * @interface ApplicationMetaDto
 */
export interface ApplicationMetaDto {
    /**
     * 
     * @type {string}
     * @memberof ApplicationMetaDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationMetaDto
     */
    'type': string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationMetaDto
     */
    'businessKey': string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationMetaDto
     */
    'trialId': string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationMetaDto
     */
    'trialTitle': string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationMetaDto
     */
    'status': string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationMetaDto
     */
    'rms': string;
    /**
     * 
     * @type {NextDueTaskDto}
     * @memberof ApplicationMetaDto
     */
    'nextDueTask'?: NextDueTaskDto;
}
/**
 * 
 * @export
 * @interface ApplicationNameDto
 */
export interface ApplicationNameDto {
    /**
     * 
     * @type {string}
     * @memberof ApplicationNameDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationNameDto
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface ApplicationSearchDto
 */
export interface ApplicationSearchDto {
    /**
     * 
     * @type {string}
     * @memberof ApplicationSearchDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationSearchDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationSearchDto
     */
    'trialId': string;
}
/**
 * 
 * @export
 * @interface ApplicationSectionItemDto
 */
export interface ApplicationSectionItemDto {
    /**
     * 
     * @type {string}
     * @memberof ApplicationSectionItemDto
     */
    'title': string;
    /**
     * 
     * @type {Array<ApplicationSectionItemDto>}
     * @memberof ApplicationSectionItemDto
     */
    'children': Array<ApplicationSectionItemDto>;
    /**
     * 
     * @type {string}
     * @memberof ApplicationSectionItemDto
     */
    'key'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationSectionItemDto
     */
    'anchor'?: string;
}
/**
 * 
 * @export
 * @interface AssigneeTypeDto
 */
export interface AssigneeTypeDto {
    /**
     * 
     * @type {string}
     * @memberof AssigneeTypeDto
     */
    'type': string;
    /**
     * 
     * @type {string}
     * @memberof AssigneeTypeDto
     */
    'name': string;
    /**
     * 
     * @type {Array<AvailableAssigneeDto>}
     * @memberof AssigneeTypeDto
     */
    'assigneePool': Array<AvailableAssigneeDto>;
}
/**
 * 
 * @export
 * @interface AvailableAssigneeDto
 */
export interface AvailableAssigneeDto {
    /**
     * 
     * @type {string}
     * @memberof AvailableAssigneeDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof AvailableAssigneeDto
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface ConfirmBiasRequestDto
 */
export interface ConfirmBiasRequestDto {
    /**
     * 
     * @type {string}
     * @memberof ConfirmBiasRequestDto
     */
    'comment': string;
}
/**
 * 
 * @export
 * @interface ConsiderationDto
 */
export interface ConsiderationDto {
    /**
     * 
     * @type {number}
     * @memberof ConsiderationDto
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof ConsiderationDto
     */
    'applicationSectionPartId': number;
    /**
     * 
     * @type {number}
     * @memberof ConsiderationDto
     */
    'applicationSectionAndDocumentId': number;
    /**
     * 
     * @type {string}
     * @memberof ConsiderationDto
     */
    'consideration': string;
    /**
     * 
     * @type {boolean}
     * @memberof ConsiderationDto
     */
    'transferredToCtis': boolean;
    /**
     * 
     * @type {ConsiderationUserDto}
     * @memberof ConsiderationDto
     */
    'createdBy': ConsiderationUserDto;
    /**
     * 
     * @type {number}
     * @memberof ConsiderationDto
     */
    'part1ConsiderationsId'?: number;
}
/**
 * 
 * @export
 * @interface ConsiderationUserDto
 */
export interface ConsiderationUserDto {
    /**
     * 
     * @type {string}
     * @memberof ConsiderationUserDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ConsiderationUserDto
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof ConsiderationUserDto
     */
    'name'?: string;
}
/**
 * 
 * @export
 * @interface CreateConsiderationRequestDto
 */
export interface CreateConsiderationRequestDto {
    /**
     * 
     * @type {string}
     * @memberof CreateConsiderationRequestDto
     */
    'evaluationProcess': string;
    /**
     * 
     * @type {number}
     * @memberof CreateConsiderationRequestDto
     */
    'applicationSectionPartsId': number;
    /**
     * 
     * @type {number}
     * @memberof CreateConsiderationRequestDto
     */
    'applicationSectionAndDocumentId': number;
    /**
     * 
     * @type {string}
     * @memberof CreateConsiderationRequestDto
     */
    'consideration': string;
    /**
     * 
     * @type {string}
     * @memberof CreateConsiderationRequestDto
     */
    'applicationId': string;
    /**
     * 
     * @type {number}
     * @memberof CreateConsiderationRequestDto
     */
    'part1ConsiderationId'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof CreateConsiderationRequestDto
     */
    'transferredToCtis'?: boolean;
}
/**
 * 
 * @export
 * @interface CreateDocumentCommentRequestDto
 */
export interface CreateDocumentCommentRequestDto {
    /**
     * 
     * @type {number}
     * @memberof CreateDocumentCommentRequestDto
     */
    'documentId': number;
    /**
     * 
     * @type {string}
     * @memberof CreateDocumentCommentRequestDto
     */
    'relatesTo': string;
    /**
     * 
     * @type {string}
     * @memberof CreateDocumentCommentRequestDto
     */
    'comment': string;
    /**
     * 
     * @type {boolean}
     * @memberof CreateDocumentCommentRequestDto
     */
    'transferredToCtis': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CreateDocumentCommentRequestDto
     */
    'isCtisDocument': boolean;
}
/**
 * 
 * @export
 * @interface CreateMeetingDto
 */
export interface CreateMeetingDto {
    /**
     * 
     * @type {string}
     * @memberof CreateMeetingDto
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof CreateMeetingDto
     */
    'startDateTime': string;
    /**
     * 
     * @type {number}
     * @memberof CreateMeetingDto
     */
    'ethicsCommissionId'?: number;
}
/**
 * 
 * @export
 * @interface CreateTaskDto
 */
export interface CreateTaskDto {
    /**
     * 
     * @type {string}
     * @memberof CreateTaskDto
     */
    'title': string;
    /**
     * 
     * @type {boolean}
     * @memberof CreateTaskDto
     */
    'done'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CreateTaskDto
     */
    'trialId'?: string;
    /**
     * 
     * @type {number}
     * @memberof CreateTaskDto
     */
    'ethicsCommissionId'?: number;
    /**
     * 
     * @type {string}
     * @memberof CreateTaskDto
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateTaskDto
     */
    'createdById'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateTaskDto
     */
    'assignedTo'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof CreateTaskDto
     */
    'dueDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateTaskDto
     */
    'applicationId'?: string;
}
/**
 * 
 * @export
 * @interface CreateUserRequestDto
 */
export interface CreateUserRequestDto {
    /**
     * 
     * @type {string}
     * @memberof CreateUserRequestDto
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof CreateUserRequestDto
     */
    'name': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateUserRequestDto
     */
    'groupIds': Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateUserRequestDto
     */
    'notificationTypes': Array<string>;
    /**
     * 
     * @type {string}
     * @memberof CreateUserRequestDto
     */
    'note'?: string;
    /**
     * 
     * @type {number}
     * @memberof CreateUserRequestDto
     */
    'ethicsCommissionId'?: number;
}
/**
 * 
 * @export
 * @interface DocumentCommentDto
 */
export interface DocumentCommentDto {
    /**
     * 
     * @type {number}
     * @memberof DocumentCommentDto
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof DocumentCommentDto
     */
    'comment': string;
    /**
     * 
     * @type {boolean}
     * @memberof DocumentCommentDto
     */
    'transferredToCtis': boolean;
    /**
     * 
     * @type {EvaluationDocumentUserDto}
     * @memberof DocumentCommentDto
     */
    'createdBy': EvaluationDocumentUserDto;
    /**
     * 
     * @type {string}
     * @memberof DocumentCommentDto
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof DocumentCommentDto
     */
    'relatesTo'?: string;
}
/**
 * 
 * @export
 * @interface DocumentDto
 */
export interface DocumentDto {
    /**
     * 
     * @type {string}
     * @memberof DocumentDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof DocumentDto
     */
    'latestVersion': string;
    /**
     * 
     * @type {string}
     * @memberof DocumentDto
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof DocumentDto
     */
    'origin': string;
    /**
     * 
     * @type {Array<DocumentVersionDto>}
     * @memberof DocumentDto
     */
    'versions': Array<DocumentVersionDto>;
    /**
     * 
     * @type {string}
     * @memberof DocumentDto
     */
    'section'?: string;
    /**
     * 
     * @type {string}
     * @memberof DocumentDto
     */
    'type'?: string;
}
/**
 * 
 * @export
 * @interface DocumentNodeContent
 */
export interface DocumentNodeContent {
    /**
     * 
     * @type {string}
     * @memberof DocumentNodeContent
     */
    'title': string;
    /**
     * 
     * @type {Array<DocumentNodeVersion>}
     * @memberof DocumentNodeContent
     */
    'versions': Array<DocumentNodeVersion>;
}
/**
 * 
 * @export
 * @interface DocumentNodeVersion
 */
export interface DocumentNodeVersion {
    /**
     * 
     * @type {string}
     * @memberof DocumentNodeVersion
     */
    'version': string;
    /**
     * 
     * @type {string}
     * @memberof DocumentNodeVersion
     */
    'downloadUrl': string;
    /**
     * 
     * @type {string}
     * @memberof DocumentNodeVersion
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof DocumentNodeVersion
     */
    'contentType': string;
}
/**
 * 
 * @export
 * @interface DocumentVersionDto
 */
export interface DocumentVersionDto {
    /**
     * 
     * @type {string}
     * @memberof DocumentVersionDto
     */
    'version': string;
    /**
     * 
     * @type {string}
     * @memberof DocumentVersionDto
     */
    'downloadUrl': string;
    /**
     * 
     * @type {string}
     * @memberof DocumentVersionDto
     */
    'comment'?: string;
    /**
     * 
     * @type {string}
     * @memberof DocumentVersionDto
     */
    'fromDate'?: string;
}
/**
 * 
 * @export
 * @interface EthicsCommissionDto
 */
export interface EthicsCommissionDto {
    /**
     * 
     * @type {number}
     * @memberof EthicsCommissionDto
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof EthicsCommissionDto
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface EvaluationDocumentDto
 */
export interface EvaluationDocumentDto {
    /**
     * 
     * @type {number}
     * @memberof EvaluationDocumentDto
     */
    'id': number;
    /**
     * 
     * @type {DocumentNodeContent}
     * @memberof EvaluationDocumentDto
     */
    'document': DocumentNodeContent;
    /**
     * 
     * @type {Array<DocumentCommentDto>}
     * @memberof EvaluationDocumentDto
     */
    'comments': Array<DocumentCommentDto>;
    /**
     * 
     * @type {boolean}
     * @memberof EvaluationDocumentDto
     */
    'isCtisDocument': boolean;
    /**
     * 
     * @type {string}
     * @memberof EvaluationDocumentDto
     */
    'rfiSequenceNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof EvaluationDocumentDto
     */
    'rfiTitle'?: string;
}
/**
 * 
 * @export
 * @interface EvaluationDocumentUserDto
 */
export interface EvaluationDocumentUserDto {
    /**
     * 
     * @type {string}
     * @memberof EvaluationDocumentUserDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof EvaluationDocumentUserDto
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof EvaluationDocumentUserDto
     */
    'name'?: string;
}
/**
 * 
 * @export
 * @interface ExternalConsiderationCommentDto
 */
export interface ExternalConsiderationCommentDto {
    /**
     * 
     * @type {string}
     * @memberof ExternalConsiderationCommentDto
     */
    'comment': string;
    /**
     * 
     * @type {string}
     * @memberof ExternalConsiderationCommentDto
     */
    'msc': string;
    /**
     * 
     * @type {string}
     * @memberof ExternalConsiderationCommentDto
     */
    'id'?: string;
}
/**
 * 
 * @export
 * @interface ExternalConsiderationDto
 */
export interface ExternalConsiderationDto {
    /**
     * 
     * @type {string}
     * @memberof ExternalConsiderationDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ExternalConsiderationDto
     */
    'number': string;
    /**
     * 
     * @type {Array<ExternalConsiderationCommentDto>}
     * @memberof ExternalConsiderationDto
     */
    'comments': Array<ExternalConsiderationCommentDto>;
    /**
     * 
     * @type {string}
     * @memberof ExternalConsiderationDto
     */
    'mscCountryName'?: string;
    /**
     * 
     * @type {string}
     * @memberof ExternalConsiderationDto
     */
    'applicationSectionParts'?: string;
    /**
     * 
     * @type {string}
     * @memberof ExternalConsiderationDto
     */
    'applicationSectionAndDocument'?: string;
    /**
     * 
     * @type {string}
     * @memberof ExternalConsiderationDto
     */
    'evaluationProcess'?: string;
    /**
     * 
     * @type {string}
     * @memberof ExternalConsiderationDto
     */
    'consideration'?: string;
    /**
     * 
     * @type {string}
     * @memberof ExternalConsiderationDto
     */
    'sharedAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof ExternalConsiderationDto
     */
    'consolidatorActionDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof ExternalConsiderationDto
     */
    'consolidatorAction'?: string;
}
/**
 * 
 * @export
 * @interface GenerateTasksViaWorkflowDto
 */
export interface GenerateTasksViaWorkflowDto {
    /**
     * 
     * @type {number}
     * @memberof GenerateTasksViaWorkflowDto
     */
    'workflowId': number;
    /**
     * 
     * @type {string}
     * @memberof GenerateTasksViaWorkflowDto
     */
    'concludeAt'?: string;
    /**
     * 
     * @type {number}
     * @memberof GenerateTasksViaWorkflowDto
     */
    'concludeWithTaskId'?: number;
    /**
     * 
     * @type {string}
     * @memberof GenerateTasksViaWorkflowDto
     */
    'trialId'?: string;
    /**
     * 
     * @type {string}
     * @memberof GenerateTasksViaWorkflowDto
     */
    'applicationId'?: string;
    /**
     * 
     * @type {number}
     * @memberof GenerateTasksViaWorkflowDto
     */
    'ethicsCommissionId'?: number;
}
/**
 * 
 * @export
 * @interface GroupDto
 */
export interface GroupDto {
    /**
     * 
     * @type {string}
     * @memberof GroupDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof GroupDto
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface MeetingDto
 */
export interface MeetingDto {
    /**
     * 
     * @type {number}
     * @memberof MeetingDto
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof MeetingDto
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof MeetingDto
     */
    'startDateTime': string;
    /**
     * 
     * @type {string}
     * @memberof MeetingDto
     */
    'endDateTime': string;
    /**
     * 
     * @type {number}
     * @memberof MeetingDto
     */
    'numberOfTasks': number;
    /**
     * 
     * @type {Array<UserDto>}
     * @memberof MeetingDto
     */
    'requiredUsers': Array<UserDto>;
    /**
     * 
     * @type {boolean}
     * @memberof MeetingDto
     */
    'isSession': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MeetingDto
     */
    'isBlocked': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MeetingDto
     */
    'invitationsChanged': boolean;
    /**
     * 
     * @type {UserDto}
     * @memberof MeetingDto
     */
    'organizer': UserDto;
    /**
     * 
     * @type {EthicsCommissionDto}
     * @memberof MeetingDto
     */
    'ethicsCommission'?: EthicsCommissionDto;
}
/**
 * 
 * @export
 * @interface MeetingTaskDto
 */
export interface MeetingTaskDto {
    /**
     * 
     * @type {number}
     * @memberof MeetingTaskDto
     */
    'duration': number;
    /**
     * 
     * @type {TaskDto}
     * @memberof MeetingTaskDto
     */
    'task': TaskDto;
    /**
     * 
     * @type {string}
     * @memberof MeetingTaskDto
     */
    'trialTitle': string;
}
/**
 * 
 * @export
 * @interface MeetingTaskUpdateDto
 */
export interface MeetingTaskUpdateDto {
    /**
     * 
     * @type {boolean}
     * @memberof MeetingTaskUpdateDto
     */
    'done': boolean;
    /**
     * 
     * @type {number}
     * @memberof MeetingTaskUpdateDto
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof MeetingTaskUpdateDto
     */
    'duration'?: number;
}
/**
 * 
 * @export
 * @interface MemberStateDto
 */
export interface MemberStateDto {
    /**
     * 
     * @type {string}
     * @memberof MemberStateDto
     */
    'countryName': string;
    /**
     * 
     * @type {string}
     * @memberof MemberStateDto
     */
    'countryCode': string;
    /**
     * 
     * @type {boolean}
     * @memberof MemberStateDto
     */
    'isRms': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MemberStateDto
     */
    'isProposed': boolean;
}
/**
 * 
 * @export
 * @interface NeedsBiasConfirmationResponseDto
 */
export interface NeedsBiasConfirmationResponseDto {
    /**
     * 
     * @type {boolean}
     * @memberof NeedsBiasConfirmationResponseDto
     */
    'needsConfirmation': boolean;
}
/**
 * 
 * @export
 * @interface NextDueTaskDto
 */
export interface NextDueTaskDto {
    /**
     * 
     * @type {string}
     * @memberof NextDueTaskDto
     */
    'dueDate': string;
    /**
     * 
     * @type {number}
     * @memberof NextDueTaskDto
     */
    'taskId': number;
}
/**
 * 
 * @export
 * @interface Node
 */
export interface Node {
    /**
     * 
     * @type {string}
     * @memberof Node
     */
    'type': string;
}
/**
 * 
 * @export
 * @interface NotificationDto
 */
export interface NotificationDto {
    /**
     * 
     * @type {string}
     * @memberof NotificationDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof NotificationDto
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof NotificationDto
     */
    'type': string;
    /**
     * 
     * @type {string}
     * @memberof NotificationDto
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof NotificationDto
     */
    'text'?: string;
    /**
     * 
     * @type {string}
     * @memberof NotificationDto
     */
    'action'?: string;
}
/**
 * 
 * @export
 * @interface PageResponseDtoMeetingDto
 */
export interface PageResponseDtoMeetingDto {
    /**
     * 
     * @type {number}
     * @memberof PageResponseDtoMeetingDto
     */
    'page': number;
    /**
     * 
     * @type {number}
     * @memberof PageResponseDtoMeetingDto
     */
    'totalElements': number;
    /**
     * 
     * @type {Array<MeetingDto>}
     * @memberof PageResponseDtoMeetingDto
     */
    'items': Array<MeetingDto>;
    /**
     * 
     * @type {number}
     * @memberof PageResponseDtoMeetingDto
     */
    'size': number;
}
/**
 * 
 * @export
 * @interface PageResponseDtoTaskDependencyValidationDto
 */
export interface PageResponseDtoTaskDependencyValidationDto {
    /**
     * 
     * @type {number}
     * @memberof PageResponseDtoTaskDependencyValidationDto
     */
    'page': number;
    /**
     * 
     * @type {number}
     * @memberof PageResponseDtoTaskDependencyValidationDto
     */
    'totalElements': number;
    /**
     * 
     * @type {Array<TaskDependencyValidationDto>}
     * @memberof PageResponseDtoTaskDependencyValidationDto
     */
    'items': Array<TaskDependencyValidationDto>;
    /**
     * 
     * @type {number}
     * @memberof PageResponseDtoTaskDependencyValidationDto
     */
    'size': number;
}
/**
 * 
 * @export
 * @interface PageResponseDtoTrialDto
 */
export interface PageResponseDtoTrialDto {
    /**
     * 
     * @type {number}
     * @memberof PageResponseDtoTrialDto
     */
    'page': number;
    /**
     * 
     * @type {number}
     * @memberof PageResponseDtoTrialDto
     */
    'totalElements': number;
    /**
     * 
     * @type {Array<TrialDto>}
     * @memberof PageResponseDtoTrialDto
     */
    'items': Array<TrialDto>;
    /**
     * 
     * @type {number}
     * @memberof PageResponseDtoTrialDto
     */
    'size': number;
}
/**
 * 
 * @export
 * @interface PageResponseDtoUserAdminDto
 */
export interface PageResponseDtoUserAdminDto {
    /**
     * 
     * @type {number}
     * @memberof PageResponseDtoUserAdminDto
     */
    'page': number;
    /**
     * 
     * @type {number}
     * @memberof PageResponseDtoUserAdminDto
     */
    'totalElements': number;
    /**
     * 
     * @type {Array<UserAdminDto>}
     * @memberof PageResponseDtoUserAdminDto
     */
    'items': Array<UserAdminDto>;
    /**
     * 
     * @type {number}
     * @memberof PageResponseDtoUserAdminDto
     */
    'size': number;
}
/**
 * 
 * @export
 * @interface PageResponseDtoWorkflowDto
 */
export interface PageResponseDtoWorkflowDto {
    /**
     * 
     * @type {number}
     * @memberof PageResponseDtoWorkflowDto
     */
    'page': number;
    /**
     * 
     * @type {number}
     * @memberof PageResponseDtoWorkflowDto
     */
    'totalElements': number;
    /**
     * 
     * @type {Array<WorkflowDto>}
     * @memberof PageResponseDtoWorkflowDto
     */
    'items': Array<WorkflowDto>;
    /**
     * 
     * @type {number}
     * @memberof PageResponseDtoWorkflowDto
     */
    'size': number;
}
/**
 * 
 * @export
 * @interface Part1Dto
 */
export interface Part1Dto {
    /**
     * 
     * @type {number}
     * @memberof Part1Dto
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof Part1Dto
     */
    'title': string;
}
/**
 * 
 * @export
 * @interface Part2Dto
 */
export interface Part2Dto {
    /**
     * 
     * @type {number}
     * @memberof Part2Dto
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof Part2Dto
     */
    'mscCountryCode': string;
    /**
     * 
     * @type {number}
     * @memberof Part2Dto
     */
    'recruitmentSubjectCount'?: number;
    /**
     * 
     * @type {string}
     * @memberof Part2Dto
     */
    'submissionDate'?: string;
}
/**
 * 
 * @export
 * @interface TaskActivityDto
 */
export interface TaskActivityDto {
    /**
     * 
     * @type {string}
     * @memberof TaskActivityDto
     */
    'recordedAt': string;
    /**
     * 
     * @type {string}
     * @memberof TaskActivityDto
     */
    'actorName': string;
    /**
     * 
     * @type {string}
     * @memberof TaskActivityDto
     */
    'activityType': string;
    /**
     * 
     * @type {string}
     * @memberof TaskActivityDto
     */
    'message': string;
    /**
     * 
     * @type {string}
     * @memberof TaskActivityDto
     */
    'payload'?: string;
}
/**
 * 
 * @export
 * @interface TaskDependencyDto
 */
export interface TaskDependencyDto {
    /**
     * 
     * @type {number}
     * @memberof TaskDependencyDto
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof TaskDependencyDto
     */
    'title': string;
    /**
     * 
     * @type {boolean}
     * @memberof TaskDependencyDto
     */
    'done': boolean;
    /**
     * 
     * @type {Array<TaskUserDto>}
     * @memberof TaskDependencyDto
     */
    'assignedTo': Array<TaskUserDto>;
    /**
     * 
     * @type {boolean}
     * @memberof TaskDependencyDto
     */
    'isDeleted': boolean;
    /**
     * 
     * @type {Array<MemberStateDto>}
     * @memberof TaskDependencyDto
     */
    'memberStates': Array<MemberStateDto>;
    /**
     * 
     * @type {boolean}
     * @memberof TaskDependencyDto
     */
    'isObsolete': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TaskDependencyDto
     */
    'isFaultyDependency': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TaskDependencyDto
     */
    'isBlockingPredecessor': boolean;
    /**
     * 
     * @type {string}
     * @memberof TaskDependencyDto
     */
    'dueDate'?: string;
    /**
     * 
     * @type {TaskExternalAssigneeDto}
     * @memberof TaskDependencyDto
     */
    'externalAssignee'?: TaskExternalAssigneeDto;
    /**
     * 
     * @type {TaskEthicsCommissionDto}
     * @memberof TaskDependencyDto
     */
    'ethicsCommission'?: TaskEthicsCommissionDto;
    /**
     * 
     * @type {TaskMeetingDto}
     * @memberof TaskDependencyDto
     */
    'meeting'?: TaskMeetingDto;
    /**
     * 
     * @type {string}
     * @memberof TaskDependencyDto
     */
    'status'?: string;
    /**
     * 
     * @type {string}
     * @memberof TaskDependencyDto
     */
    'origin'?: string;
    /**
     * 
     * @type {string}
     * @memberof TaskDependencyDto
     */
    'applicationId'?: string;
    /**
     * 
     * @type {string}
     * @memberof TaskDependencyDto
     */
    'applicationName'?: string;
    /**
     * 
     * @type {string}
     * @memberof TaskDependencyDto
     */
    'trialId'?: string;
}
/**
 * 
 * @export
 * @interface TaskDependencyEvaluationDto
 */
export interface TaskDependencyEvaluationDto {
    /**
     * 
     * @type {number}
     * @memberof TaskDependencyEvaluationDto
     */
    'taskId': number;
    /**
     * 
     * @type {string}
     * @memberof TaskDependencyEvaluationDto
     */
    'title': string;
    /**
     * 
     * @type {boolean}
     * @memberof TaskDependencyEvaluationDto
     */
    'done': boolean;
    /**
     * 
     * @type {Array<TaskUserDto>}
     * @memberof TaskDependencyEvaluationDto
     */
    'assignedTo': Array<TaskUserDto>;
    /**
     * 
     * @type {string}
     * @memberof TaskDependencyEvaluationDto
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof TaskDependencyEvaluationDto
     */
    'evaluation'?: string;
}
/**
 * 
 * @export
 * @interface TaskDependencyValidationDto
 */
export interface TaskDependencyValidationDto {
    /**
     * 
     * @type {TaskDto}
     * @memberof TaskDependencyValidationDto
     */
    'task': TaskDto;
    /**
     * 
     * @type {Array<TaskLinkDto>}
     * @memberof TaskDependencyValidationDto
     */
    'blockingPredecessors': Array<TaskLinkDto>;
}
/**
 * 
 * @export
 * @interface TaskDto
 */
export interface TaskDto {
    /**
     * 
     * @type {number}
     * @memberof TaskDto
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof TaskDto
     */
    'title': string;
    /**
     * 
     * @type {boolean}
     * @memberof TaskDto
     */
    'done': boolean;
    /**
     * 
     * @type {Array<TaskUserDto>}
     * @memberof TaskDto
     */
    'assignedTo': Array<TaskUserDto>;
    /**
     * 
     * @type {Array<TaskUserDto>}
     * @memberof TaskDto
     */
    'requiredUsers': Array<TaskUserDto>;
    /**
     * 
     * @type {boolean}
     * @memberof TaskDto
     */
    'requiresSession': boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof TaskDto
     */
    'dueDateStates': Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof TaskDto
     */
    'isDeleted': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TaskDto
     */
    'isObsolete': boolean;
    /**
     * 
     * @type {string}
     * @memberof TaskDto
     */
    'doneAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof TaskDto
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof TaskDto
     */
    'dueDate'?: string;
    /**
     * 
     * @type {TaskUserDto}
     * @memberof TaskDto
     */
    'createdBy'?: TaskUserDto;
    /**
     * 
     * @type {TaskTrialDto}
     * @memberof TaskDto
     */
    'trial'?: TaskTrialDto;
    /**
     * 
     * @type {TaskExternalAssigneeDto}
     * @memberof TaskDto
     */
    'externalAssignee'?: TaskExternalAssigneeDto;
    /**
     * 
     * @type {TaskEthicsCommissionDto}
     * @memberof TaskDto
     */
    'ethicsCommission'?: TaskEthicsCommissionDto;
    /**
     * 
     * @type {TaskMeetingDto}
     * @memberof TaskDto
     */
    'meeting'?: TaskMeetingDto;
    /**
     * 
     * @type {string}
     * @memberof TaskDto
     */
    'status'?: string;
    /**
     * 
     * @type {string}
     * @memberof TaskDto
     */
    'applicationId'?: string;
    /**
     * 
     * @type {string}
     * @memberof TaskDto
     */
    'applicationName'?: string;
    /**
     * 
     * @type {string}
     * @memberof TaskDto
     */
    'origin'?: string;
    /**
     * 
     * @type {string}
     * @memberof TaskDto
     */
    'memberStateRole'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof TaskDto
     */
    'reminderSent'?: boolean;
}
/**
 * 
 * @export
 * @interface TaskEthicsCommissionDto
 */
export interface TaskEthicsCommissionDto {
    /**
     * 
     * @type {number}
     * @memberof TaskEthicsCommissionDto
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof TaskEthicsCommissionDto
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface TaskEvaluationDto
 */
export interface TaskEvaluationDto {
    /**
     * 
     * @type {number}
     * @memberof TaskEvaluationDto
     */
    'taskId': number;
    /**
     * 
     * @type {string}
     * @memberof TaskEvaluationDto
     */
    'evaluation'?: string;
}
/**
 * 
 * @export
 * @interface TaskExternalAssigneeDto
 */
export interface TaskExternalAssigneeDto {
    /**
     * 
     * @type {string}
     * @memberof TaskExternalAssigneeDto
     */
    'key': string;
    /**
     * 
     * @type {string}
     * @memberof TaskExternalAssigneeDto
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface TaskLinkDto
 */
export interface TaskLinkDto {
    /**
     * 
     * @type {number}
     * @memberof TaskLinkDto
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof TaskLinkDto
     */
    'title': string;
}
/**
 * 
 * @export
 * @interface TaskMeetingDto
 */
export interface TaskMeetingDto {
    /**
     * 
     * @type {number}
     * @memberof TaskMeetingDto
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof TaskMeetingDto
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof TaskMeetingDto
     */
    'date': string;
}
/**
 * 
 * @export
 * @interface TaskTrialDto
 */
export interface TaskTrialDto {
    /**
     * 
     * @type {string}
     * @memberof TaskTrialDto
     */
    'trialId': string;
    /**
     * 
     * @type {boolean}
     * @memberof TaskTrialDto
     */
    'isTransitional': boolean;
    /**
     * 
     * @type {string}
     * @memberof TaskTrialDto
     */
    'autId'?: string;
}
/**
 * 
 * @export
 * @interface TaskUserDto
 */
export interface TaskUserDto {
    /**
     * 
     * @type {string}
     * @memberof TaskUserDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof TaskUserDto
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof TaskUserDto
     */
    'name'?: string;
}
/**
 * 
 * @export
 * @interface TrialDto
 */
export interface TrialDto {
    /**
     * 
     * @type {number}
     * @memberof TrialDto
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof TrialDto
     */
    'trialId': string;
    /**
     * 
     * @type {string}
     * @memberof TrialDto
     */
    'autId': string;
    /**
     * 
     * @type {string}
     * @memberof TrialDto
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof TrialDto
     */
    'status': string;
    /**
     * 
     * @type {Array<MemberStateDto>}
     * @memberof TrialDto
     */
    'memberStates': Array<MemberStateDto>;
    /**
     * 
     * @type {boolean}
     * @memberof TrialDto
     */
    'isTransitional': boolean;
    /**
     * 
     * @type {NextDueTaskDto}
     * @memberof TrialDto
     */
    'nextDueTask'?: NextDueTaskDto;
    /**
     * 
     * @type {EthicsCommissionDto}
     * @memberof TrialDto
     */
    'ethicsCommission'?: EthicsCommissionDto;
}
/**
 * 
 * @export
 * @interface TrialSettingsDto
 */
export interface TrialSettingsDto {
    /**
     * 
     * @type {number}
     * @memberof TrialSettingsDto
     */
    'ethicsCommissionId'?: number;
}
/**
 * 
 * @export
 * @interface TrialSettingsRequestDto
 */
export interface TrialSettingsRequestDto {
    /**
     * 
     * @type {number}
     * @memberof TrialSettingsRequestDto
     */
    'ethicsCommissionId': number;
}
/**
 * 
 * @export
 * @interface TrialSummaryDto
 */
export interface TrialSummaryDto {
    /**
     * 
     * @type {number}
     * @memberof TrialSummaryDto
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof TrialSummaryDto
     */
    'trialId': string;
    /**
     * 
     * @type {Array<ApplicationDto>}
     * @memberof TrialSummaryDto
     */
    'applications': Array<ApplicationDto>;
    /**
     * 
     * @type {string}
     * @memberof TrialSummaryDto
     */
    'title': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof TrialSummaryDto
     */
    'impNames': Array<string>;
    /**
     * 
     * @type {string}
     * @memberof TrialSummaryDto
     */
    'eudraCtCode'?: string;
}
/**
 * 
 * @export
 * @interface UpdateApplicationAssigneeDto
 */
export interface UpdateApplicationAssigneeDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateApplicationAssigneeDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateApplicationAssigneeDto
     */
    'type': string;
}
/**
 * 
 * @export
 * @interface UpdateConsiderationRequestDto
 */
export interface UpdateConsiderationRequestDto {
    /**
     * 
     * @type {number}
     * @memberof UpdateConsiderationRequestDto
     */
    'applicationSectionPartsId': number;
    /**
     * 
     * @type {number}
     * @memberof UpdateConsiderationRequestDto
     */
    'applicationSectionAndDocumentId': number;
    /**
     * 
     * @type {string}
     * @memberof UpdateConsiderationRequestDto
     */
    'consideration': string;
    /**
     * 
     * @type {number}
     * @memberof UpdateConsiderationRequestDto
     */
    'considerationId': number;
    /**
     * 
     * @type {number}
     * @memberof UpdateConsiderationRequestDto
     */
    'part1ConsiderationId'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateConsiderationRequestDto
     */
    'transferredToCtis'?: boolean;
}
/**
 * 
 * @export
 * @interface UpdateDocumentCommentRequestDto
 */
export interface UpdateDocumentCommentRequestDto {
    /**
     * 
     * @type {number}
     * @memberof UpdateDocumentCommentRequestDto
     */
    'documentId': number;
    /**
     * 
     * @type {number}
     * @memberof UpdateDocumentCommentRequestDto
     */
    'commentId': number;
    /**
     * 
     * @type {string}
     * @memberof UpdateDocumentCommentRequestDto
     */
    'relatesTo': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateDocumentCommentRequestDto
     */
    'comment': string;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateDocumentCommentRequestDto
     */
    'transferredToCtis': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateDocumentCommentRequestDto
     */
    'isCtisDocument': boolean;
}
/**
 * 
 * @export
 * @interface UpdateDocumentNameRequestDto
 */
export interface UpdateDocumentNameRequestDto {
    /**
     * 
     * @type {number}
     * @memberof UpdateDocumentNameRequestDto
     */
    'documentId': number;
    /**
     * 
     * @type {string}
     * @memberof UpdateDocumentNameRequestDto
     */
    'documentName': string;
}
/**
 * 
 * @export
 * @interface UpdateMeetingDto
 */
export interface UpdateMeetingDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateMeetingDto
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateMeetingDto
     */
    'startDateTime': string;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateMeetingDto
     */
    'shouldNotifyUsers': boolean;
    /**
     * 
     * @type {Array<MeetingTaskUpdateDto>}
     * @memberof UpdateMeetingDto
     */
    'tasks': Array<MeetingTaskUpdateDto>;
    /**
     * 
     * @type {number}
     * @memberof UpdateMeetingDto
     */
    'ethicsCommissionId'?: number;
}
/**
 * 
 * @export
 * @interface UpdateTaskDoneRequestDto
 */
export interface UpdateTaskDoneRequestDto {
    /**
     * 
     * @type {boolean}
     * @memberof UpdateTaskDoneRequestDto
     */
    'done': boolean;
}
/**
 * 
 * @export
 * @interface UpdateTaskDto
 */
export interface UpdateTaskDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateTaskDto
     */
    'title': string;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateTaskDto
     */
    'requiresSession': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateTaskDto
     */
    'shouldResolveConflicts': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateTaskDto
     */
    'done'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UpdateTaskDto
     */
    'trialId'?: string;
    /**
     * 
     * @type {number}
     * @memberof UpdateTaskDto
     */
    'ethicsCommissionId'?: number;
    /**
     * 
     * @type {string}
     * @memberof UpdateTaskDto
     */
    'description'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateTaskDto
     */
    'assignedTo'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateTaskDto
     */
    'requiredUsers'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof UpdateTaskDto
     */
    'dueDate'?: string;
    /**
     * 
     * @type {number}
     * @memberof UpdateTaskDto
     */
    'meetingId'?: number;
    /**
     * 
     * @type {string}
     * @memberof UpdateTaskDto
     */
    'applicationId'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateTaskDto
     */
    'isDeleted'?: boolean;
}
/**
 * 
 * @export
 * @interface UpdateTaskEvaluationDto
 */
export interface UpdateTaskEvaluationDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateTaskEvaluationDto
     */
    'evaluation'?: string;
}
/**
 * 
 * @export
 * @interface UpdateUserRequestDto
 */
export interface UpdateUserRequestDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateUserRequestDto
     */
    'name': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateUserRequestDto
     */
    'groupIds': Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateUserRequestDto
     */
    'notificationTypes': Array<string>;
    /**
     * 
     * @type {string}
     * @memberof UpdateUserRequestDto
     */
    'note'?: string;
    /**
     * 
     * @type {number}
     * @memberof UpdateUserRequestDto
     */
    'ethicsCommissionId'?: number;
}
/**
 * 
 * @export
 * @interface UpdateWorkflowRequestDto
 */
export interface UpdateWorkflowRequestDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateWorkflowRequestDto
     */
    'name': string;
    /**
     * 
     * @type {Array<number>}
     * @memberof UpdateWorkflowRequestDto
     */
    'ethicsCommissionIds'?: Array<number>;
    /**
     * 
     * @type {string}
     * @memberof UpdateWorkflowRequestDto
     */
    'description'?: string;
}
/**
 * 
 * @export
 * @interface UpdateWorkflowTaskRequestDto
 */
export interface UpdateWorkflowTaskRequestDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateWorkflowTaskRequestDto
     */
    'title': string;
    /**
     * 
     * @type {number}
     * @memberof UpdateWorkflowTaskRequestDto
     */
    'daysUntilDueDate': number;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateWorkflowTaskRequestDto
     */
    'requiresSession': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateWorkflowTaskRequestDto
     */
    'requiresEvaluation': boolean;
    /**
     * 
     * @type {string}
     * @memberof UpdateWorkflowTaskRequestDto
     */
    'description'?: string;
    /**
     * 
     * @type {Array<number>}
     * @memberof UpdateWorkflowTaskRequestDto
     */
    'predecessorIds'?: Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof UpdateWorkflowTaskRequestDto
     */
    'successorIds'?: Array<number>;
}
/**
 * 
 * @export
 * @interface UserAdminDto
 */
export interface UserAdminDto {
    /**
     * 
     * @type {string}
     * @memberof UserAdminDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof UserAdminDto
     */
    'email': string;
    /**
     * 
     * @type {Array<GroupDto>}
     * @memberof UserAdminDto
     */
    'groups': Array<GroupDto>;
    /**
     * 
     * @type {Array<string>}
     * @memberof UserAdminDto
     */
    'notificationTypes': Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof UserAdminDto
     */
    'enabled': boolean;
    /**
     * 
     * @type {string}
     * @memberof UserAdminDto
     */
    'name'?: string;
    /**
     * 
     * @type {EthicsCommissionDto}
     * @memberof UserAdminDto
     */
    'ethicsCommission'?: EthicsCommissionDto;
    /**
     * 
     * @type {string}
     * @memberof UserAdminDto
     */
    'note'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserAdminDto
     */
    'lastPasswordChange'?: string;
}
/**
 * 
 * @export
 * @interface UserDetailDto
 */
export interface UserDetailDto {
    /**
     * 
     * @type {string}
     * @memberof UserDetailDto
     */
    'id': string;
    /**
     * 
     * @type {EthicsCommissionDto}
     * @memberof UserDetailDto
     */
    'ethicsCommission'?: EthicsCommissionDto;
}
/**
 * 
 * @export
 * @interface UserDto
 */
export interface UserDto {
    /**
     * 
     * @type {string}
     * @memberof UserDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof UserDto
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof UserDto
     */
    'name'?: string;
}
/**
 * 
 * @export
 * @interface UserProfileDto
 */
export interface UserProfileDto {
    /**
     * 
     * @type {UserDto}
     * @memberof UserProfileDto
     */
    'user': UserDto;
    /**
     * 
     * @type {Array<string>}
     * @memberof UserProfileDto
     */
    'permissions': Array<string>;
}
/**
 * 
 * @export
 * @interface WorkflowDto
 */
export interface WorkflowDto {
    /**
     * 
     * @type {number}
     * @memberof WorkflowDto
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof WorkflowDto
     */
    'name': string;
    /**
     * 
     * @type {Array<EthicsCommissionDto>}
     * @memberof WorkflowDto
     */
    'ethicsCommissions': Array<EthicsCommissionDto>;
    /**
     * 
     * @type {Array<WorkflowTaskDto>}
     * @memberof WorkflowDto
     */
    'tasks': Array<WorkflowTaskDto>;
    /**
     * 
     * @type {string}
     * @memberof WorkflowDto
     */
    'description'?: string;
}
/**
 * 
 * @export
 * @interface WorkflowTaskDto
 */
export interface WorkflowTaskDto {
    /**
     * 
     * @type {number}
     * @memberof WorkflowTaskDto
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof WorkflowTaskDto
     */
    'title': string;
    /**
     * 
     * @type {number}
     * @memberof WorkflowTaskDto
     */
    'daysUntilDueDate': number;
    /**
     * 
     * @type {boolean}
     * @memberof WorkflowTaskDto
     */
    'requiresSession': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof WorkflowTaskDto
     */
    'requiresEvaluation': boolean;
    /**
     * 
     * @type {Array<number>}
     * @memberof WorkflowTaskDto
     */
    'predecessorId': Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof WorkflowTaskDto
     */
    'successorId': Array<number>;
    /**
     * 
     * @type {string}
     * @memberof WorkflowTaskDto
     */
    'description'?: string;
}
/**
 * 
 * @export
 * @interface WrappedEnumDto
 */
export interface WrappedEnumDto {
    /**
     * 
     * @type {string}
     * @memberof WrappedEnumDto
     */
    'value': string;
    /**
     * 
     * @type {string}
     * @memberof WrappedEnumDto
     */
    'text': string;
}

/**
 * AdminControllerApi - axios parameter creator
 * @export
 */
export const AdminControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateUserRequestDto} createUserRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUser: async (createUserRequestDto: CreateUserRequestDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createUserRequestDto' is not null or undefined
            assertParamExists('createUser', 'createUserRequestDto', createUserRequestDto)
            const localVarPath = `/api/v1/admin/users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createUserRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUser: async (userId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('deleteUser', 'userId', userId)
            const localVarPath = `/api/v1/admin/users/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAvailableNotificationTypes: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/admin/available-notification-types`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGroups: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/admin/available-roles`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUser: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getUser', 'id', id)
            const localVarPath = `/api/v1/admin/users/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [searchTerm] 
         * @param {string} [sortBy] 
         * @param {boolean} [sortDesc] 
         * @param {Array<number>} [ethicsCommissions] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUsers: async (page?: number, size?: number, searchTerm?: string, sortBy?: string, sortDesc?: boolean, ethicsCommissions?: Array<number>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/admin/users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (searchTerm !== undefined) {
                localVarQueryParameter['searchTerm'] = searchTerm;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (sortDesc !== undefined) {
                localVarQueryParameter['sortDesc'] = sortDesc;
            }

            if (ethicsCommissions) {
                localVarQueryParameter['ethicsCommissions'] = ethicsCommissions;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetPassword: async (userId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('resetPassword', 'userId', userId)
            const localVarPath = `/api/v1/admin/users/{userId}/reset-password`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} userId 
         * @param {UpdateUserRequestDto} updateUserRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUser: async (userId: string, updateUserRequestDto: UpdateUserRequestDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('updateUser', 'userId', userId)
            // verify required parameter 'updateUserRequestDto' is not null or undefined
            assertParamExists('updateUser', 'updateUserRequestDto', updateUserRequestDto)
            const localVarPath = `/api/v1/admin/users/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateUserRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminControllerApi - functional programming interface
 * @export
 */
export const AdminControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CreateUserRequestDto} createUserRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createUser(createUserRequestDto: CreateUserRequestDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserAdminDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createUser(createUserRequestDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteUser(userId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteUser(userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAvailableNotificationTypes(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAvailableNotificationTypes(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getGroups(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<GroupDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getGroups(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUser(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserAdminDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUser(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [searchTerm] 
         * @param {string} [sortBy] 
         * @param {boolean} [sortDesc] 
         * @param {Array<number>} [ethicsCommissions] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUsers(page?: number, size?: number, searchTerm?: string, sortBy?: string, sortDesc?: boolean, ethicsCommissions?: Array<number>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageResponseDtoUserAdminDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUsers(page, size, searchTerm, sortBy, sortDesc, ethicsCommissions, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async resetPassword(userId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.resetPassword(userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} userId 
         * @param {UpdateUserRequestDto} updateUserRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateUser(userId: string, updateUserRequestDto: UpdateUserRequestDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserAdminDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateUser(userId, updateUserRequestDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AdminControllerApi - factory interface
 * @export
 */
export const AdminControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {CreateUserRequestDto} createUserRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUser(createUserRequestDto: CreateUserRequestDto, options?: any): AxiosPromise<UserAdminDto> {
            return localVarFp.createUser(createUserRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUser(userId: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteUser(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAvailableNotificationTypes(options?: any): AxiosPromise<Array<string>> {
            return localVarFp.getAvailableNotificationTypes(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGroups(options?: any): AxiosPromise<Array<GroupDto>> {
            return localVarFp.getGroups(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUser(id: string, options?: any): AxiosPromise<UserAdminDto> {
            return localVarFp.getUser(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [searchTerm] 
         * @param {string} [sortBy] 
         * @param {boolean} [sortDesc] 
         * @param {Array<number>} [ethicsCommissions] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUsers(page?: number, size?: number, searchTerm?: string, sortBy?: string, sortDesc?: boolean, ethicsCommissions?: Array<number>, options?: any): AxiosPromise<PageResponseDtoUserAdminDto> {
            return localVarFp.getUsers(page, size, searchTerm, sortBy, sortDesc, ethicsCommissions, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetPassword(userId: string, options?: any): AxiosPromise<void> {
            return localVarFp.resetPassword(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} userId 
         * @param {UpdateUserRequestDto} updateUserRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUser(userId: string, updateUserRequestDto: UpdateUserRequestDto, options?: any): AxiosPromise<UserAdminDto> {
            return localVarFp.updateUser(userId, updateUserRequestDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AdminControllerApi - object-oriented interface
 * @export
 * @class AdminControllerApi
 * @extends {BaseAPI}
 */
export class AdminControllerApi extends BaseAPI {
    /**
     * 
     * @param {CreateUserRequestDto} createUserRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminControllerApi
     */
    public createUser(createUserRequestDto: CreateUserRequestDto, options?: AxiosRequestConfig) {
        return AdminControllerApiFp(this.configuration).createUser(createUserRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminControllerApi
     */
    public deleteUser(userId: string, options?: AxiosRequestConfig) {
        return AdminControllerApiFp(this.configuration).deleteUser(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminControllerApi
     */
    public getAvailableNotificationTypes(options?: AxiosRequestConfig) {
        return AdminControllerApiFp(this.configuration).getAvailableNotificationTypes(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminControllerApi
     */
    public getGroups(options?: AxiosRequestConfig) {
        return AdminControllerApiFp(this.configuration).getGroups(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminControllerApi
     */
    public getUser(id: string, options?: AxiosRequestConfig) {
        return AdminControllerApiFp(this.configuration).getUser(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [page] 
     * @param {number} [size] 
     * @param {string} [searchTerm] 
     * @param {string} [sortBy] 
     * @param {boolean} [sortDesc] 
     * @param {Array<number>} [ethicsCommissions] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminControllerApi
     */
    public getUsers(page?: number, size?: number, searchTerm?: string, sortBy?: string, sortDesc?: boolean, ethicsCommissions?: Array<number>, options?: AxiosRequestConfig) {
        return AdminControllerApiFp(this.configuration).getUsers(page, size, searchTerm, sortBy, sortDesc, ethicsCommissions, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminControllerApi
     */
    public resetPassword(userId: string, options?: AxiosRequestConfig) {
        return AdminControllerApiFp(this.configuration).resetPassword(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} userId 
     * @param {UpdateUserRequestDto} updateUserRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminControllerApi
     */
    public updateUser(userId: string, updateUserRequestDto: UpdateUserRequestDto, options?: AxiosRequestConfig) {
        return AdminControllerApiFp(this.configuration).updateUser(userId, updateUserRequestDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ConsiderationControllerApi - axios parameter creator
 * @export
 */
export const ConsiderationControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateConsiderationRequestDto} createConsiderationRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createConsideration: async (createConsiderationRequestDto: CreateConsiderationRequestDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createConsiderationRequestDto' is not null or undefined
            assertParamExists('createConsideration', 'createConsiderationRequestDto', createConsiderationRequestDto)
            const localVarPath = `/api/v1/considerations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createConsiderationRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} considerationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteConsideration: async (considerationId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'considerationId' is not null or undefined
            assertParamExists('deleteConsideration', 'considerationId', considerationId)
            const localVarPath = `/api/v1/considerations/{considerationId}`
                .replace(`{${"considerationId"}}`, encodeURIComponent(String(considerationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} applicationId 
         * @param {string} [evaluationProcess] 
         * @param {Array<string>} [applicationPart] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getConsiderationsOfApplication: async (applicationId: string, evaluationProcess?: string, applicationPart?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'applicationId' is not null or undefined
            assertParamExists('getConsiderationsOfApplication', 'applicationId', applicationId)
            const localVarPath = `/api/v1/considerations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (applicationId !== undefined) {
                localVarQueryParameter['applicationId'] = applicationId;
            }

            if (evaluationProcess !== undefined) {
                localVarQueryParameter['evaluationProcess'] = evaluationProcess;
            }

            if (applicationPart) {
                localVarQueryParameter['applicationPart'] = applicationPart;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} applicationId 
         * @param {string} [evaluationProcess] 
         * @param {Array<string>} [countryCodes] 
         * @param {Array<string>} [applicationPart] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getExternalConsiderationsOfApplication: async (applicationId: string, evaluationProcess?: string, countryCodes?: Array<string>, applicationPart?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'applicationId' is not null or undefined
            assertParamExists('getExternalConsiderationsOfApplication', 'applicationId', applicationId)
            const localVarPath = `/api/v1/considerations/external`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (applicationId !== undefined) {
                localVarQueryParameter['applicationId'] = applicationId;
            }

            if (evaluationProcess !== undefined) {
                localVarQueryParameter['evaluationProcess'] = evaluationProcess;
            }

            if (countryCodes) {
                localVarQueryParameter['countryCodes'] = countryCodes;
            }

            if (applicationPart) {
                localVarQueryParameter['applicationPart'] = applicationPart;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateConsiderationRequestDto} updateConsiderationRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateConsideration: async (updateConsiderationRequestDto: UpdateConsiderationRequestDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateConsiderationRequestDto' is not null or undefined
            assertParamExists('updateConsideration', 'updateConsiderationRequestDto', updateConsiderationRequestDto)
            const localVarPath = `/api/v1/considerations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateConsiderationRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ConsiderationControllerApi - functional programming interface
 * @export
 */
export const ConsiderationControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ConsiderationControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CreateConsiderationRequestDto} createConsiderationRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createConsideration(createConsiderationRequestDto: CreateConsiderationRequestDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ConsiderationDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createConsideration(createConsiderationRequestDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} considerationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteConsideration(considerationId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteConsideration(considerationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} applicationId 
         * @param {string} [evaluationProcess] 
         * @param {Array<string>} [applicationPart] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getConsiderationsOfApplication(applicationId: string, evaluationProcess?: string, applicationPart?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ConsiderationDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getConsiderationsOfApplication(applicationId, evaluationProcess, applicationPart, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} applicationId 
         * @param {string} [evaluationProcess] 
         * @param {Array<string>} [countryCodes] 
         * @param {Array<string>} [applicationPart] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getExternalConsiderationsOfApplication(applicationId: string, evaluationProcess?: string, countryCodes?: Array<string>, applicationPart?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ExternalConsiderationDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getExternalConsiderationsOfApplication(applicationId, evaluationProcess, countryCodes, applicationPart, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {UpdateConsiderationRequestDto} updateConsiderationRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateConsideration(updateConsiderationRequestDto: UpdateConsiderationRequestDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ConsiderationDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateConsideration(updateConsiderationRequestDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ConsiderationControllerApi - factory interface
 * @export
 */
export const ConsiderationControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ConsiderationControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {CreateConsiderationRequestDto} createConsiderationRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createConsideration(createConsiderationRequestDto: CreateConsiderationRequestDto, options?: any): AxiosPromise<ConsiderationDto> {
            return localVarFp.createConsideration(createConsiderationRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} considerationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteConsideration(considerationId: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteConsideration(considerationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} applicationId 
         * @param {string} [evaluationProcess] 
         * @param {Array<string>} [applicationPart] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getConsiderationsOfApplication(applicationId: string, evaluationProcess?: string, applicationPart?: Array<string>, options?: any): AxiosPromise<Array<ConsiderationDto>> {
            return localVarFp.getConsiderationsOfApplication(applicationId, evaluationProcess, applicationPart, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} applicationId 
         * @param {string} [evaluationProcess] 
         * @param {Array<string>} [countryCodes] 
         * @param {Array<string>} [applicationPart] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getExternalConsiderationsOfApplication(applicationId: string, evaluationProcess?: string, countryCodes?: Array<string>, applicationPart?: Array<string>, options?: any): AxiosPromise<Array<ExternalConsiderationDto>> {
            return localVarFp.getExternalConsiderationsOfApplication(applicationId, evaluationProcess, countryCodes, applicationPart, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpdateConsiderationRequestDto} updateConsiderationRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateConsideration(updateConsiderationRequestDto: UpdateConsiderationRequestDto, options?: any): AxiosPromise<ConsiderationDto> {
            return localVarFp.updateConsideration(updateConsiderationRequestDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ConsiderationControllerApi - object-oriented interface
 * @export
 * @class ConsiderationControllerApi
 * @extends {BaseAPI}
 */
export class ConsiderationControllerApi extends BaseAPI {
    /**
     * 
     * @param {CreateConsiderationRequestDto} createConsiderationRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConsiderationControllerApi
     */
    public createConsideration(createConsiderationRequestDto: CreateConsiderationRequestDto, options?: AxiosRequestConfig) {
        return ConsiderationControllerApiFp(this.configuration).createConsideration(createConsiderationRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} considerationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConsiderationControllerApi
     */
    public deleteConsideration(considerationId: number, options?: AxiosRequestConfig) {
        return ConsiderationControllerApiFp(this.configuration).deleteConsideration(considerationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} applicationId 
     * @param {string} [evaluationProcess] 
     * @param {Array<string>} [applicationPart] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConsiderationControllerApi
     */
    public getConsiderationsOfApplication(applicationId: string, evaluationProcess?: string, applicationPart?: Array<string>, options?: AxiosRequestConfig) {
        return ConsiderationControllerApiFp(this.configuration).getConsiderationsOfApplication(applicationId, evaluationProcess, applicationPart, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} applicationId 
     * @param {string} [evaluationProcess] 
     * @param {Array<string>} [countryCodes] 
     * @param {Array<string>} [applicationPart] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConsiderationControllerApi
     */
    public getExternalConsiderationsOfApplication(applicationId: string, evaluationProcess?: string, countryCodes?: Array<string>, applicationPart?: Array<string>, options?: AxiosRequestConfig) {
        return ConsiderationControllerApiFp(this.configuration).getExternalConsiderationsOfApplication(applicationId, evaluationProcess, countryCodes, applicationPart, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UpdateConsiderationRequestDto} updateConsiderationRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConsiderationControllerApi
     */
    public updateConsideration(updateConsiderationRequestDto: UpdateConsiderationRequestDto, options?: AxiosRequestConfig) {
        return ConsiderationControllerApiFp(this.configuration).updateConsideration(updateConsiderationRequestDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * EthicsCommissionControllerApi - axios parameter creator
 * @export
 */
export const EthicsCommissionControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllEthicCommissions: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/ethics-commissions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * EthicsCommissionControllerApi - functional programming interface
 * @export
 */
export const EthicsCommissionControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = EthicsCommissionControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllEthicCommissions(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<EthicsCommissionDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllEthicCommissions(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * EthicsCommissionControllerApi - factory interface
 * @export
 */
export const EthicsCommissionControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = EthicsCommissionControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllEthicCommissions(options?: any): AxiosPromise<Array<EthicsCommissionDto>> {
            return localVarFp.getAllEthicCommissions(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * EthicsCommissionControllerApi - object-oriented interface
 * @export
 * @class EthicsCommissionControllerApi
 * @extends {BaseAPI}
 */
export class EthicsCommissionControllerApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EthicsCommissionControllerApi
     */
    public getAllEthicCommissions(options?: AxiosRequestConfig) {
        return EthicsCommissionControllerApiFp(this.configuration).getAllEthicCommissions(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * EvaluationControllerApi - axios parameter creator
 * @export
 */
export const EvaluationControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateDocumentCommentRequestDto} createDocumentCommentRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDocumentComment: async (createDocumentCommentRequestDto: CreateDocumentCommentRequestDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createDocumentCommentRequestDto' is not null or undefined
            assertParamExists('createDocumentComment', 'createDocumentCommentRequestDto', createDocumentCommentRequestDto)
            const localVarPath = `/api/v1/applications/evaluations/documents/comments`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createDocumentCommentRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} documentId 
         * @param {number} systemVersion 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBinaryContentOfDocument: async (documentId: number, systemVersion: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'documentId' is not null or undefined
            assertParamExists('getBinaryContentOfDocument', 'documentId', documentId)
            // verify required parameter 'systemVersion' is not null or undefined
            assertParamExists('getBinaryContentOfDocument', 'systemVersion', systemVersion)
            const localVarPath = `/api/v1/applications/evaluations/documents/{documentId}/{systemVersion}`
                .replace(`{${"documentId"}}`, encodeURIComponent(String(documentId)))
                .replace(`{${"systemVersion"}}`, encodeURIComponent(String(systemVersion)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} applicationId 
         * @param {string} evaluationProcess 
         * @param {string} evaluationProcessSection 
         * @param {Array<string>} [preferredLanguages] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEvaluationDocuments: async (applicationId: string, evaluationProcess: string, evaluationProcessSection: string, preferredLanguages?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'applicationId' is not null or undefined
            assertParamExists('getEvaluationDocuments', 'applicationId', applicationId)
            // verify required parameter 'evaluationProcess' is not null or undefined
            assertParamExists('getEvaluationDocuments', 'evaluationProcess', evaluationProcess)
            // verify required parameter 'evaluationProcessSection' is not null or undefined
            assertParamExists('getEvaluationDocuments', 'evaluationProcessSection', evaluationProcessSection)
            const localVarPath = `/api/v1/applications/{applicationId}/evaluation/{evaluationProcess}/{evaluationProcessSection}`
                .replace(`{${"applicationId"}}`, encodeURIComponent(String(applicationId)))
                .replace(`{${"evaluationProcess"}}`, encodeURIComponent(String(evaluationProcess)))
                .replace(`{${"evaluationProcessSection"}}`, encodeURIComponent(String(evaluationProcessSection)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (preferredLanguages) {
                localVarQueryParameter['preferredLanguages'] = preferredLanguages;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateDocumentCommentRequestDto} updateDocumentCommentRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDocumentComment: async (updateDocumentCommentRequestDto: UpdateDocumentCommentRequestDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateDocumentCommentRequestDto' is not null or undefined
            assertParamExists('updateDocumentComment', 'updateDocumentCommentRequestDto', updateDocumentCommentRequestDto)
            const localVarPath = `/api/v1/applications/evaluations/documents/comments`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateDocumentCommentRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateDocumentNameRequestDto} updateDocumentNameRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDocumentName: async (updateDocumentNameRequestDto: UpdateDocumentNameRequestDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateDocumentNameRequestDto' is not null or undefined
            assertParamExists('updateDocumentName', 'updateDocumentNameRequestDto', updateDocumentNameRequestDto)
            const localVarPath = `/api/v1/applications/evaluations/documents/name`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateDocumentNameRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [applicationId] 
         * @param {string} [evaluationProcess] 
         * @param {string} [evaluationProcessSection] 
         * @param {number} [documentId] 
         * @param {any} [file] 
         * @param {string} [rfiSequenceNumber] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadDocument: async (applicationId?: string, evaluationProcess?: string, evaluationProcessSection?: string, documentId?: number, file?: any, rfiSequenceNumber?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/applications/evaluations/documents`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();


            if (applicationId !== undefined) { 
                localVarFormParams.append('applicationId', applicationId as any);
            }
    
            if (evaluationProcess !== undefined) { 
                localVarFormParams.append('evaluationProcess', evaluationProcess as any);
            }
    
            if (evaluationProcessSection !== undefined) { 
                localVarFormParams.append('evaluationProcessSection', evaluationProcessSection as any);
            }
    
            if (documentId !== undefined) { 
                localVarFormParams.append('documentId', documentId as any);
            }
    
            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
            if (rfiSequenceNumber !== undefined) { 
                localVarFormParams.append('rfiSequenceNumber', rfiSequenceNumber as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * EvaluationControllerApi - functional programming interface
 * @export
 */
export const EvaluationControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = EvaluationControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CreateDocumentCommentRequestDto} createDocumentCommentRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createDocumentComment(createDocumentCommentRequestDto: CreateDocumentCommentRequestDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DocumentCommentDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createDocumentComment(createDocumentCommentRequestDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} documentId 
         * @param {number} systemVersion 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBinaryContentOfDocument(documentId: number, systemVersion: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBinaryContentOfDocument(documentId, systemVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} applicationId 
         * @param {string} evaluationProcess 
         * @param {string} evaluationProcessSection 
         * @param {Array<string>} [preferredLanguages] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEvaluationDocuments(applicationId: string, evaluationProcess: string, evaluationProcessSection: string, preferredLanguages?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<EvaluationDocumentDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getEvaluationDocuments(applicationId, evaluationProcess, evaluationProcessSection, preferredLanguages, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {UpdateDocumentCommentRequestDto} updateDocumentCommentRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateDocumentComment(updateDocumentCommentRequestDto: UpdateDocumentCommentRequestDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DocumentCommentDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateDocumentComment(updateDocumentCommentRequestDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {UpdateDocumentNameRequestDto} updateDocumentNameRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateDocumentName(updateDocumentNameRequestDto: UpdateDocumentNameRequestDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EvaluationDocumentDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateDocumentName(updateDocumentNameRequestDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [applicationId] 
         * @param {string} [evaluationProcess] 
         * @param {string} [evaluationProcessSection] 
         * @param {number} [documentId] 
         * @param {any} [file] 
         * @param {string} [rfiSequenceNumber] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadDocument(applicationId?: string, evaluationProcess?: string, evaluationProcessSection?: string, documentId?: number, file?: any, rfiSequenceNumber?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EvaluationDocumentDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uploadDocument(applicationId, evaluationProcess, evaluationProcessSection, documentId, file, rfiSequenceNumber, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * EvaluationControllerApi - factory interface
 * @export
 */
export const EvaluationControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = EvaluationControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {CreateDocumentCommentRequestDto} createDocumentCommentRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDocumentComment(createDocumentCommentRequestDto: CreateDocumentCommentRequestDto, options?: any): AxiosPromise<DocumentCommentDto> {
            return localVarFp.createDocumentComment(createDocumentCommentRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} documentId 
         * @param {number} systemVersion 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBinaryContentOfDocument(documentId: number, systemVersion: number, options?: any): AxiosPromise<string> {
            return localVarFp.getBinaryContentOfDocument(documentId, systemVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} applicationId 
         * @param {string} evaluationProcess 
         * @param {string} evaluationProcessSection 
         * @param {Array<string>} [preferredLanguages] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEvaluationDocuments(applicationId: string, evaluationProcess: string, evaluationProcessSection: string, preferredLanguages?: Array<string>, options?: any): AxiosPromise<Array<EvaluationDocumentDto>> {
            return localVarFp.getEvaluationDocuments(applicationId, evaluationProcess, evaluationProcessSection, preferredLanguages, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpdateDocumentCommentRequestDto} updateDocumentCommentRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDocumentComment(updateDocumentCommentRequestDto: UpdateDocumentCommentRequestDto, options?: any): AxiosPromise<DocumentCommentDto> {
            return localVarFp.updateDocumentComment(updateDocumentCommentRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpdateDocumentNameRequestDto} updateDocumentNameRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDocumentName(updateDocumentNameRequestDto: UpdateDocumentNameRequestDto, options?: any): AxiosPromise<EvaluationDocumentDto> {
            return localVarFp.updateDocumentName(updateDocumentNameRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [applicationId] 
         * @param {string} [evaluationProcess] 
         * @param {string} [evaluationProcessSection] 
         * @param {number} [documentId] 
         * @param {any} [file] 
         * @param {string} [rfiSequenceNumber] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadDocument(applicationId?: string, evaluationProcess?: string, evaluationProcessSection?: string, documentId?: number, file?: any, rfiSequenceNumber?: string, options?: any): AxiosPromise<EvaluationDocumentDto> {
            return localVarFp.uploadDocument(applicationId, evaluationProcess, evaluationProcessSection, documentId, file, rfiSequenceNumber, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * EvaluationControllerApi - object-oriented interface
 * @export
 * @class EvaluationControllerApi
 * @extends {BaseAPI}
 */
export class EvaluationControllerApi extends BaseAPI {
    /**
     * 
     * @param {CreateDocumentCommentRequestDto} createDocumentCommentRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EvaluationControllerApi
     */
    public createDocumentComment(createDocumentCommentRequestDto: CreateDocumentCommentRequestDto, options?: AxiosRequestConfig) {
        return EvaluationControllerApiFp(this.configuration).createDocumentComment(createDocumentCommentRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} documentId 
     * @param {number} systemVersion 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EvaluationControllerApi
     */
    public getBinaryContentOfDocument(documentId: number, systemVersion: number, options?: AxiosRequestConfig) {
        return EvaluationControllerApiFp(this.configuration).getBinaryContentOfDocument(documentId, systemVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} applicationId 
     * @param {string} evaluationProcess 
     * @param {string} evaluationProcessSection 
     * @param {Array<string>} [preferredLanguages] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EvaluationControllerApi
     */
    public getEvaluationDocuments(applicationId: string, evaluationProcess: string, evaluationProcessSection: string, preferredLanguages?: Array<string>, options?: AxiosRequestConfig) {
        return EvaluationControllerApiFp(this.configuration).getEvaluationDocuments(applicationId, evaluationProcess, evaluationProcessSection, preferredLanguages, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UpdateDocumentCommentRequestDto} updateDocumentCommentRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EvaluationControllerApi
     */
    public updateDocumentComment(updateDocumentCommentRequestDto: UpdateDocumentCommentRequestDto, options?: AxiosRequestConfig) {
        return EvaluationControllerApiFp(this.configuration).updateDocumentComment(updateDocumentCommentRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UpdateDocumentNameRequestDto} updateDocumentNameRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EvaluationControllerApi
     */
    public updateDocumentName(updateDocumentNameRequestDto: UpdateDocumentNameRequestDto, options?: AxiosRequestConfig) {
        return EvaluationControllerApiFp(this.configuration).updateDocumentName(updateDocumentNameRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [applicationId] 
     * @param {string} [evaluationProcess] 
     * @param {string} [evaluationProcessSection] 
     * @param {number} [documentId] 
     * @param {any} [file] 
     * @param {string} [rfiSequenceNumber] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EvaluationControllerApi
     */
    public uploadDocument(applicationId?: string, evaluationProcess?: string, evaluationProcessSection?: string, documentId?: number, file?: any, rfiSequenceNumber?: string, options?: AxiosRequestConfig) {
        return EvaluationControllerApiFp(this.configuration).uploadDocument(applicationId, evaluationProcess, evaluationProcessSection, documentId, file, rfiSequenceNumber, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * MeetingControllerApi - axios parameter creator
 * @export
 */
export const MeetingControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateMeetingDto} createMeetingDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createMeeting: async (createMeetingDto: CreateMeetingDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createMeetingDto' is not null or undefined
            assertParamExists('createMeeting', 'createMeetingDto', createMeetingDto)
            const localVarPath = `/api/v1/meetings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createMeetingDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sortBy] 
         * @param {boolean} [sortDesc] 
         * @param {string} [fromDateTime] 
         * @param {string} [toDateTime] 
         * @param {number} [ecId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllMeetings: async (page?: number, size?: number, sortBy?: string, sortDesc?: boolean, fromDateTime?: string, toDateTime?: string, ecId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/meetings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (sortDesc !== undefined) {
                localVarQueryParameter['sortDesc'] = sortDesc;
            }

            if (fromDateTime !== undefined) {
                localVarQueryParameter['fromDateTime'] = (fromDateTime as any instanceof Date) ?
                    (fromDateTime as any).toISOString() :
                    fromDateTime;
            }

            if (toDateTime !== undefined) {
                localVarQueryParameter['toDateTime'] = (toDateTime as any instanceof Date) ?
                    (toDateTime as any).toISOString() :
                    toDateTime;
            }

            if (ecId !== undefined) {
                localVarQueryParameter['ecId'] = ecId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAssignedTasks: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getAssignedTasks', 'id', id)
            const localVarPath = `/api/v1/meetings/{id}/assigned-tasks`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBlockingPredecessors: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getBlockingPredecessors', 'id', id)
            const localVarPath = `/api/v1/meetings/{id}/blocking-predecessors`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMeeting: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getMeeting', 'id', id)
            const localVarPath = `/api/v1/meetings/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} meetingId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendInvitations: async (meetingId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'meetingId' is not null or undefined
            assertParamExists('sendInvitations', 'meetingId', meetingId)
            const localVarPath = `/api/v1/meetings/{meetingId}/send-invitations`
                .replace(`{${"meetingId"}}`, encodeURIComponent(String(meetingId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {UpdateMeetingDto} updateMeetingDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMeeting: async (id: number, updateMeetingDto: UpdateMeetingDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateMeeting', 'id', id)
            // verify required parameter 'updateMeetingDto' is not null or undefined
            assertParamExists('updateMeeting', 'updateMeetingDto', updateMeetingDto)
            const localVarPath = `/api/v1/meetings/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateMeetingDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MeetingControllerApi - functional programming interface
 * @export
 */
export const MeetingControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MeetingControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CreateMeetingDto} createMeetingDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createMeeting(createMeetingDto: CreateMeetingDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MeetingDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createMeeting(createMeetingDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sortBy] 
         * @param {boolean} [sortDesc] 
         * @param {string} [fromDateTime] 
         * @param {string} [toDateTime] 
         * @param {number} [ecId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllMeetings(page?: number, size?: number, sortBy?: string, sortDesc?: boolean, fromDateTime?: string, toDateTime?: string, ecId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageResponseDtoMeetingDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllMeetings(page, size, sortBy, sortDesc, fromDateTime, toDateTime, ecId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAssignedTasks(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<MeetingTaskDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAssignedTasks(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBlockingPredecessors(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TaskDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBlockingPredecessors(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMeeting(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MeetingDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMeeting(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} meetingId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sendInvitations(meetingId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sendInvitations(meetingId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {UpdateMeetingDto} updateMeetingDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateMeeting(id: number, updateMeetingDto: UpdateMeetingDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MeetingDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateMeeting(id, updateMeetingDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * MeetingControllerApi - factory interface
 * @export
 */
export const MeetingControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MeetingControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {CreateMeetingDto} createMeetingDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createMeeting(createMeetingDto: CreateMeetingDto, options?: any): AxiosPromise<MeetingDto> {
            return localVarFp.createMeeting(createMeetingDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sortBy] 
         * @param {boolean} [sortDesc] 
         * @param {string} [fromDateTime] 
         * @param {string} [toDateTime] 
         * @param {number} [ecId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllMeetings(page?: number, size?: number, sortBy?: string, sortDesc?: boolean, fromDateTime?: string, toDateTime?: string, ecId?: number, options?: any): AxiosPromise<PageResponseDtoMeetingDto> {
            return localVarFp.getAllMeetings(page, size, sortBy, sortDesc, fromDateTime, toDateTime, ecId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAssignedTasks(id: number, options?: any): AxiosPromise<Array<MeetingTaskDto>> {
            return localVarFp.getAssignedTasks(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBlockingPredecessors(id: number, options?: any): AxiosPromise<Array<TaskDto>> {
            return localVarFp.getBlockingPredecessors(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMeeting(id: number, options?: any): AxiosPromise<MeetingDto> {
            return localVarFp.getMeeting(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} meetingId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendInvitations(meetingId: number, options?: any): AxiosPromise<void> {
            return localVarFp.sendInvitations(meetingId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {UpdateMeetingDto} updateMeetingDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMeeting(id: number, updateMeetingDto: UpdateMeetingDto, options?: any): AxiosPromise<MeetingDto> {
            return localVarFp.updateMeeting(id, updateMeetingDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * MeetingControllerApi - object-oriented interface
 * @export
 * @class MeetingControllerApi
 * @extends {BaseAPI}
 */
export class MeetingControllerApi extends BaseAPI {
    /**
     * 
     * @param {CreateMeetingDto} createMeetingDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MeetingControllerApi
     */
    public createMeeting(createMeetingDto: CreateMeetingDto, options?: AxiosRequestConfig) {
        return MeetingControllerApiFp(this.configuration).createMeeting(createMeetingDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [page] 
     * @param {number} [size] 
     * @param {string} [sortBy] 
     * @param {boolean} [sortDesc] 
     * @param {string} [fromDateTime] 
     * @param {string} [toDateTime] 
     * @param {number} [ecId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MeetingControllerApi
     */
    public getAllMeetings(page?: number, size?: number, sortBy?: string, sortDesc?: boolean, fromDateTime?: string, toDateTime?: string, ecId?: number, options?: AxiosRequestConfig) {
        return MeetingControllerApiFp(this.configuration).getAllMeetings(page, size, sortBy, sortDesc, fromDateTime, toDateTime, ecId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MeetingControllerApi
     */
    public getAssignedTasks(id: number, options?: AxiosRequestConfig) {
        return MeetingControllerApiFp(this.configuration).getAssignedTasks(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MeetingControllerApi
     */
    public getBlockingPredecessors(id: number, options?: AxiosRequestConfig) {
        return MeetingControllerApiFp(this.configuration).getBlockingPredecessors(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MeetingControllerApi
     */
    public getMeeting(id: number, options?: AxiosRequestConfig) {
        return MeetingControllerApiFp(this.configuration).getMeeting(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} meetingId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MeetingControllerApi
     */
    public sendInvitations(meetingId: number, options?: AxiosRequestConfig) {
        return MeetingControllerApiFp(this.configuration).sendInvitations(meetingId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {UpdateMeetingDto} updateMeetingDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MeetingControllerApi
     */
    public updateMeeting(id: number, updateMeetingDto: UpdateMeetingDto, options?: AxiosRequestConfig) {
        return MeetingControllerApiFp(this.configuration).updateMeeting(id, updateMeetingDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * TaskControllerApi - axios parameter creator
 * @export
 */
export const TaskControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateTaskDto} createTaskDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTask: async (createTaskDto: CreateTaskDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createTaskDto' is not null or undefined
            assertParamExists('createTask', 'createTaskDto', createTaskDto)
            const localVarPath = `/api/v1/tasks`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createTaskDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTask: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteTask', 'id', id)
            const localVarPath = `/api/v1/tasks/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAssignableUsers: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/tasks/assignable-users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} ecId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAssignableUsersByEC: async (ecId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'ecId' is not null or undefined
            assertParamExists('getAssignableUsersByEC', 'ecId', ecId)
            const localVarPath = `/api/v1/tasks/assignable-users/{ecId}`
                .replace(`{${"ecId"}}`, encodeURIComponent(String(ecId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCTRECSTasksToChange: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getCTRECSTasksToChange', 'id', id)
            const localVarPath = `/api/v1/tasks/{id}/successors/to-change-due-date`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPrecedingEvaluations: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getPrecedingEvaluations', 'id', id)
            const localVarPath = `/api/v1/tasks/{id}/predecessors/evaluations`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPredecessorsOfTask: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getPredecessorsOfTask', 'id', id)
            const localVarPath = `/api/v1/tasks/{id}/predecessors`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<number>} tasks 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPredecessorsOfTasks: async (tasks: Array<number>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tasks' is not null or undefined
            assertParamExists('getPredecessorsOfTasks', 'tasks', tasks)
            const localVarPath = `/api/v1/tasks/predecessors`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (tasks) {
                localVarQueryParameter['tasks'] = tasks;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSuccessorsOfTask: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getSuccessorsOfTask', 'id', id)
            const localVarPath = `/api/v1/tasks/{id}/successors`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTask: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getTask', 'id', id)
            const localVarPath = `/api/v1/tasks/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTaskActivities: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getTaskActivities', 'id', id)
            const localVarPath = `/api/v1/tasks/{id}/activities`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTaskEvaluation: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getTaskEvaluation', 'id', id)
            const localVarPath = `/api/v1/tasks/{id}/evaluation`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {Array<string>} [searchTerms] 
         * @param {string} [sortBy] 
         * @param {boolean} [sortDesc] 
         * @param {boolean} [done] 
         * @param {Array<number>} [ethicsCommissions] 
         * @param {Array<string>} [assignedUsers] 
         * @param {string} [minDateTime] 
         * @param {string} [maxDateTime] 
         * @param {boolean} [includeMscCaSpTasks] 
         * @param {string} [applicationId] 
         * @param {string} [autId] 
         * @param {string} [trialId] 
         * @param {Array<string>} [ctisStatus] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTasks: async (page?: number, size?: number, searchTerms?: Array<string>, sortBy?: string, sortDesc?: boolean, done?: boolean, ethicsCommissions?: Array<number>, assignedUsers?: Array<string>, minDateTime?: string, maxDateTime?: string, includeMscCaSpTasks?: boolean, applicationId?: string, autId?: string, trialId?: string, ctisStatus?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/tasks`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (searchTerms) {
                localVarQueryParameter['searchTerms'] = searchTerms;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (sortDesc !== undefined) {
                localVarQueryParameter['sortDesc'] = sortDesc;
            }

            if (done !== undefined) {
                localVarQueryParameter['done'] = done;
            }

            if (ethicsCommissions) {
                localVarQueryParameter['ethicsCommissions'] = ethicsCommissions;
            }

            if (assignedUsers) {
                localVarQueryParameter['assignedUsers'] = assignedUsers;
            }

            if (minDateTime !== undefined) {
                localVarQueryParameter['minDateTime'] = (minDateTime as any instanceof Date) ?
                    (minDateTime as any).toISOString() :
                    minDateTime;
            }

            if (maxDateTime !== undefined) {
                localVarQueryParameter['maxDateTime'] = (maxDateTime as any instanceof Date) ?
                    (maxDateTime as any).toISOString() :
                    maxDateTime;
            }

            if (includeMscCaSpTasks !== undefined) {
                localVarQueryParameter['includeMscCaSpTasks'] = includeMscCaSpTasks;
            }

            if (applicationId !== undefined) {
                localVarQueryParameter['applicationId'] = applicationId;
            }

            if (autId !== undefined) {
                localVarQueryParameter['autId'] = autId;
            }

            if (trialId !== undefined) {
                localVarQueryParameter['trialId'] = trialId;
            }

            if (ctisStatus) {
                localVarQueryParameter['ctisStatus'] = ctisStatus;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} applicationId 
         * @param {boolean} [includeObsolete] 
         * @param {boolean} [includeProjected] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTasksForApplication: async (applicationId: string, includeObsolete?: boolean, includeProjected?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'applicationId' is not null or undefined
            assertParamExists('getTasksForApplication', 'applicationId', applicationId)
            const localVarPath = `/api/v1/tasks/applications/{applicationId}/tasks`
                .replace(`{${"applicationId"}}`, encodeURIComponent(String(applicationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (includeObsolete !== undefined) {
                localVarQueryParameter['includeObsolete'] = includeObsolete;
            }

            if (includeProjected !== undefined) {
                localVarQueryParameter['includeProjected'] = includeProjected;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendReminderToAssignedUsers: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('sendReminderToAssignedUsers', 'id', id)
            const localVarPath = `/api/v1/tasks/{id}/sendreminder`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {UpdateTaskDoneRequestDto} updateTaskDoneRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDone: async (id: number, updateTaskDoneRequestDto: UpdateTaskDoneRequestDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateDone', 'id', id)
            // verify required parameter 'updateTaskDoneRequestDto' is not null or undefined
            assertParamExists('updateDone', 'updateTaskDoneRequestDto', updateTaskDoneRequestDto)
            const localVarPath = `/api/v1/tasks/{id}/done`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateTaskDoneRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {Array<number>} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePredecessorsOfTask: async (id: number, requestBody: Array<number>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updatePredecessorsOfTask', 'id', id)
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('updatePredecessorsOfTask', 'requestBody', requestBody)
            const localVarPath = `/api/v1/tasks/{id}/predecessors`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {Array<number>} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSuccessorsOfTask: async (id: number, requestBody: Array<number>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateSuccessorsOfTask', 'id', id)
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('updateSuccessorsOfTask', 'requestBody', requestBody)
            const localVarPath = `/api/v1/tasks/{id}/successors`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {UpdateTaskDto} updateTaskDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTask: async (id: number, updateTaskDto: UpdateTaskDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateTask', 'id', id)
            // verify required parameter 'updateTaskDto' is not null or undefined
            assertParamExists('updateTask', 'updateTaskDto', updateTaskDto)
            const localVarPath = `/api/v1/tasks/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateTaskDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {UpdateTaskEvaluationDto} updateTaskEvaluationDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTaskEvaluation: async (id: number, updateTaskEvaluationDto: UpdateTaskEvaluationDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateTaskEvaluation', 'id', id)
            // verify required parameter 'updateTaskEvaluationDto' is not null or undefined
            assertParamExists('updateTaskEvaluation', 'updateTaskEvaluationDto', updateTaskEvaluationDto)
            const localVarPath = `/api/v1/tasks/{id}/evaluation`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateTaskEvaluationDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TaskControllerApi - functional programming interface
 * @export
 */
export const TaskControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TaskControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CreateTaskDto} createTaskDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createTask(createTaskDto: CreateTaskDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TaskDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createTask(createTaskDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteTask(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteTask(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAssignableUsers(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TaskUserDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAssignableUsers(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} ecId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAssignableUsersByEC(ecId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TaskUserDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAssignableUsersByEC(ecId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCTRECSTasksToChange(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TaskDependencyDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCTRECSTasksToChange(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPrecedingEvaluations(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TaskDependencyEvaluationDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPrecedingEvaluations(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPredecessorsOfTask(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TaskDependencyDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPredecessorsOfTask(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {Array<number>} tasks 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPredecessorsOfTasks(tasks: Array<number>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TaskDependencyDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPredecessorsOfTasks(tasks, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSuccessorsOfTask(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TaskDependencyDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSuccessorsOfTask(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTask(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TaskDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTask(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTaskActivities(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TaskActivityDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTaskActivities(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTaskEvaluation(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TaskEvaluationDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTaskEvaluation(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {Array<string>} [searchTerms] 
         * @param {string} [sortBy] 
         * @param {boolean} [sortDesc] 
         * @param {boolean} [done] 
         * @param {Array<number>} [ethicsCommissions] 
         * @param {Array<string>} [assignedUsers] 
         * @param {string} [minDateTime] 
         * @param {string} [maxDateTime] 
         * @param {boolean} [includeMscCaSpTasks] 
         * @param {string} [applicationId] 
         * @param {string} [autId] 
         * @param {string} [trialId] 
         * @param {Array<string>} [ctisStatus] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTasks(page?: number, size?: number, searchTerms?: Array<string>, sortBy?: string, sortDesc?: boolean, done?: boolean, ethicsCommissions?: Array<number>, assignedUsers?: Array<string>, minDateTime?: string, maxDateTime?: string, includeMscCaSpTasks?: boolean, applicationId?: string, autId?: string, trialId?: string, ctisStatus?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageResponseDtoTaskDependencyValidationDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTasks(page, size, searchTerms, sortBy, sortDesc, done, ethicsCommissions, assignedUsers, minDateTime, maxDateTime, includeMscCaSpTasks, applicationId, autId, trialId, ctisStatus, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} applicationId 
         * @param {boolean} [includeObsolete] 
         * @param {boolean} [includeProjected] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTasksForApplication(applicationId: string, includeObsolete?: boolean, includeProjected?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TaskDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTasksForApplication(applicationId, includeObsolete, includeProjected, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sendReminderToAssignedUsers(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sendReminderToAssignedUsers(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {UpdateTaskDoneRequestDto} updateTaskDoneRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateDone(id: number, updateTaskDoneRequestDto: UpdateTaskDoneRequestDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TaskDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateDone(id, updateTaskDoneRequestDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {Array<number>} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updatePredecessorsOfTask(id: number, requestBody: Array<number>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TaskDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updatePredecessorsOfTask(id, requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {Array<number>} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateSuccessorsOfTask(id: number, requestBody: Array<number>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TaskDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateSuccessorsOfTask(id, requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {UpdateTaskDto} updateTaskDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateTask(id: number, updateTaskDto: UpdateTaskDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TaskDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateTask(id, updateTaskDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {UpdateTaskEvaluationDto} updateTaskEvaluationDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateTaskEvaluation(id: number, updateTaskEvaluationDto: UpdateTaskEvaluationDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TaskEvaluationDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateTaskEvaluation(id, updateTaskEvaluationDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * TaskControllerApi - factory interface
 * @export
 */
export const TaskControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TaskControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {CreateTaskDto} createTaskDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTask(createTaskDto: CreateTaskDto, options?: any): AxiosPromise<TaskDto> {
            return localVarFp.createTask(createTaskDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTask(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteTask(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAssignableUsers(options?: any): AxiosPromise<Array<TaskUserDto>> {
            return localVarFp.getAssignableUsers(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} ecId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAssignableUsersByEC(ecId: number, options?: any): AxiosPromise<Array<TaskUserDto>> {
            return localVarFp.getAssignableUsersByEC(ecId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCTRECSTasksToChange(id: number, options?: any): AxiosPromise<Array<TaskDependencyDto>> {
            return localVarFp.getCTRECSTasksToChange(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPrecedingEvaluations(id: number, options?: any): AxiosPromise<Array<TaskDependencyEvaluationDto>> {
            return localVarFp.getPrecedingEvaluations(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPredecessorsOfTask(id: number, options?: any): AxiosPromise<Array<TaskDependencyDto>> {
            return localVarFp.getPredecessorsOfTask(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Array<number>} tasks 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPredecessorsOfTasks(tasks: Array<number>, options?: any): AxiosPromise<Array<TaskDependencyDto>> {
            return localVarFp.getPredecessorsOfTasks(tasks, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSuccessorsOfTask(id: number, options?: any): AxiosPromise<Array<TaskDependencyDto>> {
            return localVarFp.getSuccessorsOfTask(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTask(id: number, options?: any): AxiosPromise<TaskDto> {
            return localVarFp.getTask(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTaskActivities(id: number, options?: any): AxiosPromise<Array<TaskActivityDto>> {
            return localVarFp.getTaskActivities(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTaskEvaluation(id: number, options?: any): AxiosPromise<TaskEvaluationDto> {
            return localVarFp.getTaskEvaluation(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {Array<string>} [searchTerms] 
         * @param {string} [sortBy] 
         * @param {boolean} [sortDesc] 
         * @param {boolean} [done] 
         * @param {Array<number>} [ethicsCommissions] 
         * @param {Array<string>} [assignedUsers] 
         * @param {string} [minDateTime] 
         * @param {string} [maxDateTime] 
         * @param {boolean} [includeMscCaSpTasks] 
         * @param {string} [applicationId] 
         * @param {string} [autId] 
         * @param {string} [trialId] 
         * @param {Array<string>} [ctisStatus] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTasks(page?: number, size?: number, searchTerms?: Array<string>, sortBy?: string, sortDesc?: boolean, done?: boolean, ethicsCommissions?: Array<number>, assignedUsers?: Array<string>, minDateTime?: string, maxDateTime?: string, includeMscCaSpTasks?: boolean, applicationId?: string, autId?: string, trialId?: string, ctisStatus?: Array<string>, options?: any): AxiosPromise<PageResponseDtoTaskDependencyValidationDto> {
            return localVarFp.getTasks(page, size, searchTerms, sortBy, sortDesc, done, ethicsCommissions, assignedUsers, minDateTime, maxDateTime, includeMscCaSpTasks, applicationId, autId, trialId, ctisStatus, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} applicationId 
         * @param {boolean} [includeObsolete] 
         * @param {boolean} [includeProjected] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTasksForApplication(applicationId: string, includeObsolete?: boolean, includeProjected?: boolean, options?: any): AxiosPromise<Array<TaskDto>> {
            return localVarFp.getTasksForApplication(applicationId, includeObsolete, includeProjected, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendReminderToAssignedUsers(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.sendReminderToAssignedUsers(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {UpdateTaskDoneRequestDto} updateTaskDoneRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDone(id: number, updateTaskDoneRequestDto: UpdateTaskDoneRequestDto, options?: any): AxiosPromise<TaskDto> {
            return localVarFp.updateDone(id, updateTaskDoneRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {Array<number>} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePredecessorsOfTask(id: number, requestBody: Array<number>, options?: any): AxiosPromise<Array<TaskDto>> {
            return localVarFp.updatePredecessorsOfTask(id, requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {Array<number>} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSuccessorsOfTask(id: number, requestBody: Array<number>, options?: any): AxiosPromise<Array<TaskDto>> {
            return localVarFp.updateSuccessorsOfTask(id, requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {UpdateTaskDto} updateTaskDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTask(id: number, updateTaskDto: UpdateTaskDto, options?: any): AxiosPromise<TaskDto> {
            return localVarFp.updateTask(id, updateTaskDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {UpdateTaskEvaluationDto} updateTaskEvaluationDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTaskEvaluation(id: number, updateTaskEvaluationDto: UpdateTaskEvaluationDto, options?: any): AxiosPromise<TaskEvaluationDto> {
            return localVarFp.updateTaskEvaluation(id, updateTaskEvaluationDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TaskControllerApi - object-oriented interface
 * @export
 * @class TaskControllerApi
 * @extends {BaseAPI}
 */
export class TaskControllerApi extends BaseAPI {
    /**
     * 
     * @param {CreateTaskDto} createTaskDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TaskControllerApi
     */
    public createTask(createTaskDto: CreateTaskDto, options?: AxiosRequestConfig) {
        return TaskControllerApiFp(this.configuration).createTask(createTaskDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TaskControllerApi
     */
    public deleteTask(id: number, options?: AxiosRequestConfig) {
        return TaskControllerApiFp(this.configuration).deleteTask(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TaskControllerApi
     */
    public getAssignableUsers(options?: AxiosRequestConfig) {
        return TaskControllerApiFp(this.configuration).getAssignableUsers(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} ecId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TaskControllerApi
     */
    public getAssignableUsersByEC(ecId: number, options?: AxiosRequestConfig) {
        return TaskControllerApiFp(this.configuration).getAssignableUsersByEC(ecId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TaskControllerApi
     */
    public getCTRECSTasksToChange(id: number, options?: AxiosRequestConfig) {
        return TaskControllerApiFp(this.configuration).getCTRECSTasksToChange(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TaskControllerApi
     */
    public getPrecedingEvaluations(id: number, options?: AxiosRequestConfig) {
        return TaskControllerApiFp(this.configuration).getPrecedingEvaluations(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TaskControllerApi
     */
    public getPredecessorsOfTask(id: number, options?: AxiosRequestConfig) {
        return TaskControllerApiFp(this.configuration).getPredecessorsOfTask(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Array<number>} tasks 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TaskControllerApi
     */
    public getPredecessorsOfTasks(tasks: Array<number>, options?: AxiosRequestConfig) {
        return TaskControllerApiFp(this.configuration).getPredecessorsOfTasks(tasks, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TaskControllerApi
     */
    public getSuccessorsOfTask(id: number, options?: AxiosRequestConfig) {
        return TaskControllerApiFp(this.configuration).getSuccessorsOfTask(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TaskControllerApi
     */
    public getTask(id: number, options?: AxiosRequestConfig) {
        return TaskControllerApiFp(this.configuration).getTask(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TaskControllerApi
     */
    public getTaskActivities(id: number, options?: AxiosRequestConfig) {
        return TaskControllerApiFp(this.configuration).getTaskActivities(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TaskControllerApi
     */
    public getTaskEvaluation(id: number, options?: AxiosRequestConfig) {
        return TaskControllerApiFp(this.configuration).getTaskEvaluation(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [page] 
     * @param {number} [size] 
     * @param {Array<string>} [searchTerms] 
     * @param {string} [sortBy] 
     * @param {boolean} [sortDesc] 
     * @param {boolean} [done] 
     * @param {Array<number>} [ethicsCommissions] 
     * @param {Array<string>} [assignedUsers] 
     * @param {string} [minDateTime] 
     * @param {string} [maxDateTime] 
     * @param {boolean} [includeMscCaSpTasks] 
     * @param {string} [applicationId] 
     * @param {string} [autId] 
     * @param {string} [trialId] 
     * @param {Array<string>} [ctisStatus] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TaskControllerApi
     */
    public getTasks(page?: number, size?: number, searchTerms?: Array<string>, sortBy?: string, sortDesc?: boolean, done?: boolean, ethicsCommissions?: Array<number>, assignedUsers?: Array<string>, minDateTime?: string, maxDateTime?: string, includeMscCaSpTasks?: boolean, applicationId?: string, autId?: string, trialId?: string, ctisStatus?: Array<string>, options?: AxiosRequestConfig) {
        return TaskControllerApiFp(this.configuration).getTasks(page, size, searchTerms, sortBy, sortDesc, done, ethicsCommissions, assignedUsers, minDateTime, maxDateTime, includeMscCaSpTasks, applicationId, autId, trialId, ctisStatus, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} applicationId 
     * @param {boolean} [includeObsolete] 
     * @param {boolean} [includeProjected] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TaskControllerApi
     */
    public getTasksForApplication(applicationId: string, includeObsolete?: boolean, includeProjected?: boolean, options?: AxiosRequestConfig) {
        return TaskControllerApiFp(this.configuration).getTasksForApplication(applicationId, includeObsolete, includeProjected, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TaskControllerApi
     */
    public sendReminderToAssignedUsers(id: number, options?: AxiosRequestConfig) {
        return TaskControllerApiFp(this.configuration).sendReminderToAssignedUsers(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {UpdateTaskDoneRequestDto} updateTaskDoneRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TaskControllerApi
     */
    public updateDone(id: number, updateTaskDoneRequestDto: UpdateTaskDoneRequestDto, options?: AxiosRequestConfig) {
        return TaskControllerApiFp(this.configuration).updateDone(id, updateTaskDoneRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {Array<number>} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TaskControllerApi
     */
    public updatePredecessorsOfTask(id: number, requestBody: Array<number>, options?: AxiosRequestConfig) {
        return TaskControllerApiFp(this.configuration).updatePredecessorsOfTask(id, requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {Array<number>} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TaskControllerApi
     */
    public updateSuccessorsOfTask(id: number, requestBody: Array<number>, options?: AxiosRequestConfig) {
        return TaskControllerApiFp(this.configuration).updateSuccessorsOfTask(id, requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {UpdateTaskDto} updateTaskDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TaskControllerApi
     */
    public updateTask(id: number, updateTaskDto: UpdateTaskDto, options?: AxiosRequestConfig) {
        return TaskControllerApiFp(this.configuration).updateTask(id, updateTaskDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {UpdateTaskEvaluationDto} updateTaskEvaluationDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TaskControllerApi
     */
    public updateTaskEvaluation(id: number, updateTaskEvaluationDto: UpdateTaskEvaluationDto, options?: AxiosRequestConfig) {
        return TaskControllerApiFp(this.configuration).updateTaskEvaluation(id, updateTaskEvaluationDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * TrialControllerApi - axios parameter creator
 * @export
 */
export const TrialControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} id 
         * @param {ConfirmBiasRequestDto} confirmBiasRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        confirmBiased: async (id: string, confirmBiasRequestDto: ConfirmBiasRequestDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('confirmBiased', 'id', id)
            // verify required parameter 'confirmBiasRequestDto' is not null or undefined
            assertParamExists('confirmBiased', 'confirmBiasRequestDto', confirmBiasRequestDto)
            const localVarPath = `/api/v1/trials/{id}/biased`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(confirmBiasRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        confirmNotBiased: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('confirmNotBiased', 'id', id)
            const localVarPath = `/api/v1/trials/{id}/not-biased`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} applicationId 
         * @param {string} documentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDocument: async (applicationId: string, documentId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'applicationId' is not null or undefined
            assertParamExists('deleteDocument', 'applicationId', applicationId)
            // verify required parameter 'documentId' is not null or undefined
            assertParamExists('deleteDocument', 'documentId', documentId)
            const localVarPath = `/api/v1/trials/application/{applicationId}/documents/{documentId}`
                .replace(`{${"applicationId"}}`, encodeURIComponent(String(applicationId)))
                .replace(`{${"documentId"}}`, encodeURIComponent(String(documentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [search] 
         * @param {Array<string>} [memberStateRoles] 
         * @param {Array<string>} [trialStatus] 
         * @param {string} [nationality] 
         * @param {Array<number>} [ethicsCommissions] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllTrials: async (page?: number, size?: number, search?: string, memberStateRoles?: Array<string>, trialStatus?: Array<string>, nationality?: string, ethicsCommissions?: Array<number>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/trials`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (memberStateRoles) {
                localVarQueryParameter['memberStateRoles'] = memberStateRoles;
            }

            if (trialStatus) {
                localVarQueryParameter['trialStatus'] = trialStatus;
            }

            if (nationality !== undefined) {
                localVarQueryParameter['nationality'] = nationality;
            }

            if (ethicsCommissions) {
                localVarQueryParameter['ethicsCommissions'] = ethicsCommissions;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} applicationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApplicationAssigneeTypes: async (applicationId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'applicationId' is not null or undefined
            assertParamExists('getApplicationAssigneeTypes', 'applicationId', applicationId)
            const localVarPath = `/api/v1/trials/application/{applicationId}/assignee-types`
                .replace(`{${"applicationId"}}`, encodeURIComponent(String(applicationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} applicationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApplicationAssignees: async (applicationId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'applicationId' is not null or undefined
            assertParamExists('getApplicationAssignees', 'applicationId', applicationId)
            const localVarPath = `/api/v1/trials/application/{applicationId}/assignees`
                .replace(`{${"applicationId"}}`, encodeURIComponent(String(applicationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} applicationId 
         * @param {string} [key] 
         * @param {Array<string>} [preferredDocumentLanguages] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApplicationDetail: async (applicationId: string, key?: string, preferredDocumentLanguages?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'applicationId' is not null or undefined
            assertParamExists('getApplicationDetail', 'applicationId', applicationId)
            const localVarPath = `/api/v1/trials/application/{applicationId}/detail`
                .replace(`{${"applicationId"}}`, encodeURIComponent(String(applicationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (key !== undefined) {
                localVarQueryParameter['key'] = key;
            }

            if (preferredDocumentLanguages) {
                localVarQueryParameter['preferredDocumentLanguages'] = preferredDocumentLanguages;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} applicationId 
         * @param {Array<string>} [preferredLanguages] 
         * @param {number} [preferredPart] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApplicationDocuments: async (applicationId: string, preferredLanguages?: Array<string>, preferredPart?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'applicationId' is not null or undefined
            assertParamExists('getApplicationDocuments', 'applicationId', applicationId)
            const localVarPath = `/api/v1/trials/application/{applicationId}/documents`
                .replace(`{${"applicationId"}}`, encodeURIComponent(String(applicationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (preferredLanguages) {
                localVarQueryParameter['preferredLanguages'] = preferredLanguages;
            }

            if (preferredPart !== undefined) {
                localVarQueryParameter['preferredPart'] = preferredPart;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} applicationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApplicationMeta: async (applicationId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'applicationId' is not null or undefined
            assertParamExists('getApplicationMeta', 'applicationId', applicationId)
            const localVarPath = `/api/v1/trials/application/{applicationId}/meta`
                .replace(`{${"applicationId"}}`, encodeURIComponent(String(applicationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} applicationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApplicationMscs: async (applicationId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'applicationId' is not null or undefined
            assertParamExists('getApplicationMscs', 'applicationId', applicationId)
            const localVarPath = `/api/v1/trials/applications/{applicationId}/mscs`
                .replace(`{${"applicationId"}}`, encodeURIComponent(String(applicationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} applicationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApplicationSections: async (applicationId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'applicationId' is not null or undefined
            assertParamExists('getApplicationSections', 'applicationId', applicationId)
            const localVarPath = `/api/v1/trials/application/{applicationId}/sections`
                .replace(`{${"applicationId"}}`, encodeURIComponent(String(applicationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApplications: async (search?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/trials/applications`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} documentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDocument: async (documentId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'documentId' is not null or undefined
            assertParamExists('getDocument', 'documentId', documentId)
            const localVarPath = `/api/v1/trials/documents/{documentId}`
                .replace(`{${"documentId"}}`, encodeURIComponent(String(documentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTrial: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getTrial', 'id', id)
            const localVarPath = `/api/v1/trials/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} trialId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTrialApplications: async (trialId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'trialId' is not null or undefined
            assertParamExists('getTrialApplications', 'trialId', trialId)
            const localVarPath = `/api/v1/trials/{trialId}/applications`
                .replace(`{${"trialId"}}`, encodeURIComponent(String(trialId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} trialId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTrialSettings: async (trialId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'trialId' is not null or undefined
            assertParamExists('getTrialSettings', 'trialId', trialId)
            const localVarPath = `/api/v1/trials/{trialId}/settings`
                .replace(`{${"trialId"}}`, encodeURIComponent(String(trialId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTrialSummary: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getTrialSummary', 'id', id)
            const localVarPath = `/api/v1/trials/{id}/summary`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        needsBiasConfirmation: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('needsBiasConfirmation', 'id', id)
            const localVarPath = `/api/v1/trials/{id}/needs-bias-confirmation`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} trialId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        revokeTransitionalState: async (trialId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'trialId' is not null or undefined
            assertParamExists('revokeTransitionalState', 'trialId', trialId)
            const localVarPath = `/api/v1/trials/{trialId}/revoke-transition`
                .replace(`{${"trialId"}}`, encodeURIComponent(String(trialId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} trialId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        synchronizeTrial: async (trialId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'trialId' is not null or undefined
            assertParamExists('synchronizeTrial', 'trialId', trialId)
            const localVarPath = `/api/v1/trials/{trialId}/synchronize`
                .replace(`{${"trialId"}}`, encodeURIComponent(String(trialId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} applicationId 
         * @param {Array<UpdateApplicationAssigneeDto>} updateApplicationAssigneeDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateApplicationAssignees: async (applicationId: string, updateApplicationAssigneeDto: Array<UpdateApplicationAssigneeDto>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'applicationId' is not null or undefined
            assertParamExists('updateApplicationAssignees', 'applicationId', applicationId)
            // verify required parameter 'updateApplicationAssigneeDto' is not null or undefined
            assertParamExists('updateApplicationAssignees', 'updateApplicationAssigneeDto', updateApplicationAssigneeDto)
            const localVarPath = `/api/v1/trials/application/{applicationId}/assignees`
                .replace(`{${"applicationId"}}`, encodeURIComponent(String(applicationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateApplicationAssigneeDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} trialId 
         * @param {TrialSettingsRequestDto} trialSettingsRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTrialSettings: async (trialId: string, trialSettingsRequestDto: TrialSettingsRequestDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'trialId' is not null or undefined
            assertParamExists('updateTrialSettings', 'trialId', trialId)
            // verify required parameter 'trialSettingsRequestDto' is not null or undefined
            assertParamExists('updateTrialSettings', 'trialSettingsRequestDto', trialSettingsRequestDto)
            const localVarPath = `/api/v1/trials/{trialId}/settings`
                .replace(`{${"trialId"}}`, encodeURIComponent(String(trialId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(trialSettingsRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} applicationId 
         * @param {Array<any>} files 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadApplicationDocuments: async (applicationId: string, files: Array<any>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'applicationId' is not null or undefined
            assertParamExists('uploadApplicationDocuments', 'applicationId', applicationId)
            // verify required parameter 'files' is not null or undefined
            assertParamExists('uploadApplicationDocuments', 'files', files)
            const localVarPath = `/api/v1/trials/application/{applicationId}/documents/upload`
                .replace(`{${"applicationId"}}`, encodeURIComponent(String(applicationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            if (files) {
                files.forEach((element) => {
                    localVarFormParams.append('files', element as any);
                })
            }

    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TrialControllerApi - functional programming interface
 * @export
 */
export const TrialControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TrialControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} id 
         * @param {ConfirmBiasRequestDto} confirmBiasRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async confirmBiased(id: string, confirmBiasRequestDto: ConfirmBiasRequestDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.confirmBiased(id, confirmBiasRequestDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async confirmNotBiased(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.confirmNotBiased(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} applicationId 
         * @param {string} documentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteDocument(applicationId: string, documentId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteDocument(applicationId, documentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [search] 
         * @param {Array<string>} [memberStateRoles] 
         * @param {Array<string>} [trialStatus] 
         * @param {string} [nationality] 
         * @param {Array<number>} [ethicsCommissions] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllTrials(page?: number, size?: number, search?: string, memberStateRoles?: Array<string>, trialStatus?: Array<string>, nationality?: string, ethicsCommissions?: Array<number>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageResponseDtoTrialDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllTrials(page, size, search, memberStateRoles, trialStatus, nationality, ethicsCommissions, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} applicationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getApplicationAssigneeTypes(applicationId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AssigneeTypeDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getApplicationAssigneeTypes(applicationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} applicationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getApplicationAssignees(applicationId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ApplicationAssigneeDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getApplicationAssignees(applicationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} applicationId 
         * @param {string} [key] 
         * @param {Array<string>} [preferredDocumentLanguages] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getApplicationDetail(applicationId: string, key?: string, preferredDocumentLanguages?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Node>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getApplicationDetail(applicationId, key, preferredDocumentLanguages, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} applicationId 
         * @param {Array<string>} [preferredLanguages] 
         * @param {number} [preferredPart] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getApplicationDocuments(applicationId: string, preferredLanguages?: Array<string>, preferredPart?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DocumentDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getApplicationDocuments(applicationId, preferredLanguages, preferredPart, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} applicationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getApplicationMeta(applicationId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplicationMetaDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getApplicationMeta(applicationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} applicationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getApplicationMscs(applicationId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<MemberStateDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getApplicationMscs(applicationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} applicationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getApplicationSections(applicationId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ApplicationSectionItemDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getApplicationSections(applicationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getApplications(search?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ApplicationSearchDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getApplications(search, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} documentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDocument(documentId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDocument(documentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTrial(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TrialDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTrial(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} trialId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTrialApplications(trialId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ApplicationNameDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTrialApplications(trialId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} trialId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTrialSettings(trialId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TrialSettingsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTrialSettings(trialId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTrialSummary(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TrialSummaryDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTrialSummary(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async needsBiasConfirmation(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NeedsBiasConfirmationResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.needsBiasConfirmation(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} trialId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async revokeTransitionalState(trialId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.revokeTransitionalState(trialId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} trialId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async synchronizeTrial(trialId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TrialDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.synchronizeTrial(trialId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} applicationId 
         * @param {Array<UpdateApplicationAssigneeDto>} updateApplicationAssigneeDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateApplicationAssignees(applicationId: string, updateApplicationAssigneeDto: Array<UpdateApplicationAssigneeDto>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ApplicationAssigneeDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateApplicationAssignees(applicationId, updateApplicationAssigneeDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} trialId 
         * @param {TrialSettingsRequestDto} trialSettingsRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateTrialSettings(trialId: string, trialSettingsRequestDto: TrialSettingsRequestDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TrialSettingsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateTrialSettings(trialId, trialSettingsRequestDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} applicationId 
         * @param {Array<any>} files 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadApplicationDocuments(applicationId: string, files: Array<any>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DocumentDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uploadApplicationDocuments(applicationId, files, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * TrialControllerApi - factory interface
 * @export
 */
export const TrialControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TrialControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {string} id 
         * @param {ConfirmBiasRequestDto} confirmBiasRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        confirmBiased(id: string, confirmBiasRequestDto: ConfirmBiasRequestDto, options?: any): AxiosPromise<void> {
            return localVarFp.confirmBiased(id, confirmBiasRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        confirmNotBiased(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.confirmNotBiased(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} applicationId 
         * @param {string} documentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDocument(applicationId: string, documentId: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteDocument(applicationId, documentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [search] 
         * @param {Array<string>} [memberStateRoles] 
         * @param {Array<string>} [trialStatus] 
         * @param {string} [nationality] 
         * @param {Array<number>} [ethicsCommissions] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllTrials(page?: number, size?: number, search?: string, memberStateRoles?: Array<string>, trialStatus?: Array<string>, nationality?: string, ethicsCommissions?: Array<number>, options?: any): AxiosPromise<PageResponseDtoTrialDto> {
            return localVarFp.getAllTrials(page, size, search, memberStateRoles, trialStatus, nationality, ethicsCommissions, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} applicationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApplicationAssigneeTypes(applicationId: string, options?: any): AxiosPromise<Array<AssigneeTypeDto>> {
            return localVarFp.getApplicationAssigneeTypes(applicationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} applicationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApplicationAssignees(applicationId: string, options?: any): AxiosPromise<Array<ApplicationAssigneeDto>> {
            return localVarFp.getApplicationAssignees(applicationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} applicationId 
         * @param {string} [key] 
         * @param {Array<string>} [preferredDocumentLanguages] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApplicationDetail(applicationId: string, key?: string, preferredDocumentLanguages?: Array<string>, options?: any): AxiosPromise<Array<Node>> {
            return localVarFp.getApplicationDetail(applicationId, key, preferredDocumentLanguages, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} applicationId 
         * @param {Array<string>} [preferredLanguages] 
         * @param {number} [preferredPart] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApplicationDocuments(applicationId: string, preferredLanguages?: Array<string>, preferredPart?: number, options?: any): AxiosPromise<Array<DocumentDto>> {
            return localVarFp.getApplicationDocuments(applicationId, preferredLanguages, preferredPart, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} applicationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApplicationMeta(applicationId: string, options?: any): AxiosPromise<ApplicationMetaDto> {
            return localVarFp.getApplicationMeta(applicationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} applicationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApplicationMscs(applicationId: string, options?: any): AxiosPromise<Array<MemberStateDto>> {
            return localVarFp.getApplicationMscs(applicationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} applicationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApplicationSections(applicationId: string, options?: any): AxiosPromise<Array<ApplicationSectionItemDto>> {
            return localVarFp.getApplicationSections(applicationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApplications(search?: string, options?: any): AxiosPromise<Array<ApplicationSearchDto>> {
            return localVarFp.getApplications(search, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} documentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDocument(documentId: string, options?: any): AxiosPromise<string> {
            return localVarFp.getDocument(documentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTrial(id: string, options?: any): AxiosPromise<TrialDto> {
            return localVarFp.getTrial(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} trialId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTrialApplications(trialId: string, options?: any): AxiosPromise<Array<ApplicationNameDto>> {
            return localVarFp.getTrialApplications(trialId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} trialId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTrialSettings(trialId: string, options?: any): AxiosPromise<TrialSettingsDto> {
            return localVarFp.getTrialSettings(trialId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTrialSummary(id: string, options?: any): AxiosPromise<TrialSummaryDto> {
            return localVarFp.getTrialSummary(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        needsBiasConfirmation(id: string, options?: any): AxiosPromise<NeedsBiasConfirmationResponseDto> {
            return localVarFp.needsBiasConfirmation(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} trialId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        revokeTransitionalState(trialId: string, options?: any): AxiosPromise<void> {
            return localVarFp.revokeTransitionalState(trialId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} trialId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        synchronizeTrial(trialId: string, options?: any): AxiosPromise<TrialDto> {
            return localVarFp.synchronizeTrial(trialId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} applicationId 
         * @param {Array<UpdateApplicationAssigneeDto>} updateApplicationAssigneeDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateApplicationAssignees(applicationId: string, updateApplicationAssigneeDto: Array<UpdateApplicationAssigneeDto>, options?: any): AxiosPromise<Array<ApplicationAssigneeDto>> {
            return localVarFp.updateApplicationAssignees(applicationId, updateApplicationAssigneeDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} trialId 
         * @param {TrialSettingsRequestDto} trialSettingsRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTrialSettings(trialId: string, trialSettingsRequestDto: TrialSettingsRequestDto, options?: any): AxiosPromise<TrialSettingsDto> {
            return localVarFp.updateTrialSettings(trialId, trialSettingsRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} applicationId 
         * @param {Array<any>} files 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadApplicationDocuments(applicationId: string, files: Array<any>, options?: any): AxiosPromise<Array<DocumentDto>> {
            return localVarFp.uploadApplicationDocuments(applicationId, files, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TrialControllerApi - object-oriented interface
 * @export
 * @class TrialControllerApi
 * @extends {BaseAPI}
 */
export class TrialControllerApi extends BaseAPI {
    /**
     * 
     * @param {string} id 
     * @param {ConfirmBiasRequestDto} confirmBiasRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TrialControllerApi
     */
    public confirmBiased(id: string, confirmBiasRequestDto: ConfirmBiasRequestDto, options?: AxiosRequestConfig) {
        return TrialControllerApiFp(this.configuration).confirmBiased(id, confirmBiasRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TrialControllerApi
     */
    public confirmNotBiased(id: string, options?: AxiosRequestConfig) {
        return TrialControllerApiFp(this.configuration).confirmNotBiased(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} applicationId 
     * @param {string} documentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TrialControllerApi
     */
    public deleteDocument(applicationId: string, documentId: string, options?: AxiosRequestConfig) {
        return TrialControllerApiFp(this.configuration).deleteDocument(applicationId, documentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [page] 
     * @param {number} [size] 
     * @param {string} [search] 
     * @param {Array<string>} [memberStateRoles] 
     * @param {Array<string>} [trialStatus] 
     * @param {string} [nationality] 
     * @param {Array<number>} [ethicsCommissions] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TrialControllerApi
     */
    public getAllTrials(page?: number, size?: number, search?: string, memberStateRoles?: Array<string>, trialStatus?: Array<string>, nationality?: string, ethicsCommissions?: Array<number>, options?: AxiosRequestConfig) {
        return TrialControllerApiFp(this.configuration).getAllTrials(page, size, search, memberStateRoles, trialStatus, nationality, ethicsCommissions, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} applicationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TrialControllerApi
     */
    public getApplicationAssigneeTypes(applicationId: string, options?: AxiosRequestConfig) {
        return TrialControllerApiFp(this.configuration).getApplicationAssigneeTypes(applicationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} applicationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TrialControllerApi
     */
    public getApplicationAssignees(applicationId: string, options?: AxiosRequestConfig) {
        return TrialControllerApiFp(this.configuration).getApplicationAssignees(applicationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} applicationId 
     * @param {string} [key] 
     * @param {Array<string>} [preferredDocumentLanguages] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TrialControllerApi
     */
    public getApplicationDetail(applicationId: string, key?: string, preferredDocumentLanguages?: Array<string>, options?: AxiosRequestConfig) {
        return TrialControllerApiFp(this.configuration).getApplicationDetail(applicationId, key, preferredDocumentLanguages, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} applicationId 
     * @param {Array<string>} [preferredLanguages] 
     * @param {number} [preferredPart] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TrialControllerApi
     */
    public getApplicationDocuments(applicationId: string, preferredLanguages?: Array<string>, preferredPart?: number, options?: AxiosRequestConfig) {
        return TrialControllerApiFp(this.configuration).getApplicationDocuments(applicationId, preferredLanguages, preferredPart, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} applicationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TrialControllerApi
     */
    public getApplicationMeta(applicationId: string, options?: AxiosRequestConfig) {
        return TrialControllerApiFp(this.configuration).getApplicationMeta(applicationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} applicationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TrialControllerApi
     */
    public getApplicationMscs(applicationId: string, options?: AxiosRequestConfig) {
        return TrialControllerApiFp(this.configuration).getApplicationMscs(applicationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} applicationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TrialControllerApi
     */
    public getApplicationSections(applicationId: string, options?: AxiosRequestConfig) {
        return TrialControllerApiFp(this.configuration).getApplicationSections(applicationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [search] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TrialControllerApi
     */
    public getApplications(search?: string, options?: AxiosRequestConfig) {
        return TrialControllerApiFp(this.configuration).getApplications(search, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} documentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TrialControllerApi
     */
    public getDocument(documentId: string, options?: AxiosRequestConfig) {
        return TrialControllerApiFp(this.configuration).getDocument(documentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TrialControllerApi
     */
    public getTrial(id: string, options?: AxiosRequestConfig) {
        return TrialControllerApiFp(this.configuration).getTrial(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} trialId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TrialControllerApi
     */
    public getTrialApplications(trialId: string, options?: AxiosRequestConfig) {
        return TrialControllerApiFp(this.configuration).getTrialApplications(trialId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} trialId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TrialControllerApi
     */
    public getTrialSettings(trialId: string, options?: AxiosRequestConfig) {
        return TrialControllerApiFp(this.configuration).getTrialSettings(trialId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TrialControllerApi
     */
    public getTrialSummary(id: string, options?: AxiosRequestConfig) {
        return TrialControllerApiFp(this.configuration).getTrialSummary(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TrialControllerApi
     */
    public needsBiasConfirmation(id: string, options?: AxiosRequestConfig) {
        return TrialControllerApiFp(this.configuration).needsBiasConfirmation(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} trialId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TrialControllerApi
     */
    public revokeTransitionalState(trialId: string, options?: AxiosRequestConfig) {
        return TrialControllerApiFp(this.configuration).revokeTransitionalState(trialId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} trialId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TrialControllerApi
     */
    public synchronizeTrial(trialId: string, options?: AxiosRequestConfig) {
        return TrialControllerApiFp(this.configuration).synchronizeTrial(trialId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} applicationId 
     * @param {Array<UpdateApplicationAssigneeDto>} updateApplicationAssigneeDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TrialControllerApi
     */
    public updateApplicationAssignees(applicationId: string, updateApplicationAssigneeDto: Array<UpdateApplicationAssigneeDto>, options?: AxiosRequestConfig) {
        return TrialControllerApiFp(this.configuration).updateApplicationAssignees(applicationId, updateApplicationAssigneeDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} trialId 
     * @param {TrialSettingsRequestDto} trialSettingsRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TrialControllerApi
     */
    public updateTrialSettings(trialId: string, trialSettingsRequestDto: TrialSettingsRequestDto, options?: AxiosRequestConfig) {
        return TrialControllerApiFp(this.configuration).updateTrialSettings(trialId, trialSettingsRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} applicationId 
     * @param {Array<any>} files 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TrialControllerApi
     */
    public uploadApplicationDocuments(applicationId: string, files: Array<any>, options?: AxiosRequestConfig) {
        return TrialControllerApiFp(this.configuration).uploadApplicationDocuments(applicationId, files, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * UserControllerApi - axios parameter creator
 * @export
 */
export const UserControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getArchivedNotifications: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/users/me/notifications/archived`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCurrentUser: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/users/me`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCurrentUserDetails: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/users/me/details`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUnreadNotifications: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/users/me/notifications`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} notificationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        markNotificationRead: async (notificationId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'notificationId' is not null or undefined
            assertParamExists('markNotificationRead', 'notificationId', notificationId)
            const localVarPath = `/api/v1/users/me/notifications/{notificationId}/read`
                .replace(`{${"notificationId"}}`, encodeURIComponent(String(notificationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} notificationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        markNotificationUnread: async (notificationId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'notificationId' is not null or undefined
            assertParamExists('markNotificationUnread', 'notificationId', notificationId)
            const localVarPath = `/api/v1/users/me/notifications/{notificationId}/unread`
                .replace(`{${"notificationId"}}`, encodeURIComponent(String(notificationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        streamNotificationUpdates: async (userId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('streamNotificationUpdates', 'userId', userId)
            const localVarPath = `/api/v1/users/me/notifications/updates`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (userId !== undefined) {
                localVarQueryParameter['userId'] = userId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserControllerApi - functional programming interface
 * @export
 */
export const UserControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UserControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getArchivedNotifications(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<NotificationDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getArchivedNotifications(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCurrentUser(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserProfileDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCurrentUser(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCurrentUserDetails(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserDetailDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCurrentUserDetails(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUnreadNotifications(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<NotificationDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUnreadNotifications(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} notificationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async markNotificationRead(notificationId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.markNotificationRead(notificationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} notificationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async markNotificationUnread(notificationId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.markNotificationUnread(notificationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async streamNotificationUpdates(userId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<object>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.streamNotificationUpdates(userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UserControllerApi - factory interface
 * @export
 */
export const UserControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UserControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getArchivedNotifications(options?: any): AxiosPromise<Array<NotificationDto>> {
            return localVarFp.getArchivedNotifications(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCurrentUser(options?: any): AxiosPromise<UserProfileDto> {
            return localVarFp.getCurrentUser(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCurrentUserDetails(options?: any): AxiosPromise<UserDetailDto> {
            return localVarFp.getCurrentUserDetails(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUnreadNotifications(options?: any): AxiosPromise<Array<NotificationDto>> {
            return localVarFp.getUnreadNotifications(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} notificationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        markNotificationRead(notificationId: number, options?: any): AxiosPromise<void> {
            return localVarFp.markNotificationRead(notificationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} notificationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        markNotificationUnread(notificationId: number, options?: any): AxiosPromise<void> {
            return localVarFp.markNotificationUnread(notificationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        streamNotificationUpdates(userId: string, options?: any): AxiosPromise<Array<object>> {
            return localVarFp.streamNotificationUpdates(userId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UserControllerApi - object-oriented interface
 * @export
 * @class UserControllerApi
 * @extends {BaseAPI}
 */
export class UserControllerApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserControllerApi
     */
    public getArchivedNotifications(options?: AxiosRequestConfig) {
        return UserControllerApiFp(this.configuration).getArchivedNotifications(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserControllerApi
     */
    public getCurrentUser(options?: AxiosRequestConfig) {
        return UserControllerApiFp(this.configuration).getCurrentUser(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserControllerApi
     */
    public getCurrentUserDetails(options?: AxiosRequestConfig) {
        return UserControllerApiFp(this.configuration).getCurrentUserDetails(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserControllerApi
     */
    public getUnreadNotifications(options?: AxiosRequestConfig) {
        return UserControllerApiFp(this.configuration).getUnreadNotifications(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} notificationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserControllerApi
     */
    public markNotificationRead(notificationId: number, options?: AxiosRequestConfig) {
        return UserControllerApiFp(this.configuration).markNotificationRead(notificationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} notificationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserControllerApi
     */
    public markNotificationUnread(notificationId: number, options?: AxiosRequestConfig) {
        return UserControllerApiFp(this.configuration).markNotificationUnread(notificationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserControllerApi
     */
    public streamNotificationUpdates(userId: string, options?: AxiosRequestConfig) {
        return UserControllerApiFp(this.configuration).streamNotificationUpdates(userId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * WorkflowControllerApi - axios parameter creator
 * @export
 */
export const WorkflowControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} workflowId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        copyWorkflow: async (workflowId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workflowId' is not null or undefined
            assertParamExists('copyWorkflow', 'workflowId', workflowId)
            const localVarPath = `/api/v1/workflows/copy/{workflowId}`
                .replace(`{${"workflowId"}}`, encodeURIComponent(String(workflowId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createWorkflow: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/workflows`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} workflowId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createWorkflowTask: async (workflowId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workflowId' is not null or undefined
            assertParamExists('createWorkflowTask', 'workflowId', workflowId)
            const localVarPath = `/api/v1/workflows/{workflowId}/tasks`
                .replace(`{${"workflowId"}}`, encodeURIComponent(String(workflowId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} workflowId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteWorkflow: async (workflowId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workflowId' is not null or undefined
            assertParamExists('deleteWorkflow', 'workflowId', workflowId)
            const localVarPath = `/api/v1/workflows/{workflowId}`
                .replace(`{${"workflowId"}}`, encodeURIComponent(String(workflowId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} workflowId 
         * @param {number} workflowTaskId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteWorkflowTask: async (workflowId: number, workflowTaskId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workflowId' is not null or undefined
            assertParamExists('deleteWorkflowTask', 'workflowId', workflowId)
            // verify required parameter 'workflowTaskId' is not null or undefined
            assertParamExists('deleteWorkflowTask', 'workflowTaskId', workflowTaskId)
            const localVarPath = `/api/v1/workflows/{workflowId}/tasks/{workflowTaskId}`
                .replace(`{${"workflowId"}}`, encodeURIComponent(String(workflowId)))
                .replace(`{${"workflowTaskId"}}`, encodeURIComponent(String(workflowTaskId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {GenerateTasksViaWorkflowDto} generateTasksViaWorkflowDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateTasks: async (generateTasksViaWorkflowDto: GenerateTasksViaWorkflowDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'generateTasksViaWorkflowDto' is not null or undefined
            assertParamExists('generateTasks', 'generateTasksViaWorkflowDto', generateTasksViaWorkflowDto)
            const localVarPath = `/api/v1/workflows/generate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(generateTasksViaWorkflowDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWorkflow: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getWorkflow', 'id', id)
            const localVarPath = `/api/v1/workflows/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [searchTerm] 
         * @param {string} [sortBy] 
         * @param {boolean} [sortDesc] 
         * @param {Array<number>} [ethicsCommissionIds] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWorkflows: async (page?: number, size?: number, searchTerm?: string, sortBy?: string, sortDesc?: boolean, ethicsCommissionIds?: Array<number>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/workflows`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (searchTerm !== undefined) {
                localVarQueryParameter['searchTerm'] = searchTerm;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (sortDesc !== undefined) {
                localVarQueryParameter['sortDesc'] = sortDesc;
            }

            if (ethicsCommissionIds) {
                localVarQueryParameter['ethicsCommissionIds'] = ethicsCommissionIds;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} workflowId 
         * @param {UpdateWorkflowRequestDto} updateWorkflowRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateWorkflow: async (workflowId: number, updateWorkflowRequestDto: UpdateWorkflowRequestDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workflowId' is not null or undefined
            assertParamExists('updateWorkflow', 'workflowId', workflowId)
            // verify required parameter 'updateWorkflowRequestDto' is not null or undefined
            assertParamExists('updateWorkflow', 'updateWorkflowRequestDto', updateWorkflowRequestDto)
            const localVarPath = `/api/v1/workflows/{workflowId}`
                .replace(`{${"workflowId"}}`, encodeURIComponent(String(workflowId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateWorkflowRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} workflowId 
         * @param {number} workflowTaskId 
         * @param {UpdateWorkflowTaskRequestDto} updateWorkflowTaskRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateWorkflowTask: async (workflowId: number, workflowTaskId: number, updateWorkflowTaskRequestDto: UpdateWorkflowTaskRequestDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workflowId' is not null or undefined
            assertParamExists('updateWorkflowTask', 'workflowId', workflowId)
            // verify required parameter 'workflowTaskId' is not null or undefined
            assertParamExists('updateWorkflowTask', 'workflowTaskId', workflowTaskId)
            // verify required parameter 'updateWorkflowTaskRequestDto' is not null or undefined
            assertParamExists('updateWorkflowTask', 'updateWorkflowTaskRequestDto', updateWorkflowTaskRequestDto)
            const localVarPath = `/api/v1/workflows/{workflowId}/tasks/{workflowTaskId}`
                .replace(`{${"workflowId"}}`, encodeURIComponent(String(workflowId)))
                .replace(`{${"workflowTaskId"}}`, encodeURIComponent(String(workflowTaskId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateWorkflowTaskRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * WorkflowControllerApi - functional programming interface
 * @export
 */
export const WorkflowControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = WorkflowControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} workflowId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async copyWorkflow(workflowId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WorkflowDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.copyWorkflow(workflowId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createWorkflow(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WorkflowDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createWorkflow(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} workflowId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createWorkflowTask(workflowId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WorkflowTaskDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createWorkflowTask(workflowId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} workflowId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteWorkflow(workflowId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteWorkflow(workflowId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} workflowId 
         * @param {number} workflowTaskId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteWorkflowTask(workflowId: number, workflowTaskId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteWorkflowTask(workflowId, workflowTaskId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {GenerateTasksViaWorkflowDto} generateTasksViaWorkflowDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async generateTasks(generateTasksViaWorkflowDto: GenerateTasksViaWorkflowDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TaskDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.generateTasks(generateTasksViaWorkflowDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getWorkflow(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WorkflowDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getWorkflow(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [searchTerm] 
         * @param {string} [sortBy] 
         * @param {boolean} [sortDesc] 
         * @param {Array<number>} [ethicsCommissionIds] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getWorkflows(page?: number, size?: number, searchTerm?: string, sortBy?: string, sortDesc?: boolean, ethicsCommissionIds?: Array<number>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageResponseDtoWorkflowDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getWorkflows(page, size, searchTerm, sortBy, sortDesc, ethicsCommissionIds, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} workflowId 
         * @param {UpdateWorkflowRequestDto} updateWorkflowRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateWorkflow(workflowId: number, updateWorkflowRequestDto: UpdateWorkflowRequestDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WorkflowDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateWorkflow(workflowId, updateWorkflowRequestDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} workflowId 
         * @param {number} workflowTaskId 
         * @param {UpdateWorkflowTaskRequestDto} updateWorkflowTaskRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateWorkflowTask(workflowId: number, workflowTaskId: number, updateWorkflowTaskRequestDto: UpdateWorkflowTaskRequestDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WorkflowTaskDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateWorkflowTask(workflowId, workflowTaskId, updateWorkflowTaskRequestDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * WorkflowControllerApi - factory interface
 * @export
 */
export const WorkflowControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = WorkflowControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {number} workflowId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        copyWorkflow(workflowId: number, options?: any): AxiosPromise<WorkflowDto> {
            return localVarFp.copyWorkflow(workflowId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createWorkflow(options?: any): AxiosPromise<WorkflowDto> {
            return localVarFp.createWorkflow(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} workflowId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createWorkflowTask(workflowId: number, options?: any): AxiosPromise<WorkflowTaskDto> {
            return localVarFp.createWorkflowTask(workflowId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} workflowId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteWorkflow(workflowId: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteWorkflow(workflowId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} workflowId 
         * @param {number} workflowTaskId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteWorkflowTask(workflowId: number, workflowTaskId: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteWorkflowTask(workflowId, workflowTaskId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {GenerateTasksViaWorkflowDto} generateTasksViaWorkflowDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateTasks(generateTasksViaWorkflowDto: GenerateTasksViaWorkflowDto, options?: any): AxiosPromise<Array<TaskDto>> {
            return localVarFp.generateTasks(generateTasksViaWorkflowDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWorkflow(id: number, options?: any): AxiosPromise<WorkflowDto> {
            return localVarFp.getWorkflow(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [searchTerm] 
         * @param {string} [sortBy] 
         * @param {boolean} [sortDesc] 
         * @param {Array<number>} [ethicsCommissionIds] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWorkflows(page?: number, size?: number, searchTerm?: string, sortBy?: string, sortDesc?: boolean, ethicsCommissionIds?: Array<number>, options?: any): AxiosPromise<PageResponseDtoWorkflowDto> {
            return localVarFp.getWorkflows(page, size, searchTerm, sortBy, sortDesc, ethicsCommissionIds, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} workflowId 
         * @param {UpdateWorkflowRequestDto} updateWorkflowRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateWorkflow(workflowId: number, updateWorkflowRequestDto: UpdateWorkflowRequestDto, options?: any): AxiosPromise<WorkflowDto> {
            return localVarFp.updateWorkflow(workflowId, updateWorkflowRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} workflowId 
         * @param {number} workflowTaskId 
         * @param {UpdateWorkflowTaskRequestDto} updateWorkflowTaskRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateWorkflowTask(workflowId: number, workflowTaskId: number, updateWorkflowTaskRequestDto: UpdateWorkflowTaskRequestDto, options?: any): AxiosPromise<WorkflowTaskDto> {
            return localVarFp.updateWorkflowTask(workflowId, workflowTaskId, updateWorkflowTaskRequestDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * WorkflowControllerApi - object-oriented interface
 * @export
 * @class WorkflowControllerApi
 * @extends {BaseAPI}
 */
export class WorkflowControllerApi extends BaseAPI {
    /**
     * 
     * @param {number} workflowId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkflowControllerApi
     */
    public copyWorkflow(workflowId: number, options?: AxiosRequestConfig) {
        return WorkflowControllerApiFp(this.configuration).copyWorkflow(workflowId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkflowControllerApi
     */
    public createWorkflow(options?: AxiosRequestConfig) {
        return WorkflowControllerApiFp(this.configuration).createWorkflow(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} workflowId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkflowControllerApi
     */
    public createWorkflowTask(workflowId: number, options?: AxiosRequestConfig) {
        return WorkflowControllerApiFp(this.configuration).createWorkflowTask(workflowId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} workflowId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkflowControllerApi
     */
    public deleteWorkflow(workflowId: number, options?: AxiosRequestConfig) {
        return WorkflowControllerApiFp(this.configuration).deleteWorkflow(workflowId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} workflowId 
     * @param {number} workflowTaskId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkflowControllerApi
     */
    public deleteWorkflowTask(workflowId: number, workflowTaskId: number, options?: AxiosRequestConfig) {
        return WorkflowControllerApiFp(this.configuration).deleteWorkflowTask(workflowId, workflowTaskId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {GenerateTasksViaWorkflowDto} generateTasksViaWorkflowDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkflowControllerApi
     */
    public generateTasks(generateTasksViaWorkflowDto: GenerateTasksViaWorkflowDto, options?: AxiosRequestConfig) {
        return WorkflowControllerApiFp(this.configuration).generateTasks(generateTasksViaWorkflowDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkflowControllerApi
     */
    public getWorkflow(id: number, options?: AxiosRequestConfig) {
        return WorkflowControllerApiFp(this.configuration).getWorkflow(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [page] 
     * @param {number} [size] 
     * @param {string} [searchTerm] 
     * @param {string} [sortBy] 
     * @param {boolean} [sortDesc] 
     * @param {Array<number>} [ethicsCommissionIds] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkflowControllerApi
     */
    public getWorkflows(page?: number, size?: number, searchTerm?: string, sortBy?: string, sortDesc?: boolean, ethicsCommissionIds?: Array<number>, options?: AxiosRequestConfig) {
        return WorkflowControllerApiFp(this.configuration).getWorkflows(page, size, searchTerm, sortBy, sortDesc, ethicsCommissionIds, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} workflowId 
     * @param {UpdateWorkflowRequestDto} updateWorkflowRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkflowControllerApi
     */
    public updateWorkflow(workflowId: number, updateWorkflowRequestDto: UpdateWorkflowRequestDto, options?: AxiosRequestConfig) {
        return WorkflowControllerApiFp(this.configuration).updateWorkflow(workflowId, updateWorkflowRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} workflowId 
     * @param {number} workflowTaskId 
     * @param {UpdateWorkflowTaskRequestDto} updateWorkflowTaskRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkflowControllerApi
     */
    public updateWorkflowTask(workflowId: number, workflowTaskId: number, updateWorkflowTaskRequestDto: UpdateWorkflowTaskRequestDto, options?: AxiosRequestConfig) {
        return WorkflowControllerApiFp(this.configuration).updateWorkflowTask(workflowId, workflowTaskId, updateWorkflowTaskRequestDto, options).then((request) => request(this.axios, this.basePath));
    }
}


