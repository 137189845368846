import { RepositoryError } from '@ctr-ecs/common/model/exception/repository-exception'
import { MeetingModel } from '@ctr-ecs/common/model/meeting-model'
import { MeetingTask } from '@ctr-ecs/common/model/meeting-task'
import { PageableMeetingsQueryModel } from '@ctr-ecs/common/model/pageable-meetings-query-model'
import { PageableModel } from '@ctr-ecs/common/model/pageable-model'
import { CommonMeetingsRepository } from '@ctr-ecs/common/repository'
import { useNotifications } from '@ctr-ecs/notifications/use/useNotifications'
import { Ref, unref } from 'vue'
import { useQuery } from 'vue-query'
import { MaybeRef } from '@vueuse/core'

let _repository: CommonMeetingsRepository

export function initialize(repository: CommonMeetingsRepository) {
  _repository = repository
}

export function useMeetingsQuery(pageableQuery?: PageableQueryQueryParam<PageableMeetingsQueryModel>, opts?: {
  enabled: Ref<boolean> | boolean
}) {
  const queryKey = ['meetings', pageableQuery]

  return useQuery<PageableModel<MeetingModel>, RepositoryError>(queryKey, () => {
    return _repository.getMeetings(pageableQuery ? unref(pageableQuery) : { size: -1 })
  }, {
    staleTime: 1000 * 60,
    keepPreviousData: true,
    enabled: opts?.enabled,
    retry: 3,
    onError: (err) => {
      useNotifications().warning(err.message)
    }
  })
}

export function useMeetingQuery(id: number | Ref<number>, opts?: { enabled: MaybeRef<boolean> }) {
  const queryKey = ['meeting', id]

  return useQuery<MeetingModel, RepositoryError>(queryKey, () => {
    return _repository.getMeetingById(unref(id))
  }, {
    staleTime: 1000 * 60,
    enabled: opts?.enabled,
    onError: (err) => {
      useNotifications().warning(err.message)
    }
  })
}

export function useMeetingTasksQuery(id: number | Ref<number>) {
  const queryKey = ['meetings-tasks', id]

  return useQuery<MeetingTask[], RepositoryError>(queryKey, () => {
    return _repository.getMeetingTasks(unref(id))
  }, {
    onError: (err) => {
      useNotifications().warning(err.message)
    }
  })
}
