<template>
  <Multiselect
    :classes="{
        container: 'items-center border border-gray-300 rounded-md box-border cursor-pointer flex justify-end my-0 mx-auto outline-none relative w-full',
        containerDisabled: 'cursor-default bg-gray-100',
        // containerOpen: 'rounded-b-none',
        // containerOpenTop: 'rounded-t-none',
        containerActive: '!border-primary-500 ring-1 ring-primary-500',
        singleLabel: 'flex items-center h-full max-w-full absolute left-0 top-0 pointer-events-none bg-transparent leading-snug pl-3.5 pr-16 box-border rtl:left-auto rtl:right-0 rtl:pl-0 rtl:pr-3.5',
        singleLabelText: 'truncate block max-w-full',
        multipleLabel: 'flex items-center h-full absolute left-0 top-0 pointer-events-none bg-transparent leading-snug pl-3.5 rtl:left-auto rtl:right-0 rtl:pl-0 rtl:pr-3.5',
        search: 'w-full outline-none focus:ring-0 appearance-none box-border border-0 text-sm font-sans bg-white rounded pl-3.5 rtl:pl-0 rtl:pr-3.5',
        tags: 'items-center flex flex-grow flex-shrink flex-wrap mt-1 mb-0 mx-0 pl-2',
        tag: 'bg-primary-400 text-white text-sm py-0.5 pl-2 rounded mr-1 mb-1 flex items-center whitespace-nowrap rtl:pl-0 rtl:pr-2 rtl:mr-0 rtl:ml-1',
        tagDisabled: 'pr-2 opacity-50 rtl:pl-2',
        tagRemove: 'flex items-center justify-center p-1 mx-0.5 rounded-sm hover:bg-black hover:bg-opacity-10 group',
        tagRemoveIcon: 'bg-multiselect-remove bg-center bg-no-repeat opacity-30 inline-block w-3 h-3 group-hover:opacity-60 text-white',
        tagsSearchWrapper: 'inline-block flex-grow flex-shrink h-full min-h-[1.5rem] mt-0 mb-1 mx-1 relative',
        tagsSearch: 'appearance-none border-0 inset-0 box-border focus:outline-none focus:ring-0 p-0 absolute w-full',
        tagsSearchCopy: 'invisible whitespace-pre-wrap inline-block h-px w-full',
        placeholder: 'flex items-center h-full absolute left-0 top-0 pointer-events-none bg-transparent leading-snug pl-3.5 text-gray-500 rtl:left-auto rtl:right-0 rtl:pl-0 rtl:pr-3.5',
        // caret: 'bg-grey bg-center bg-no-repeat w-2.5 h-4 py-px box-content mr-3.5 relative z-10 opacity-40 flex-shrink-0 flex-grow-0 transition-transform transform pointer-events-none rtl:mr-0 rtl:ml-3.5',
        caretOpen: 'rotate-180 pointer-events-auto',
        clear: 'pr-3.5 relative z-10 opacity-40 transition duration-300 flex-shrink-0 flex-grow-0 flex hover:opacity-80 rtl:pr-0 rtl:pl-3.5',
        // clearIcon: 'bg-multiselect-remove bg-center bg-no-repeat w-2.5 h-4 py-px box-content inline-block',
        spinner: 'bg-multiselect-spinner bg-center bg-no-repeat w-4 h-4 z-10 mr-3.5 animate-spin flex-shrink-0 flex-grow-0 rtl:mr-0 rtl:ml-3.5',
        infinite: 'flex items-center justify-center w-full',
        infiniteSpinner: 'bg-multiselect-spinner bg-center bg-no-repeat w-4 h-4 z-10 animate-spin flex-shrink-0 flex-grow-0 m-3.5',
        dropdown: 'max-h-60 absolute -left-px -right-px -bottom-1.5 transform translate-y-full -mt-px overflow-y-scroll z-50 bg-white flex flex-col rounded-md py-1 shadow-lg ring-1 ring-black ring-opacity-5',
        dropdownTop: '-translate-y-full top-px bottom-auto rounded-b-none rounded-t',
        dropdownHidden: 'hidden',
        options: 'flex flex-col p-0 m-0 list-none',
        group: 'p-0 m-0',
        groupLabel: 'flex text-sm box-border items-center justify-start text-left py-1 px-3 font-semibold bg-gray-200 cursor-default leading-normal',
        groupLabelPointable: 'cursor-pointer',
        groupLabelPointed: 'bg-gray-300 text-gray-700',
        groupLabelSelected: 'bg-green-600 text-white',
        groupLabelDisabled: 'bg-gray-100 text-gray-300 cursor-not-allowed',
        groupLabelSelectedPointed: 'bg-green-600 text-white opacity-90',
        groupLabelSelectedDisabled: 'text-green-100 bg-green-600 bg-opacity-50 cursor-not-allowed',
        groupOptions: 'p-0 m-0',
        option: 'flex items-center justify-start box-border text-left cursor-pointer text-sm leading-snug py-2 px-3 text-gray-900',
        optionPointed: 'bg-primary-500 text-white',
        optionSelected: 'font-medium',
        optionDisabled: 'text-gray-300 cursor-not-allowed',
        optionSelectedPointed: 'bg-primary text-white font-medium',
        optionSelectedDisabled: 'text-green-100 bg-green-500 bg-opacity-50 cursor-not-allowed',
        noOptions: 'py-2 px-3 text-gray-600 bg-white text-left',
        noResults: 'py-2 px-3 text-gray-600 bg-white text-left',
        fakeInput: 'bg-transparent absolute left-0 right-0 -bottom-px w-full h-px border-0 p-0 appearance-none outline-none text-transparent',
        spacer: 'h-9 py-px box-content',
}"
    v-model="internalModel"
    :can-clear="clearable"
    :can-deselect="nullable"
    class="text-sm"
    :close-on-select="mode === 'single'"
    :label="itemText"
    :loading="loading"
    :mode="mode"
    :object="returnObject"
    :options="items"
    :placeholder="placeholder"
    :resolve-on-load="false"
    :searchable="searchable"
    :track-by="trackBy"
    :value-prop="itemValue"
    :max="maxOptions"
    :open-direction="openDirection"
    @search-change="(search) => $emit('update:search', search)">
    <template #option="{ option, isSelected, isPointed }">
      <div class="relative pl-8 w-full select-none">
        <span>{{ option[itemText] }}</span>

        <span
          v-if="isSelected(option)"
          class="absolute inset-y-0 left-0 flex items-center"
          :class="{'text-primary-500': isSelected(option) && !isPointed(option)}">
          <CheckIcon class="h-5 w-5" aria-hidden="true"/>
        </span>
      </div>
    </template>

    <template #caret>
      <span class="pl-0.5 pr-2">
        <ChevronUpDownIcon class="h-5 w-5 text-gray-400" aria-hidden="true"/>
      </span>
    </template>

    <template #tag="{ option, handleTagRemove }">
      <div class="flex mr-1 mb-1">
        <dw-badge color="primary" dismissible @dismissed="handleTagRemove(option, $event)">
          <span>{{ option[itemText] }}</span>
        </dw-badge>
      </div>
    </template>

    <template #clear="{ clear }">
      <div class="flex-shrink-0 h-4 w-4 text-gray-400" @click="clear">
        <x-mark-icon/>
      </div>
    </template>

    <template #spinner>
      <dw-loading-indicator class="h-4 w-4 text-gray-400"></dw-loading-indicator>
    </template>

    <template #singlelabel="attrs" v-if="$slots.singlelabel">
      <slot name="singlelabel" v-bind="attrs"></slot>
    </template>
  </Multiselect>

  <span class="text-sm text-watermelon-600" v-if="errorMessage">{{ errorMessage }}</span>
</template>

<script lang="ts" setup>
// for the sake of simplicity we use any in this component
/* eslint-disable @typescript-eslint/no-explicit-any */

import { CheckIcon, ChevronUpDownIcon, XMarkIcon } from '@heroicons/vue/24/solid'
import Multiselect from '@vueform/multiselect'
import { useField } from 'vee-validate'
import { computed, getCurrentInstance, ref, toRefs } from 'vue'

interface Props {
  clearable?: boolean
  errorMessageLabel?: string
  items?: any
  itemText?: string
  itemValue?: string
  loading?: boolean
  mode?: 'single' | 'multiple' | 'tags'
  modelValue?: any
  name?: string
  nullable?: boolean
  openDirection?: string
  placeholder?: string
  returnObject?: boolean
  searchable?: boolean
  trackBy?: string
  rules?: any
  maxOptions?: number
}

defineEmits(['update:search', 'update:modelValue'])
defineExpose({
  updateValue
})
const _props = withDefaults(defineProps<Props>(), {
  mode: 'single',
  openDirection: 'bottom',
  items: () => []
})
const props = toRefs(_props)
const inputName = computed<string>(() => props.name?.value || 'multiselect-' + getCurrentInstance()?.uid)
const { value, errorMessage } = useField(inputName, props.rules, {
  label: props.errorMessageLabel || inputName
})

// NOTE: workaround for undefined v-models. assign at least an empty object or array to keep the reference to our v-model alive
const internalModel = computed({
  get: () => value.value ?? ref(props.mode.value === 'single' ? {} : []).value,
  set: (val) => value.value = val
})

function updateValue(v: any) {
  value.value = v
}
</script>
