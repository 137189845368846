import { MeetingModel } from '@ctr-ecs/common/model/meeting-model'
import { MeetingTask } from '@ctr-ecs/common/model/meeting-task'
import { PageableMeetingsQueryModel } from '@ctr-ecs/common/model/pageable-meetings-query-model'
import { PageableModel } from '@ctr-ecs/common/model/pageable-model'
import { CommonMeetingsRepository } from '@ctr-ecs/common/repository'
import { CreateMeetingModel } from '@ctr-ecs/meetings/model/create-meeting-model'
import { UpdateMeetingModel } from '@ctr-ecs/meetings/model/update-meeting-model'
import { MeetingsRepository } from '@ctr-ecs/meetings/repository'
import { MeetingControllerApi } from '@ctr-ecs/repository/apis/openapi/generated'
import { rethrowAsRepositoryError } from '@ctr-ecs/repository/error/rethrow-as-repository-error'
import { TaskModel } from '@ctr-ecs/common/model/task-model'

export class MeetingsRepositoryImpl implements MeetingsRepository, CommonMeetingsRepository {
  constructor(private meetingControllerApi: MeetingControllerApi) {
  }

  async getMeetingById(id: number): Promise<MeetingModel> {
    return rethrowAsRepositoryError(async () => {
      const response = await this.meetingControllerApi.getMeeting(id)
      return response.data
    })
  }

  async createMeeting(meeting: CreateMeetingModel): Promise<MeetingModel> {
    return rethrowAsRepositoryError(async () => {
      const response = await this.meetingControllerApi.createMeeting(meeting)
      return response.data
    })
  }

  async getMeetings(pageable: PageableMeetingsQueryModel): Promise<PageableModel<MeetingModel>> {
    return rethrowAsRepositoryError(async () => {
      const response = await this.meetingControllerApi.getAllMeetings(pageable.page, pageable.size, pageable.sortBy, pageable.sortDesc, pageable.fromDateTime, pageable.toDateTime, pageable.ecId)
      return response.data
    })
  }

  async updateMeeting(meeting: UpdateMeetingModel): Promise<MeetingModel> {
    return rethrowAsRepositoryError(async () => {
      const response = await this.meetingControllerApi.updateMeeting(meeting.id, meeting)
      return response.data
    })
  }

  async getMeetingTasks(id: number): Promise<MeetingTask[]> {
    return rethrowAsRepositoryError(async () => {
      const response = await this.meetingControllerApi.getAssignedTasks(id)
      return response.data
    })
  }

  async getBlockingPredecessors(meetingId: number): Promise<TaskModel[]> {
    return rethrowAsRepositoryError(async () => {
      const response = await this.meetingControllerApi.getBlockingPredecessors(meetingId)
      return response.data
    })
  }

  async useInviteUsers(meetingId: number): Promise<void> {
    return rethrowAsRepositoryError(async () => {
      await this.meetingControllerApi.sendInvitations(meetingId)
    })
  }
}
