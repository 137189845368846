<template>
  <dw-data-table
    :headers="transformedHeaders"
    :items="transformedContent"
    :items-per-page="props.itemsPerPage"
    vertical-lines
    pagination-view="none"
    class="w-full"
  ></dw-data-table>
</template>

<script lang="ts" setup>
import { computed } from 'vue'

interface Props {
  headers: string[]
  content: string[][],
  itemsPerPage?: number
}

const props = defineProps<Props>()

const transformedHeaders = computed(() => props.headers.map((h, idx) => {
  return { text: h, value: idx.toString(), cellClass: '', sortable: true, bodyCellClass: "!whitespace-normal break-words" }
}))
const transformedContent = computed(() => props.content.map(row => {
  return transformedHeaders.value.reduce<Record<string, string>>((mappedRow, header, idx) => {
    mappedRow[idx.toString()] = row[idx]
    return mappedRow
  }, {} as Record<string, string>)
}))
</script>
