import { PageableModel } from '@ctr-ecs/common/model/pageable-model'

export class PageableMapper {
  mapDtoToModel<TDtoElementType>(pageable: PageableModel<TDtoElementType>): PageableModel<TDtoElementType> {
    return this.mapDtoToModelWithMapper(pageable, (element) => element)
  }

  mapDtoToModelWithMapper<TDtoElementType, TModelElementType>(pageable: PageableModel<TDtoElementType>, elementMapper: (element: TDtoElementType) => TModelElementType): PageableModel<TModelElementType> {
    return {
      page: pageable.page,
      size: pageable.size,
      totalElements: pageable.totalElements,
      items: pageable.items.map(elementMapper)
    }
  }
}
