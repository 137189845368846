<template>
  <span>
    <label>
      <input
        type="radio"
        :class="[disabled ? 'text-gray-400 bg-gray-100' : 'focus:ring-primary-500 text-primary-600', 'h-4 w-4 border-gray-300 rounded-full']"
        :name="inputName"
        :disabled="disabled"
        :checked="checked"
        :value="fieldValue"
        @input="handleChange(value)"
      />

      <span class="ml-2 text-sm font-medium select-none" v-if="label">{{ label }}</span>
    </label>

    <span class="text-watermelon-600" v-if="errorMessage">
      {{ errorMessage }}
    </span>
  </span>
</template>

<script lang="ts" setup>
import { useField } from 'vee-validate'
import { computed, getCurrentInstance, toRefs } from 'vue'

interface Props {
  value: unknown
  label?: string
  name?: string
  errorMessageLabel?: string
  rules?: string | Record<string, unknown | unknown[]>
  disabled?: boolean
}

const props = defineProps<Props>()
const { name, value } = toRefs(props)
const inputName = computed<string>(() => name?.value || 'radio-' + getCurrentInstance()?.uid)
const { errorMessage, value: fieldValue, checked, handleChange } = useField(inputName, props.rules, {
  type: 'radio',
  checkedValue: value,
  uncheckedValue: false,
  label: props.errorMessageLabel || inputName
})
</script>
