import { ApplicationMenuNode } from '@ctr-ecs/common/model/application-menu-model'
import { ApplicationNameModel } from '@ctr-ecs/common/model/application-name-model'
import { ApplicationSearchModel } from '@ctr-ecs/common/model/application-search-model'
import { DocumentModel } from '@ctr-ecs/common/model/document-model'
import { Node } from '@ctr-ecs/common/model/node'
import { PageableModel } from '@ctr-ecs/common/model/pageable-model'
import { TrialModel } from '@ctr-ecs/common/model/trial-model'
import { TrialSummaryModel } from '@ctr-ecs/common/model/trial-summary-model'
import { CommonTrialRepository } from '@ctr-ecs/common/repository'
import { PageableMapper } from '@ctr-ecs/repository/mapper/pageable-mapper'
import { ApplicationMeta } from '@ctr-ecs/trials/model/application-meta'
import { ConsiderationModel, ExternalConsiderationModel } from '@ctr-ecs/trials/model/consideration'
import { CreateConsiderationModel } from '@ctr-ecs/trials/model/create-consideration'
import { CreateDocumentCommentModel } from '@ctr-ecs/trials/model/create-document-comment'
import { EvaluationDocumentCommentModel, EvaluationDocumentModel } from '@ctr-ecs/trials/model/evaluation-document'
import { TrialSettingsModel } from '@ctr-ecs/trials/model/trial-settings'
import { UpdateConsiderationModel } from '@ctr-ecs/trials/model/update-consideration'
import { UpdateDocumentCommentModel } from '@ctr-ecs/trials/model/update-document-comment'
import { UpdateTrialSettingsModel } from '@ctr-ecs/trials/model/update-trial-settings'
import { UploadEvaluationDocumentModel, UploadEvaluationDocumentVersionModel } from '@ctr-ecs/trials/model/upload-evaluation-document'
import { TrialRepository } from '@ctr-ecs/trials/repository'
import { ApplicationDetailsQueryParam, EvaluationProcess, EvaluationProcessSection } from '@ctr-ecs/trials/types'
import { ConsiderationControllerApi, CreateConsiderationRequestDto, EvaluationControllerApi, TrialControllerApi, TrialDto, UpdateConsiderationRequestDto } from '../apis/openapi/generated'
import { rethrowAsRepositoryError } from '../error/rethrow-as-repository-error'
import { TrialMapper } from '../mapper/trial-mapper'
import { UpdateDocumentNameModel } from '@ctr-ecs/trials/model/update-document-name'
import { PageableTrialQueryModel } from '@ctr-ecs/trials/model/pageable-trial-query-model'
import { MemberState } from '@ctr-ecs/common/model/member-state'
import { UploadApplicationDocumentModel } from '@ctr-ecs/trials/model/upload-application-document'
import { DeleteApplicationDocumentModel } from '@ctr-ecs/trials/model/delete-application-document-model'
import { ApplicationAssigneeModel } from '@ctr-ecs/trials/model/application-assignee-model'
import { ApplicationAssigneeTypeModel } from '@ctr-ecs/trials/model/application-assignee-type-model'
import { UpdateApplicationAssigneeModel } from '@ctr-ecs/trials/model/update-application-assignee-model'
import { ConfirmTrialBiasedModel } from '@ctr-ecs/trials/model/confirm-trial-biased'

export class TrialRepositoryImpl implements CommonTrialRepository, TrialRepository {
  constructor(
      private trialControllerApi: TrialControllerApi,
      private considerationControllerApi: ConsiderationControllerApi,
      private evaluationControllerApi: EvaluationControllerApi,
      private pageableMapper: PageableMapper,
      private trialMapper: TrialMapper
  ) {
  }

  async getAllMSCs(applicationId: string): Promise<MemberState[]> {
    return rethrowAsRepositoryError(async () => {
      const response = await this.trialControllerApi.getApplicationMscs(applicationId)
      return response.data
    })
  }

  async getAllTrials(): Promise<TrialModel[]> {
    return rethrowAsRepositoryError(async () => {
      const response = await this.trialControllerApi.getAllTrials(0, -1)
      return response.data.items.map(this.trialMapper.mapDtoToModel)
    })
  }

  async getTrials(pageable: PageableTrialQueryModel): Promise<PageableModel<TrialModel>> {
    return rethrowAsRepositoryError(async () => {
      const response = await this.trialControllerApi.getAllTrials(pageable.page, pageable.size, pageable.search, pageable.ecRoles, pageable.trialStatus, pageable.nationalities, pageable.ethicsCommissions)
      return this.pageableMapper.mapDtoToModelWithMapper<TrialDto, TrialModel>(response.data, element => this.trialMapper.mapDtoToModel(element))
    })
  }

  async getTrial(id: string): Promise<TrialModel> {
    return rethrowAsRepositoryError(async () => {
      const response = await this.trialControllerApi.getTrial(id)
      return this.trialMapper.mapDtoToModel(response.data)
    })
  }

  async getApplicationMeta(id: string): Promise<ApplicationMeta> {
    return rethrowAsRepositoryError(async () => {
      const response = await this.trialControllerApi.getApplicationMeta(id)
      return this.trialMapper.mapApplicationMetaDtoToModel(response.data)
    })
  }

  async getApplicationDetails(params: ApplicationDetailsQueryParam): Promise<Node[]> {
    return rethrowAsRepositoryError(async () => {
      const response = await this.trialControllerApi.getApplicationDetail(params.id, params.key, params.preferredDocumentLanguages)
      return response.data.map((n) => this.trialMapper.mapNodeDtoToModel(n)).filter(node => Boolean(node).valueOf()) as Node[]
    })
  }

  async getApplicationSections(id: string): Promise<ApplicationMenuNode[]> {
    return rethrowAsRepositoryError(async () => {
      const response = await this.trialControllerApi.getApplicationSections(id)
      return this.trialMapper.mapApplicationSectionItemDtoToModel(response.data)
    })
  }

  async getTrialSummary(id: string): Promise<TrialSummaryModel> {
    return rethrowAsRepositoryError(async () => {
      const response = await this.trialControllerApi.getTrialSummary(id)
      return this.trialMapper.mapSummaryDtoToModel(response.data)
    })
  }

  async getApplicationDocuments(id: string, preferredLanguages?: Array<string>, preferredPart?: number): Promise<DocumentModel[]> {
    return rethrowAsRepositoryError(async () => {
      const response = await this.trialControllerApi.getApplicationDocuments(id, preferredLanguages, preferredPart)
      return response.data.map(d => this.trialMapper.mapToDocumentModel(d))
    })
  }

  async getExternalApplicationConsiderations(id: string, evaluationProcess: EvaluationProcess, filteredMSCs?: string[], filteredApplicationParts?: string[]): Promise<ExternalConsiderationModel[]> {
    return rethrowAsRepositoryError(async () => {
      const evaluationProcessParam: string = mapEvaluationProcess(evaluationProcess)
      return (await this.considerationControllerApi.getExternalConsiderationsOfApplication(id, evaluationProcessParam, filteredMSCs, filteredApplicationParts)).data
    })
  }

  async getApplicationConsiderations(id: string, evaluationProcess: EvaluationProcess, filteredApplicationParts?: string[]): Promise<ConsiderationModel[]> {
    return rethrowAsRepositoryError(async () => {
      const evaluationProcessParam: string = mapEvaluationProcess(evaluationProcess)
      return (await this.considerationControllerApi.getConsiderationsOfApplication(id, evaluationProcessParam, filteredApplicationParts)).data
    })
  }

  async getTrialApplications(trialId: string): Promise<ApplicationNameModel[]> {
    return rethrowAsRepositoryError(async () => {
      const response = await this.trialControllerApi.getTrialApplications(trialId)
      return response.data.map(a => this.trialMapper.mapToApplicationNameModel(a))
    })
  }

  async updateTrialSettings(model: UpdateTrialSettingsModel): Promise<TrialSettingsModel> {
    return rethrowAsRepositoryError(async () => {
      const response = await this.trialControllerApi.updateTrialSettings(model.trialId, { ethicsCommissionId: model.ethicsCommissionId })
      return this.trialMapper.mapToSettingsModel(response.data)
    })
  }

  async getTrialSettings(id: string): Promise<TrialSettingsModel> {
    return rethrowAsRepositoryError(async () => {
      const response = await this.trialControllerApi.getTrialSettings(id)
      return this.trialMapper.mapToSettingsModel(response.data)
    })
  }

  async synchronizeTrial(id: string): Promise<TrialModel> {
    return rethrowAsRepositoryError(async () => {
      const response = await this.trialControllerApi.synchronizeTrial(id)
      return this.trialMapper.mapDtoToModel(response.data)
    })
  }

  async revokeTransitionalState(id: string): Promise<void> {
    return rethrowAsRepositoryError(async () => {
      await this.trialControllerApi.revokeTransitionalState(id)
    })
  }

  async createConsideration(model: CreateConsiderationModel): Promise<ConsiderationModel> {
    return rethrowAsRepositoryError(async () => {
      const request: CreateConsiderationRequestDto = {
        evaluationProcess: mapEvaluationProcess(model.evaluationProcess),
        applicationId: model.applicationId,
        applicationSectionPartsId: model.applicationSectionPartsId,
        applicationSectionAndDocumentId: model.applicationSectionAndDocumentId,
        consideration: model.consideration,
        part1ConsiderationId: model.part1ConsiderationId,
        transferredToCtis: model.transferredToCtis
      }
      return (await this.considerationControllerApi.createConsideration(request)).data
    })
  }

  async updateConsideration(model: UpdateConsiderationModel): Promise<ConsiderationModel> {
    return rethrowAsRepositoryError(async () => {
      const request: UpdateConsiderationRequestDto = {
        considerationId: model.considerationId,
        applicationSectionPartsId: model.applicationSectionPartsId,
        applicationSectionAndDocumentId: model.applicationSectionAndDocumentId,
        consideration: model.consideration,
        part1ConsiderationId: model.part1ConsiderationId,
        transferredToCtis: model.transferredToCtis
      }
      return (await this.considerationControllerApi.updateConsideration(request)).data
    })
  }

  async getEvaluationDocuments(applicationId: string, evaluationProcess: EvaluationProcess, evaluationProcessSection: EvaluationProcessSection, preferredLanguages?: string[]): Promise<EvaluationDocumentModel[]> {
    return rethrowAsRepositoryError(async () => {
      const response = await this.evaluationControllerApi.getEvaluationDocuments(applicationId, mapEvaluationProcess(evaluationProcess), mapEvaluationProcessSection(evaluationProcessSection), preferredLanguages)
      return response.data
    })
  }

  async uploadNewEvaluationDocument(payload: UploadEvaluationDocumentModel | UploadEvaluationDocumentVersionModel, onUploadProgress: (event: {
    loaded: number,
    total: number
  }) => void): Promise<EvaluationDocumentModel> {
    return rethrowAsRepositoryError(async () => {
      let applicationId: string | undefined
      let evaluationProcess: EvaluationProcess | undefined
      let evaluationProcessSection: EvaluationProcessSection | undefined
      let documentId: number | undefined
      const rfiSequenceNumber: string | undefined = (payload as UploadEvaluationDocumentModel)?.rfiSequenceNumber

      if ('documentId' in payload) {
        documentId = payload.documentId
      } else {
        applicationId = payload.applicationId
        evaluationProcess = payload.evaluationProcess
        evaluationProcessSection = payload.evaluationProcessSection
      }
      const response = await this.evaluationControllerApi.uploadDocument(
          applicationId,
          evaluationProcess ? mapEvaluationProcess(evaluationProcess) : undefined,
          evaluationProcessSection ? mapEvaluationProcessSection(evaluationProcessSection) : undefined,
          documentId,
          payload.file,
          rfiSequenceNumber
          , { onUploadProgress })
      return response.data
    })
  }

  async createDocumentComment(payload: CreateDocumentCommentModel): Promise<EvaluationDocumentCommentModel> {
    return rethrowAsRepositoryError(async () => {
      return (await this.evaluationControllerApi.createDocumentComment(payload)).data
    })
  }

  async updateDocumentComment(payload: UpdateDocumentCommentModel): Promise<EvaluationDocumentCommentModel> {
    return rethrowAsRepositoryError(async () => {
      return (await this.evaluationControllerApi.updateDocumentComment(payload)).data
    })
  }

  async getApplications(search?: string): Promise<ApplicationSearchModel[]> {
    return rethrowAsRepositoryError(async () => {
      return (await this.trialControllerApi.getApplications(search)).data
    })
  }

  async updateEvaluationDocumentName(payload: UpdateDocumentNameModel): Promise<EvaluationDocumentModel> {
    return rethrowAsRepositoryError(async () => {
      return (await this.evaluationControllerApi.updateDocumentName(payload)).data
    })
  }

  async uploadApplicationDocument(payload: UploadApplicationDocumentModel, onUploadProgress: (event: {
    loaded: number,
    total: number
  }) => void): Promise<DocumentModel[]> {
    return rethrowAsRepositoryError(async () => {
      const response = await this.trialControllerApi.uploadApplicationDocuments(payload.applicationId, payload.files, { onUploadProgress })
      return response.data
    })
  }

  async deleteApplicationDocument(payload: DeleteApplicationDocumentModel): Promise<void> {
    return rethrowAsRepositoryError(async () => {
      await this.trialControllerApi.deleteDocument(payload.applicationId, payload.documentId)
    })
  }

  async deleteConsideration(considerationId: number): Promise<void> {
    return rethrowAsRepositoryError(async () => {
      await this.considerationControllerApi.deleteConsideration(considerationId)
    })
  }

  async getApplicationAssignees(applicationId: string): Promise<ApplicationAssigneeModel[]> {
    return rethrowAsRepositoryError(async () => {
      return (await this.trialControllerApi.getApplicationAssignees(applicationId)).data
    })
  }

  async getApplicationAssigneeTypes(applicationId: string): Promise<ApplicationAssigneeTypeModel[]> {
    return rethrowAsRepositoryError(async () => {
      return (await this.trialControllerApi.getApplicationAssigneeTypes(applicationId)).data
    })
  }

  async updateApplicationAssignees(applicationId: string, assignees: UpdateApplicationAssigneeModel[]): Promise<ApplicationAssigneeModel[]> {
    return rethrowAsRepositoryError(async () => {
      return (await this.trialControllerApi.updateApplicationAssignees(applicationId, assignees)).data
    })
  }

  async getUserNeedsBiasConfirmation(trialId: string): Promise<boolean> {
    return rethrowAsRepositoryError(async () => {
      return (await this.trialControllerApi.needsBiasConfirmation(trialId)).data.needsConfirmation
    })
  }

  async confirmTrialBiased(model: ConfirmTrialBiasedModel): Promise<void> {
    return rethrowAsRepositoryError(async () => {
      await this.trialControllerApi.confirmBiased(model.trialId, { comment: model.comment })
    })
  }

  async confirmTrialNotBiased(trialId: string): Promise<void> {
    return rethrowAsRepositoryError(async () => {
      await this.trialControllerApi.confirmNotBiased(trialId)
    })
  }
}

function mapEvaluationProcess(evaluationProcess: EvaluationProcess) {
  switch (evaluationProcess) {
    case 'validation':
      return 'VALIDATION'
    case 'assessment-part-1':
      return 'PART1'
    case 'assessment-part-2':
      return 'PART2'
  }
}

function mapEvaluationProcessSection(evaluationProcessSection: EvaluationProcessSection) {
  switch (evaluationProcessSection) {
    case 'rfi':
      return 'RFI'
    case 'draft-assessment-report':
      return 'DRAFT_ASSESSMENT_REPORT'
    case 'conclusion':
      return 'CONCLUSION'

  }
}
