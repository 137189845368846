<template>
  <div class="aspect-ratio" :style="paddingTopStyle">
    <div class="aspect-ratio__child">
      <slot></slot>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, toRefs } from 'vue'

export default defineComponent({
  props: {
    aspectRatio: {
      type: [Number],
      default: 1
    }
  },
  setup(props) {
    const { aspectRatio } = toRefs(props)

    const paddingTopStyle = computed(() => {
      return {
        paddingTop: `${(1 / aspectRatio.value) * 100}%`
      }
    })

    return {
      paddingTopStyle
    }
  }
})
</script>

<!--<style scoped lang="scss">
.aspect-ratio {
  width: 100%;
  position: relative;
  overflow: hidden;

  &__child {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }
}
</style>-->