<template>
  <aspect-ratio>
    <div class="qr-code-scanner" @click="showSettingsPanel = false">
      <canvas v-show="isCameraPermitted" ref="canvas" class="qr-code-scanner__canvas"></canvas>

      <div v-if="isCameraPermitted && !isStreaming" class="qr-code-scanner__pause-overlay">
        <button @click="startStreaming">Fortsetzen</button>
      </div>

      <div v-else-if="isCameraPermitted" class="qr-code-scanner__controls">
        <button @click.stop="showSettingsPanel = !showSettingsPanel">⚙️</button>
      </div>

      <div v-else-if="!isCameraPermitted && isCameraSupported" class="qr-code-scanner__center-children">
        <button @click="startStreaming">Kamera aktivieren</button>
      </div>

      <p v-else>Kamerazugriff wird vom Browser nicht unterstützt</p>

      <div class="qr-code-scanner__settings-panel" v-if="showSettingsPanel" @click.stop>
        <select v-model="currentCameraId">
          <option disabled :value="null">Kamera auswählen</option>
          <option v-for="camera in cameras" :value="camera.deviceId" :key="camera.deviceId">{{ camera.label }}</option>
        </select>
        <p>{{ framesPerSecond }} FPS / {{ scansPerSecond }} SPS</p>
      </div>
    </div>
  </aspect-ratio>
</template>

<script lang="ts">
/* eslint-disable */
import { defineComponent, onBeforeUnmount, onMounted, PropType, Ref, ref, watchEffect } from 'vue'
import { useDevicePixelRatio, useDevicesList, useRafFn, useStorage, useTimeoutFn, useUserMedia } from '@vueuse/core'
import { QRCode } from 'jsqr'
import AspectRatio from '../aspect-ratio.vue'
import ParserWorker from './parser-worker.ts?worker&inline'
import { useFPSCalculator } from '../../use/use-fps-calculator'

export default defineComponent({
  name: 'qr-code-scanner',
  components: { AspectRatio },
  props: {
    pauseAfterSeconds: {
      type: Number,
      default: 60
    },
    pattern: {
      type: Object as () => RegExp,
      default: () => /.+/
    },
    highlightQrCode: {
      type: Function as PropType<(context: CanvasRenderingContext2D, qrCode: QRCode, matchesPattern: boolean) => void>,
      default: (context: CanvasRenderingContext2D, qrCode: QRCode, matchesPattern: boolean) => {
        const highlightColor = matchesPattern ? 'yellowgreen' : '#ff3b58'
        const topLeftCorner = {
          x: qrCode.location.topLeftCorner.x,
          y: qrCode.location.topLeftCorner.y
        }
        const topRightCorner = {
          x: qrCode.location.topRightCorner.x,
          y: qrCode.location.topRightCorner.y
        }
        const bottomLeftCorner = {
          x: qrCode.location.bottomLeftCorner.x,
          y: qrCode.location.bottomLeftCorner.y
        }
        const bottomRightCorner = {
          x: qrCode.location.bottomRightCorner.x,
          y: qrCode.location.bottomRightCorner.y
        }

        function fillPolygon(context: CanvasRenderingContext2D, points: { x: number, y: number }[], color: string) {
          if (points.length < 3) {
            console.warn('cannot draw polygon with less than 3 points')
            return
          }

          const oldAlpha = context.globalAlpha
          context.globalAlpha = .2
          context.fillStyle = color
          context.beginPath()
          context.moveTo(points[0].x, points[0].y)

          points.forEach((point) => {
            context.lineTo(point.x, point.y)
          })

          context.closePath()
          context.fill()
          context.globalAlpha = oldAlpha
        }

        function drawLine(context: CanvasRenderingContext2D, begin: { x: number, y: number }, end: { x: number, y: number }, color: string) {
          context.beginPath()
          context.moveTo(begin.x, begin.y)
          context.lineTo(end.x, end.y)
          context.lineWidth = 4
          context.strokeStyle = color
          context.stroke()
        }

        fillPolygon(context, [topLeftCorner, topRightCorner, bottomRightCorner, bottomLeftCorner], highlightColor)
        drawLine(context, topLeftCorner, topRightCorner, highlightColor)
        drawLine(context, topRightCorner, bottomRightCorner, highlightColor)
        drawLine(context, bottomRightCorner, bottomLeftCorner, highlightColor)
        drawLine(context, bottomLeftCorner, topLeftCorner, highlightColor)
      }
    }
  },
  emits: ['scan'],
  setup(props, { emit }) {
    const pauseAfterSeconds = typeof props.pauseAfterSeconds === 'number' ? props.pauseAfterSeconds : parseInt(props.pauseAfterSeconds)
    let lastScanTimestamp: number | null = null
    let lastScannedQrCode: QRCode | null = null
    let shouldHighlightQrCode = false
    let isParserBusy = false
    let parserWorker: Worker
    const videoElement: HTMLVideoElement = document.createElement('video')
    videoElement.playsInline = true
    const { fps: framesPerSecond, updateFPS } = useFPSCalculator()
    const { fps: scansPerSecond, updateFPS: updateScansPerSecond } = useFPSCalculator()
    const { pixelRatio } = useDevicePixelRatio()
    const canvasElement: Ref<HTMLCanvasElement | null> = ref(null)
    const currentCameraId = useStorage<string>('lastSelectedCameraId', null)
    const { start, stop, enabled: isStreaming, isSupported: isCameraSupported } = useUserMedia({
      videoDeviceId: currentCameraId,
      audioDeviceId: false,
      autoSwitch: true,
      enabled: false
    })
    const { videoInputs: cameras } = useDevicesList({ requestPermissions: false })
    const isCameraPermitted: Ref<boolean> = ref(false)
    const showSettingsPanel: Ref<boolean> = ref(false)
    const { resume: resumeQRCodeParsing, pause: pauseQRCodeParsing } = useRafFn(() => {
      const timestamp = window.performance.now()

      updateFPS()

      if (videoElement.readyState === videoElement.HAVE_ENOUGH_DATA && canvasElement.value) {
        if (lastScanTimestamp === null) {
          lastScanTimestamp = timestamp
        }

        const canvasContext = canvasElement.value.getContext('2d')!
        canvasContext.drawImage(videoElement, 0, 0, canvasElement.value.width, canvasElement.value.height)
        const elapsed = timestamp - lastScanTimestamp

        if (elapsed > 2000) {
          shouldHighlightQrCode = false
          lastScannedQrCode = null
        }

        if (!isParserBusy) {
          isParserBusy = true
          const imageData = canvasContext.getImageData(0, 0, canvasElement.value.width, canvasElement.value.height)
          parserWorker.postMessage(imageData)
        }

        if (lastScannedQrCode !== null && shouldHighlightQrCode) {
          props.highlightQrCode(canvasContext, lastScannedQrCode, props.pattern.test(lastScannedQrCode.data))
        }
      }
    })
    const { start: startPauseTimeout } = useTimeoutFn(stopStreaming, pauseAfterSeconds * 1000, { immediate: false })

    async function startStreaming() {
      try {
        videoElement.srcObject = (await start()) || null
        await videoElement.play()
        isCameraPermitted.value = true
        resumeQRCodeParsing()
        fitCanvasToDevicePixelRatioAndVideoDimensions(videoElement.videoWidth, videoElement.videoHeight)
        startPauseTimeout()
      } catch (e) {
        isCameraPermitted.value = false
        console.warn(e)
      }
    }

    function stopStreaming() {
      pauseQRCodeParsing()
      stop()
      videoElement.srcObject = null
    }

    function onQrCodeParsed(event: MessageEvent & { data: QRCode | null }) {
      const qrCode = event.data

      if (qrCode) {
        updateScansPerSecond()
        startPauseTimeout()

        if (props.pattern.test(qrCode.data) && (lastScannedQrCode === null || qrCode !== lastScannedQrCode)) {
          emit('scan', qrCode)
        }

        lastScanTimestamp = window.performance.now()
        lastScannedQrCode = qrCode
        shouldHighlightQrCode = true
      }

      isParserBusy = false
    }

    function fitCanvasToDevicePixelRatioAndVideoDimensions(videoWidth: number, videoHeight: number) {
      canvasElement.value!.width = videoWidth * pixelRatio.value
      canvasElement.value!.height = videoHeight * pixelRatio.value
    }

    function initializeParserWorker() {
      // @ts-ignore
      parserWorker = ParserWorker()
      parserWorker.addEventListener('message', onQrCodeParsed)
    }

    // initialize selected camera if it's not set
    watchEffect(() => {
      if (currentCameraId.value === null && cameras.value.length > 0 && cameras.value[0].deviceId.length > 0) {
        currentCameraId.value = cameras.value[0]?.deviceId || null
      }
    })

    onMounted(async () => {
      initializeParserWorker()
      await startStreaming()
    })

    onBeforeUnmount(() => {
      stopStreaming()
      parserWorker.terminate()
    })

    return {
      isCameraSupported,
      isCameraPermitted,
      startStreaming,
      showSettingsPanel,
      isStreaming,
      cameras,
      currentCameraId,
      canvas: canvasElement,
      framesPerSecond,
      scansPerSecond
    }
  }
})
</script>

<!--<style scoped lang="scss">
.qr-code-scanner {
  position: relative;
  height: 100%;
  width: 100%;

  &__canvas {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }

  &__pause-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, .8);
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px);

    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__controls {
    position: absolute;
    right: 20px;
    bottom: 20px;
  }

  &__center-children {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
  }

  &__settings-panel {
    position: absolute;
    bottom: 20px;
    right: 20px;

    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px);
    background-color: rgba(255, 255, 255, .4);
    border-radius: 5px;
    color: black;
    box-shadow: 0 0 10px rgba(0, 0, 0, .3);

    padding: 20px;
    text-align: end;

    p {
      font-family: monospace;
    }
  }
}
</style>-->
