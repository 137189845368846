// eslint-disable-next-line boundaries/element-types
import { i18n } from '@ctr-ecs/application/i18n/plugin'
import { useAuth } from '@ctr-ecs/auth/use/useAuth'
import { useMonitoring } from '@ctr-ecs/common/use/use-monitoring'
import axios, { AxiosInstance, AxiosRequestConfig } from 'axios'

export class AxiosManager {
  private readonly _axios: AxiosInstance

  constructor(private storage: Storage, config?: AxiosRequestConfig) {
    this._axios = axios.create(config)
    this._axios.interceptors.request.use((config) => {
      const auth = useAuth()

      if (config.headers) {
        config.headers['Authorization'] = ['Bearer', auth.accessToken.value].join(' ')
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        config.headers['Accept-Language'] = i18n.global.locale.value
      }

      return config
    }, error => Promise.reject(error))
    this._axios.interceptors.response.use(response => response, (error) => {
      if (error.response?.status === 403) {
        useMonitoring().captureException(error)
      }
      return Promise.reject(error)
    })
  }

  public get axios() {
    return this._axios
  }
}
