import { createI18n } from 'vue-i18n'
import { en } from '../locales'

// if multiple languages: combine with '|' e.g. 'de' | 'en' | 'ja'
type SupportedLocales = 'en-GB'
type MessageSchema = typeof en
const defaultLocale = 'en-GB'

const i18n = createI18n<[MessageSchema], SupportedLocales>({
  legacy: false,
  locale: defaultLocale,
  fallbackLocale: defaultLocale,
  messages: { 'en-GB': en }
})

export {
  i18n
}
