<template>
  <button
    v-if="isSupported"
    @click="copy(textToCopy)"
    class="rounded text-xs flex items-center hover:text-gray-800"
    title="Copy">
    <dw-fade-transition mode="out-in">
      <dw-copy-icon v-if="!copied"></dw-copy-icon>
      <dw-check-icon v-else></dw-check-icon>
    </dw-fade-transition>
  </button>
</template>

<script lang="ts" setup>
import { useClipboard } from '@vueuse/core'

interface Props {
  textToCopy: string
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const props = defineProps<Props>()
const { copied, copy, isSupported } = useClipboard()
</script>
