import { WorkflowRepository } from '@ctr-ecs/workflows/repository'
import { useMutation, useQueryClient } from 'vue-query'
import { UpdateWorkflowModel } from '@ctr-ecs/workflows/model/update-workflow-model'
import { RepositoryError } from '@ctr-ecs/common/model/exception/repository-exception'
import { WorkflowModel } from '@ctr-ecs/common/model/workflow-model'
import { WorkflowTaskModel } from '@ctr-ecs/common/model/workflow-task-model'
import { UpdateWorkflowTaskModel } from '@ctr-ecs/workflows/model/update-workflow-task-model'

let _repository: WorkflowRepository

export function initialize(repository: WorkflowRepository) {
  _repository = repository
}

export function useUpdateWorkflowMutation() {
  const queryClient = useQueryClient()

  return useMutation<WorkflowModel, RepositoryError, UpdateWorkflowModel>('updateWorkflow', (model: UpdateWorkflowModel) => {
    return _repository.updateWorkflow(model)
  }, {
    async onSuccess(data: WorkflowModel, variables: UpdateWorkflowModel) {
      await queryClient.invalidateQueries(['workflows'])
      queryClient.setQueryData(['workflow', variables.id], data)
    }
  })
}

export function useUpdateWorkflowTaskMutation() {
  const queryClient = useQueryClient()

  return useMutation<WorkflowTaskModel, RepositoryError, UpdateWorkflowTaskModel>('updateWorkflowTask', (model: UpdateWorkflowTaskModel) => {
    return _repository.updateWorkflowTask(model)
  }, {
    async onSuccess(data: WorkflowTaskModel, variables: UpdateWorkflowTaskModel) {
      await queryClient.invalidateQueries(['workflow', variables.workflowId])
    }
  })
}

export function useCreateWorkflowTaskMutation() {
  const queryClient = useQueryClient()

  return useMutation<WorkflowTaskModel, RepositoryError, number>('createWorkflowTask', (workflowId: number) => {
    return _repository.createWorkflowTask(workflowId)
  }, {
    async onSuccess(data: WorkflowTaskModel, variables: number) {
      await queryClient.invalidateQueries(['workflow', variables])
    }
  })
}

export function useDeleteWorkflowTaskMutation() {
  const queryClient = useQueryClient()

  return useMutation<void, RepositoryError, { workflowId: number, taskId: number }>('deleteWorkflowTask', (variables) => {
    return _repository.deleteWorkflowTask(variables.workflowId, variables.taskId)
  }, {
    async onSuccess(data: void, variables: { workflowId: number, taskId: number }) {
      await queryClient.invalidateQueries(['workflow', variables.workflowId])
    }
  })
}

export function useDeleteWorkflowMutation() {
  const queryClient = useQueryClient()

  return useMutation<void, RepositoryError, number>('deleteWorkflow', (workflowId: number) => {
    return _repository.deleteWorkflow(workflowId)
  }, {
    async onSuccess(data: void, variables: number) {
      await queryClient.invalidateQueries(['workflows'])
      queryClient.removeQueries(['workflow', variables])
    }
  })
}

export function useCreateWorkflowMutation() {
  const queryClient = useQueryClient()

  return useMutation<WorkflowModel, RepositoryError, void>('createWorkflow', () => {
    return _repository.createWorkflow()
  }, {
    async onSuccess() {
      await queryClient.invalidateQueries(['workflows'])
    }
  })
}

export function useDuplicateWorkflowMutation() {
  const queryClient = useQueryClient()

  return useMutation<WorkflowModel, RepositoryError, number>('duplicateWorkflow', (workflowId: number) => {
    return _repository.duplicateWorkflow(workflowId)
  }, {
    async onSuccess(data: WorkflowModel,  variables: number) {
      await queryClient.invalidateQueries(['workflows'])
      await queryClient.invalidateQueries(['workflow', variables])
    }
  })
}