import { CommonMeetingsRepository, CommonTaskRepository, CommonTrialRepository, CommonUserRepository, CommonWorkflowRepository, EthicCommissionsRepository } from '@ctr-ecs/common/repository'
import { MeetingsRepository } from '@ctr-ecs/meetings/repository'
import { UserMapper } from '@ctr-ecs/repository/mapper/user-mapper'
import { MeetingsRepositoryImpl } from '@ctr-ecs/repository/repositories/meetings-repository-impl'
import { UserRepositoryImpl } from '@ctr-ecs/repository/repositories/user-repository-impl'
import { TasksRepository } from '@ctr-ecs/tasks/repository'
import { TrialRepository } from '@ctr-ecs/trials/repository'
import { AdminControllerApi, ConsiderationControllerApi, EthicsCommissionControllerApi, EvaluationControllerApi, MeetingControllerApi, TaskControllerApi, TrialControllerApi, UserControllerApi, WorkflowControllerApi } from './apis/openapi/generated'
import { AxiosManager } from './axios-manager'
import { PageableMapper } from './mapper/pageable-mapper'
import { TaskMapper } from './mapper/task-mapper'
import { TrialMapper } from './mapper/trial-mapper'
import { WorkflowRepository } from '@ctr-ecs/workflows/repository'
// import hook
import { EthicsCommissionRepositoryImpl } from './repositories/ethics-commission-repository-impl'
import { TasksRepositoryImpl } from './repositories/tasks-repository-impl'
import { TrialRepositoryImpl } from './repositories/trial-repository-impl'
import { AdminRepository } from '@ctr-ecs/admin/repository'
import { AdminRepositoryImpl } from '@ctr-ecs/repository/repositories/admin-repository-impl'
import { WorkflowRepositoryImpl } from '@ctr-ecs/repository/repositories/workflow-repository-impl'

export const API_URL = import.meta.env.VITE_API_URL as string
const axiosManager = new AxiosManager(window.localStorage, {
  baseURL: API_URL
})
const pageableMapper = new PageableMapper()
const taskMapper = new TaskMapper()
const trialMapper = new TrialMapper()
const userMapper = new UserMapper()
const taskApi = new TaskControllerApi(undefined, API_URL, axiosManager.axios)
const ethicsCommissionApi = new EthicsCommissionControllerApi(undefined, API_URL, axiosManager.axios)
const meetingApi = new MeetingControllerApi(undefined, API_URL, axiosManager.axios)
const trialApi = new TrialControllerApi(undefined, API_URL, axiosManager.axios)
const considerationApi = new ConsiderationControllerApi(undefined, API_URL, axiosManager.axios)
const evaluationApi = new EvaluationControllerApi(undefined, API_URL, axiosManager.axios)
const userApi = new UserControllerApi(undefined, API_URL, axiosManager.axios)
const adminApi = new AdminControllerApi(undefined, API_URL, axiosManager.axios)
const workflowApi = new WorkflowControllerApi(undefined, API_URL, axiosManager.axios)

const _taskRepo = new TasksRepositoryImpl(taskApi, pageableMapper, taskMapper)
export const tasksRepository: TasksRepository = _taskRepo
export const commonTaskRepository: CommonTaskRepository = _taskRepo

export const ethicsCommissionRepository: EthicCommissionsRepository = new EthicsCommissionRepositoryImpl(ethicsCommissionApi)

const _meetingRepo = new MeetingsRepositoryImpl(meetingApi)
export const meetingRepository: MeetingsRepository = _meetingRepo
export const commonMeetingRepository: CommonMeetingsRepository = _meetingRepo

const _trialRepo = new TrialRepositoryImpl(trialApi, considerationApi, evaluationApi, pageableMapper, trialMapper)
export const trialRepository: TrialRepository = _trialRepo
export const commonTrialRepository: CommonTrialRepository = _trialRepo

const _userRepo = new UserRepositoryImpl(userApi, userMapper)
export const commonUserRepository: CommonUserRepository = _userRepo
export const adminRepository: AdminRepository = new AdminRepositoryImpl(adminApi, pageableMapper, userMapper)

const _workflowRepo = new WorkflowRepositoryImpl(workflowApi)
export const workflowRepository: WorkflowRepository = _workflowRepo
export const commonWorkflowRepository: CommonWorkflowRepository = _workflowRepo

// initialization hook
