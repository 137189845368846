import { UserWithPermissionsModel } from '@ctr-ecs/auth/models/user-with-permissions-model'
// eslint-disable-next-line boundaries/element-types
import { ROUTE_NAME } from '@ctr-ecs/common/config/route'

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function getDefaultRouteForLoggedInUser(user: UserWithPermissionsModel): ROUTE_NAME {
  /**
   * Uncomment if necessary
   *
   * if(user.permissions.canViewUsers) {
   *   return '...'
   * } else if (user.permissions.canEditUsers) {
   *   return '...'
   * }
   */

  return ROUTE_NAME.TASKS
}
