import { CreateUserModel } from '@ctr-ecs/admin/model/create-user'
import { GroupModel } from '@ctr-ecs/admin/model/group'
import { UpdateUserModel } from '@ctr-ecs/admin/model/update-user'
import { UserAdminModel } from '@ctr-ecs/admin/model/user-admin'
import { AdminRepository } from '@ctr-ecs/admin/repository'
import { AdminControllerApi, CreateUserRequestDto, UpdateUserRequestDto } from '../apis/openapi/generated'
import { rethrowAsRepositoryError } from '../error/rethrow-as-repository-error'
import { PageableUsersQueryModel } from '@ctr-ecs/admin/model/pageable-users-query-model'
import { PageableModel } from '@ctr-ecs/common/model/pageable-model'
import { PageableMapper } from '@ctr-ecs/repository/mapper/pageable-mapper'
import { UserMapper } from '@ctr-ecs/repository/mapper/user-mapper'

export class AdminRepositoryImpl implements AdminRepository {
  constructor(private adminControllerApi: AdminControllerApi, private pageableMapper: PageableMapper, private userMapper: UserMapper) {
  }

  async getUsers(pageableQuery: PageableUsersQueryModel): Promise<PageableModel<UserAdminModel>> {
    return rethrowAsRepositoryError(async () => {
      const response = await this.adminControllerApi.getUsers(
          pageableQuery.page,
          pageableQuery.size,
          pageableQuery.search,
          pageableQuery.sortBy,
          pageableQuery.sortDesc,
          pageableQuery.ethicsCommissionIds)
      return this.pageableMapper.mapDtoToModelWithMapper(response.data, this.userMapper.mapDtoToModel)
    })
  }


  async getUser(id: string): Promise<UserAdminModel> {
    return rethrowAsRepositoryError(async () => {
      return (await this.adminControllerApi.getUser(id)).data
    })
  }

  async createUser(user: CreateUserModel): Promise<UserAdminModel> {
    return rethrowAsRepositoryError(async () => {
      const data: CreateUserRequestDto = {
        ...user,
        ethicsCommissionId: Array.isArray(user.ethicsCommissionId) ? undefined : user.ethicsCommissionId,
        groupIds: user.groups
      }
      return (await this.adminControllerApi.createUser(data)).data
    })
  }

  async getGroups(): Promise<GroupModel[]> {
    return rethrowAsRepositoryError(async () => {
      return (await this.adminControllerApi.getGroups()).data
    })
  }

  async updateUser(user: UpdateUserModel): Promise<UserAdminModel> {
    return rethrowAsRepositoryError(async () => {
      const data: UpdateUserRequestDto = {
        ...user,
        groupIds: user.groups
      }
      return (await this.adminControllerApi.updateUser(user.id, data)).data
    })
  }

  async getNotificationTypes(): Promise<string[]> {
    return rethrowAsRepositoryError(async () => {
      return (await this.adminControllerApi.getAvailableNotificationTypes()).data
    })
  }

  async sendResetPasswordMail(userId: string): Promise<void> {
    await rethrowAsRepositoryError(async () => {
      await this.adminControllerApi.resetPassword(userId)
    })
  }

  async softDeleteUser(userId: string): Promise<void> {
    await rethrowAsRepositoryError(async () => {
      await this.adminControllerApi.deleteUser(userId)
    })
  }
}
