<template>
  <div class="rounded-[5px] border border-gray-100 overflow-hidden sm:shadow">
    <div class="flex items-center bg-white py-4 px-4 sm:px-6 select-none" v-if="paginationTop">
      <pagination
        class="flex-1"
        :total="totalData"
        v-model:page="internalPage"
        v-model:per-page="internalItemsPerPage"
        :items-per-page-options="itemsPerPageOptions">
        <template #pagination-info="paginationInfo">
          <slot name="pagination-info" :start="paginationInfo.start" :end="paginationInfo.end" :total="paginationInfo.total">
            <i18n-t keypath="ui.dataTable.pagination.info" tag="span" scope="global">
              <template v-slot:firstItemInPage>
                <span class="font-medium">{{ paginationInfo.start }}</span>
              </template>
              <template v-slot:lastItemInPage>
                <span class="font-medium">{{ paginationInfo.end }}</span>
              </template>
              <template v-slot:totalItems>
                <span class="font-medium">{{ paginationInfo.total }}</span>
              </template>
            </i18n-t>
          </slot>
        </template>
      </pagination>
    </div>

    <div class="overflow-x-auto">
      <table class="min-w-full divide-y divide-gray-100" :class="{'table-fixed': fixedLayout}">
        <slot name="thead" :columns="tableColumns" :set-sort="setSort" :sort-by="sortBy" :sort-desc="sortDesc">
          <thead class="bg-gray-50 select-none">
          <tr :class="{ 'divide-x divide-grey-100': verticalLines }">
            <TableHeadCell
              v-for="column in tableColumns"
              :key="`datatable-thead-th-${column.value}`"
              :sort-by="column.value"
              :sortable="column.sortable"
              :class="column.cellClass">
              {{ column.text }}
            </TableHeadCell>
          </tr>
          </thead>
        </slot>

        <draggable
          v-model="tableRows"
          :disabled="!dragableHandle"
          tag="tbody"
          :item-key="'id'"
          :handle="dragableHandle"
          :component-data="{ class: { 'divide-y divide-grey-100': !striped } }">
          <template #item="{ element: row, index }">
            <tr
              :class="{ 'divide-x divide-grey-100': verticalLines, 'bg-white': !striped, 'odd:bg-white even:bg-gray-50': striped, 'hover:bg-gray-100': hoverable, 'cursor-pointer': hasClickRowListener }"
              :key="`datatable-row-${uniqueId()}-${index}`"
              @click="rowClickHandler(row)">
              <td
                class="px-4 py-3 whitespace-nowrap text-sm font-normal text-gray-500 select-auto"
                :class="column.bodyCellClass"
                v-for="column in tableColumns"
                :key="`datatable-tbody-td-${uniqueId()}-${column.value}`">
                <slot :name="`item:${column.value.replaceAll('.', ':')}`" :item="row">
                  {{ getItemText(row, column.value) }}
                </slot>
              </td>
            </tr>
          </template>
        </draggable>

        <tr v-if="loading" class="bg-white select-none">
          <slot name="loading">
            <td class="px-6 py-4 whitespace-nowrap text-sm font-normal text-gray-500 none" :colspan="tableColumns.length">
              <div class="flex justify-center">
                <dw-logo-icon animated class="h-5"></dw-logo-icon>
              </div>
            </td>
          </slot>
        </tr>

        <tr v-if="tableRows.length === 0 && !loading" class="bg-white">
          <slot name="empty">
            <td class="px-6 py-4 whitespace-nowrap text-sm font-normal text-gray-500 select-none" :colspan="tableColumns.length">
              {{ $t('ui.dataTable.empty') }}
            </td>
          </slot>
        </tr>
      </table>
    </div>

    <div class="flex items-center bg-white py-4 px-4 sm:px-6 border-t select-none" v-if="paginationBottom">
      <pagination
        class="flex-1"
        :total="totalData"
        v-model:page="internalPage"
        v-model:per-page="internalItemsPerPage"
        :items-per-page-options="itemsPerPageOptions">
        <template #pagination-info="paginationInfo">
          <slot name="pagination-info" :start="paginationInfo.start" :end="paginationInfo.end" :total="paginationInfo.total">
            <i18n-t keypath="ui.dataTable.pagination.info" tag="span" scope="global">
              <template v-slot:firstItemInPage>
                <span class="font-medium">{{ paginationInfo.start }}</span>
              </template>
              <template v-slot:lastItemInPage>
                <span class="font-medium">{{ paginationInfo.end }}</span>
              </template>
              <template v-slot:totalItems>
                <span class="font-medium">{{ paginationInfo.total }}</span>
              </template>
            </i18n-t>
          </slot>
        </template>
      </pagination>
    </div>
  </div>
</template>

<script lang="ts">
// https://github.com/JoBinsJP/vue3-datatable

// https://github.com/JoBinsJP/vue3-datatable
import { computed, defineComponent, InjectionKey, PropType, provide, Ref, ref, watch } from 'vue'
import Draggable from 'vuedraggable'
import Pagination from './components/pagination/pagination.vue'
import TableHeadCell from './components/table/table-head-cell.vue'

export const SortableColumnInjectionKey = Symbol('sortable column injection key symbol') as InjectionKey<SortableColumn>

export interface SortableColumn {
  sortBy: Ref<string | undefined>
  sortDesc: Ref<boolean>
  setSorting: (sortBy: string | undefined, sortDesc: boolean) => void
}

export default defineComponent({
  inheritAttrs: false,
  name: 'DataTable',
  components: {
    Draggable,
    TableHeadCell,
    Pagination
  },
  props: {
    items: { type: Array as PropType<unknown[]>, default: () => [] },
    // eslint-disable-next-line no-undef
    headers: { type: Array as PropType<TableHeader<unknown>[]>, required: false, default: () => [] },
    loading: { type: Boolean },
    serverPagination: { type: Boolean },
    page: { type: Number, default: 0 },
    itemsPerPage: { type: Number, default: 10 },
    totalItems: { type: Number, default: -1 },
    sortBy: { type: String, default: undefined },
    sortDesc: { type: Boolean },
    serverSort: { type: Boolean },
    itemsPerPageOptions: {
      type: Array as PropType<Array<number>>,
      required: false,
      default: () => [5, 10, 25, 100, -1]
    },
    paginationView: {
      type: String,
      default: 'both',
      validator: (value: string) => ['bottom', 'top', 'none', 'both'].includes(value)
    },
    striped: { type: Boolean },
    hoverable: { type: Boolean },
    verticalLines: { type: Boolean },
    fixedLayout: { type: Boolean },
    dragableHandle: { type: String }
  },

  emits: [
    'update:table-options',
    'update:sort-by',
    'update:sort-desc',
    'update:page',
    'update:itemsPerPage', /* 'click:row' is missing on purpose, since we can check if it is bound when not mentioned in emits array */
    'update:items'
  ],

  setup(props, context) {
    const internalSortBy = ref<string | undefined>(props.sortBy)
    const internalSortDesc = ref<boolean>(props.sortDesc)
    const internalPage = ref<number>(0)
    const internalItemsPerPage = ref<number>(props.itemsPerPage)
    const totalData = computed(() => props.totalItems !== -1 ? props.totalItems : props.items.length)
    // eslint-disable-next-line no-undef
    const tableColumns = computed<TableHeader<unknown>[]>(() => {
      if (props.headers && props.headers.length > 0) {
        return props.headers
      }

      if (props.items.length === 0) {
        return []
      }

      // eslint-disable-next-line no-undef
      return Object.keys(props.items[0] as object).map<TableHeader<unknown>>(key => ({
        text: formatToTableHeader(key),
        value: key
      }), [])
    })
    const sortedRows = computed<unknown[]>(() => {
      let sortedRows = props.items

      if (internalSortBy.value && !props.serverSort) {
        // eslint-disable-next-line no-undef
        const defaultSortMethod: TableHeader<unknown>['sort'] = (a, b) => {
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          const _a = resolveDotPath((a as object), internalSortBy.value!)
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          const _b = resolveDotPath((b as object), internalSortBy.value!)

          if (_a === _b) {
            return 0
          } else if (_a === null) {
            return 1
          } else if (_b === null) {
            return -1
          } else {
            if (typeof _a === 'number' && typeof _b === 'number') {
              return _a - _b
            } else if (typeof _a === 'string' && !Number.isNaN(Date.parse(_a)) && typeof _b === 'string' && !Number.isNaN(Date.parse(_b))) {
              console.debug('TABLE: compared as date strings')
              return Date.parse(_a) - Date.parse(_b)
            } else if (_a instanceof Date && _b instanceof Date) {
              console.debug('TABLE: compared as dates')
              return _a.valueOf() - _b.valueOf()
            } else {
              return (_a as object).toString().localeCompare((_b as object).toString())
            }
          }
        }
        const columnSortMethod = tableColumns.value.find(c => c.value === internalSortBy.value)?.sort

        sortedRows = sortedRows.slice().sort(typeof columnSortMethod !== 'undefined' ? columnSortMethod : defaultSortMethod)

        if (internalSortDesc.value) {
          sortedRows.reverse()
        }
      }

      return sortedRows
    })
    const paginationTop = computed<boolean>(() => props.paginationView === 'top' || props.paginationView === 'both')
    const paginationBottom = computed<boolean>(() => props.paginationView === 'bottom' || props.paginationView === 'both')

    /**
     *
     * @param index
     * @param page 0-indexed (Page 1 = 0)
     * @param itemsPerPage
     */
    function isIndexWithinPage(index: number, page: number, itemsPerPage: number): boolean {
      const lowestIndexOfPage = page * itemsPerPage
      const highestIndexOfPage = ((page + 1) * itemsPerPage) - 1
      return index >= lowestIndexOfPage && index <= highestIndexOfPage
    }

    const tableRows = computed<unknown[]>({
      get: () => {
        return sortedRows.value.filter((row, index) => props.serverPagination || internalItemsPerPage.value === -1 || isIndexWithinPage(index, internalPage.value, internalItemsPerPage.value))
      }, set: (value) => {
        context.emit('update:items', value)
      }
    })
    const paginatedRowIndex = computed(() => internalItemsPerPage.value * internalPage.value)
    const hasClickRowListener = computed<boolean>(() => Boolean(context.attrs['onClick:row']).valueOf())

    function getItemText(row: unknown, key: string) {
      return typeof row === 'object' ? resolveDotPath(row as object, key) : row
    }

    function resolveDotPath(object: object, path: string): unknown {
      try {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        return path.split('.').reduce((o, i) => o[i], object)
      } catch (e) {
        return ''
      }
    }

    function formatToTableHeader(value: string): string {
      const formattedStr = value.toLowerCase().replace(/[-_]/g, ' ')

      return formattedStr.split(' ').map(word => {
        return (word.charAt(0).toUpperCase() + word.slice(1))
      }).join(' ')
    }

    const uniqueId = () => Math.floor(Math.random() * 100000)

    const rowClickHandler = (row: unknown): void => {
      if (context.attrs['onClick:row']) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        context.emit('click:row', row)
      }
    }

    function setSort(sortBy: string | undefined, sortDesc: boolean) {
      internalSortBy.value = sortBy
      internalSortDesc.value = sortDesc
      internalPage.value = 0
    }

    watch(() => internalSortBy.value, () => {
      context.emit('update:sort-by', internalSortBy.value)
    })

    watch(() => props.sortBy, () => {
      internalSortBy.value = props.sortBy
    })

    watch(() => internalSortDesc.value, () => {
      context.emit('update:sort-desc', internalSortDesc.value)
    })

    watch(() => props.sortDesc, () => {
      internalSortDesc.value = props.sortDesc
    })

    watch(() => internalPage.value, () => {
      context.emit('update:page', internalPage.value)
    })

    watch(() => props.page, () => {
      internalPage.value = props.page
    }, { immediate: true })

    watch(() => internalItemsPerPage.value, () => {
      context.emit('update:itemsPerPage', internalItemsPerPage.value)
    })

    watch(() => props.itemsPerPage, () => {
      internalItemsPerPage.value = props.itemsPerPage
    })

    provide<SortableColumn>(SortableColumnInjectionKey, {
      sortBy: internalSortBy,
      sortDesc: internalSortDesc,
      setSorting: setSort
    })

    return {
      totalData,
      tableRows,
      tableColumns,
      paginatedRowIndex,
      internalPage,
      internalItemsPerPage,
      uniqueId,
      hasClickRowListener,
      rowClickHandler,
      setSort,
      getItemText,
      paginationTop,
      paginationBottom
    }
  }
})
</script>
