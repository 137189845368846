import { RepositoryError } from '@ctr-ecs/common/model/exception/repository-exception'
import { AxiosError } from 'axios'

function mapErrorToRepositoryError(e: unknown): RepositoryError {
  if ((e as AxiosError).isAxiosError) {
    const axiosError = e as AxiosError<{ message?: string, cause?: string }>
    return new RepositoryError(axiosError.response?.data.message || axiosError.message, axiosError.response?.data.cause || undefined)
  } else if (e instanceof Error) {
    return new RepositoryError(e.message)
  } else if (typeof e === 'object' && e != null) {
    return new RepositoryError(e.toString())
  } else if (typeof e === 'string') {
    return new RepositoryError(e)
  } else {
    return new RepositoryError()
  }
}

export async function rethrowAsRepositoryError<T>(f: (() => T) | (() => Promise<T>)): Promise<T> {
  try {
    return await f()
  } catch (e) {
    throw mapErrorToRepositoryError(e)
  }
}
