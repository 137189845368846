import App from '@ctr-ecs/application/App.vue'
import '@ctr-ecs/application/assets/fonts/inter/inter.css'
import { i18n } from '@ctr-ecs/application/i18n/plugin'
import { createPiniaI18nPlugin } from '@ctr-ecs/application/pinia-plugins/i18n'
import { initialize as initializeRepositories } from '@ctr-ecs/application/repository/bootstrap-repositories'
import router from '@ctr-ecs/application/router/router'
import { registerGlobalRules as registerGlobalValidationRules } from '@ctr-ecs/application/validation/register-global-rules'
import { registerI18nMessages as registerI18nValidationMessages } from '@ctr-ecs/application/validation/register-i18n-messages'
import { createAuth } from '@ctr-ecs/auth/plugin/plugin'
import { commonComponents } from '@ctr-ecs/common/plugin/plugin'
import { notifications } from '@ctr-ecs/notifications/plugin/plugin'
import { uiComponents } from '@ctr-ecs/ui/plugin/plugin'
import '@material-design-icons/font'
import { init as initialzeSentry } from '@sentry/vue'
import { createPinia } from 'pinia'

import { createApp } from 'vue'
import { QueryClient } from 'vue-query'
// eslint-disable-next-line boundaries/no-unknown
import './index.css'

initializeRepositories()

const pinia = createPinia()
pinia.use(createPiniaI18nPlugin(i18n))
const app = createApp(App, { queryClient: new QueryClient({}) })
initialzeSentry({
  app,
  dsn: import.meta.env.VITE_SENTRY_DSN,
  environment: import.meta.env.VITE_SENTRY_ENVIRONMENT,
  release: import.meta.env.PACKAGE_VERSION,
  logErrors: import.meta.env.DEV
})

createAuth().then(auth => {
  app.use(auth, { router })
     .use(router)
     .use(i18n)
     .use(pinia)
     .use(notifications)
     .use(commonComponents)
     .use(uiComponents)

  registerGlobalValidationRules()
  registerI18nValidationMessages()

  app.mount('#mounting-point')
})
