import { initialize as initializeCommonEthicsCommissionQueries } from '@ctr-ecs/common/queries/ethics-commission-queries'
import { initialize as initializeCommonMeetingQueries } from '@ctr-ecs/common/queries/meeting-queries'
import { initialize as initializeCommonTrialsQueries } from '@ctr-ecs/common/queries/trial-queries'
import { initialize as initializeCommonUserQueries } from '@ctr-ecs/common/queries/user-queries'
import { initialize as initializeCommonTasksQueries } from '@ctr-ecs/common/queries/task-queries'
import { initialize as initializeMeetingQueries } from '@ctr-ecs/meetings/queries'
import * as repositoryImplementations from '@ctr-ecs/repository'
import { initialize as initializeTasksQueries } from '@ctr-ecs/tasks/queries'
import { initialize as initializeTrialsQueries } from '@ctr-ecs/trials/queries'
import { initialize as initializeAdminQueries } from '@ctr-ecs/admin/queries'
import { initialize as initializeCommonWorkflowQueries } from '@ctr-ecs/common/queries/workflow-queries'
import { initialize as initializeWorkflowQueries } from '@ctr-ecs/workflows/queries'

export function initialize() {
  initializeTasksQueries(repositoryImplementations.tasksRepository, repositoryImplementations.commonWorkflowRepository)
  initializeCommonTasksQueries(repositoryImplementations.commonTaskRepository)
  initializeTrialsQueries(repositoryImplementations.trialRepository)
  initializeCommonTrialsQueries(repositoryImplementations.commonTrialRepository)
  initializeCommonEthicsCommissionQueries(repositoryImplementations.ethicsCommissionRepository)
  initializeMeetingQueries(repositoryImplementations.meetingRepository)
  initializeCommonMeetingQueries(repositoryImplementations.commonMeetingRepository)
  initializeCommonUserQueries(repositoryImplementations.commonUserRepository)
  initializeAdminQueries(repositoryImplementations.adminRepository)
  initializeCommonWorkflowQueries(repositoryImplementations.commonWorkflowRepository)
  initializeWorkflowQueries(repositoryImplementations.workflowRepository)
}
