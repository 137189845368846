import { useQuery } from 'vue-query'
import { WorkflowModel } from '@ctr-ecs/common/model/workflow-model'
import { RepositoryError } from '@ctr-ecs/common/model/exception/repository-exception'
import { useNotifications } from '@ctr-ecs/notifications/use/useNotifications'
import { CommonWorkflowRepository } from '@ctr-ecs/common/repository'
import { Ref, unref } from 'vue'
import { PageableModel } from '@ctr-ecs/common/model/pageable-model'
import { PageableQueryModel } from '@ctr-ecs/common/model/pageable-query-model'

let _repository: CommonWorkflowRepository

export function initialize(commonWorkflowRepository: CommonWorkflowRepository) {
  _repository = commonWorkflowRepository
}

export function useWorkflowsQuery(pageableQuery?: PageableQueryQueryParam<PageableQueryModel>, opts?: { enabled: Ref<boolean> | boolean }) {
  const queryKey = ['workflows', pageableQuery]

  return useQuery<PageableModel<WorkflowModel>, RepositoryError>(queryKey, () => {
    return _repository.getWorkflows(pageableQuery ? unref(pageableQuery) : { size: -1 })
  }, {
    staleTime: 1000 * 60,
    keepPreviousData: true,
    enabled: opts?.enabled,
    retry: 3,
    onError: (err) => {
      useNotifications().warning(err.message)
    }
  })
}

export function useWorkflowQuery(id: number | Ref<number>, opts?: { enabled: Ref<boolean> | boolean }) {
  const queryKey = ['workflow', id]

  return useQuery<WorkflowModel, RepositoryError>(queryKey, () => {
    return _repository.getWorkflow(unref(id))
  }, {
    staleTime: 1000 * 60,
    keepPreviousData: true,
    enabled: opts?.enabled,
    retry: 3,
    onError: (err) => {
      useNotifications().warning(err.message)
    }
  })
}