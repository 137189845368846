import { RepositoryError } from '@ctr-ecs/common/model/exception/repository-exception'
import { CommonUserRepository } from '@ctr-ecs/common/repository'
import { useNotifications } from '@ctr-ecs/notifications/use/useNotifications'
import { useMutation, useQuery, useQueryClient } from 'vue-query'
import { UserDetailModel } from '@ctr-ecs/common/model/user-detail-model'
import { NotificationModel } from '@ctr-ecs/common/model/notification-model'
import { useEventSource } from '@vueuse/core'
// eslint-disable-next-line boundaries/element-types
import { API_URL } from '@ctr-ecs/repository'
import { watch } from 'vue'

let _repository: CommonUserRepository

export function initialize(repository: CommonUserRepository) {
  _repository = repository
}

export function useUserDetailQuery() {
  const queryKey = 'user-detail'

  return useQuery<UserDetailModel, RepositoryError>(queryKey, () => {
    return _repository.getCurrentUserDetails()
  }, {
    staleTime: 1000 * 60,
    retry: 3,
    onError: (err) => {
      useNotifications().warning(err.message)
    }
  })
}

export function useUserNotificationsQuery() {
  const queryKey = 'user-notifications'

  return useQuery<NotificationModel[], RepositoryError>(queryKey, () => {
    return _repository.getCurrentUserNotifications()
  }, {
    staleTime: 1000 * 60,
    retry: 3,
    onError: (err) => {
      useNotifications().warning(err.message)
    }
  })
}

export function useHasNewNotificationQuery(userId: string) {
  const queryClient = useQueryClient()
  const { data } =
      useEventSource(API_URL + '/api/v1/users/me/notifications/updates?userId=' + userId)
  watch(data, () => {
    if (data.value?.length) {
      queryClient.invalidateQueries('user-notifications')
      queryClient.invalidateQueries('user-archived-notifications')
    }
  })
}

export function useUserArchivedNotificationsQuery() {
  const queryKey = 'user-archived-notifications'

  return useQuery<NotificationModel[], RepositoryError>(queryKey, () => {
    return _repository.getCurrentUserNotificationArchive()
  }, {
    staleTime: 1000 * 60,
    retry: 3,
    onError: (err) => {
      useNotifications().warning(err.message)
    }
  })
}

export function useMarkNotificationAsReadQuery() {
  const queryClient = useQueryClient()

  return useMutation<void, RepositoryError, number>('mark-notification-as-read', (notificationId: number) => {
    return _repository.markNotificationAsRead(notificationId)
  }, {
    async onSuccess() {
      queryClient.invalidateQueries('user-notifications')
      queryClient.invalidateQueries('user-archived-notifications')
    }
  })
}

export function useMarkNotificationAsUnreadQuery() {
  const queryClient = useQueryClient()

  return useMutation<void, RepositoryError, number>('mark-notification-as-unread', (notificationId: number) => {
    return _repository.markNotificationAsUnread(notificationId)
  }, {
    async onSuccess() {
      queryClient.invalidateQueries('user-notifications')
      queryClient.invalidateQueries('user-archived-notifications')
    }
  })
}
