export enum ROUTE {
  LOGIN = '/login',
  TASKS = '/',
  TASK_CREATE = '/tasks/create',
  TASK_EDIT = '/tasks/:id(\\d+)/edit',
  TRIALS = '/trials',
  TRIAL_DETAIL = '/trials/:id',
  TRIAL_DETAIL_NOTIFICIATION = 'notification',
  TRIAL_DETAIL_ALLDOCS = 'all-documents',
  TRIAL_APPLICATION = '/trials/applications/:id',
  TRIAL_APPLICATION_DETAIL = 'detail/:sections*',
  TRIAL_APPLICATION_DOCUMENTS = 'documents',
  TRIAL_APPLICATION_EVALUATION = 'evaluation/:evaluationSection(validation|assessment-part-1|assessment-part-2)?',
  TRIAL_APPLICATION_TASK = 'tasks',
  TRIAL_APPLICATION_ASSIGNEES = 'assignees',
  ADMIN = '/admin',
  ADMIN_USER_CREATE = 'user/create',
  ADMIN_USER_EDIT = 'user/:id',
  MEETINGS = '/meetings',
  MEETING_CREATE = '/meetings/create',
  MEETING_EDIT = '/meetings/:id',
  WORKFLOWS = '/workflows',
  WORKFLOW_CREATE = '/workflows/create',
  WORKFLOW_EDIT = '/workflows/:id',
  WORKFLOW_TASK_EDIT = '/workflows/:id/task/:taskId',
  WORKFLOW_TASK_CREATE = '/workflows/:id/task/create',
}

export enum ROUTE_NAME {
  LOGIN = 'login',
  TRIALS = 'trials',
  TRIAL_DETAIL = 'trial-detail',
  ADMIN = 'admin',
  ADMIN_USER_CREATE = 'admin-user-create',
  ADMIN_USER_EDIT = 'admin-user-edit',
  TASKS = 'tasks',
  TASK_CREATE = 'task-create',
  TASK_EDIT = 'task-edit',
  TRIAL_DETAIL_SUMMARY = 'trial-detail-summary',
  TRIAL_DETAIL_NOTIFICIATION = 'notification',
  TRIAL_DETAIL_ALLDOCS = 'all-documents',
  TRIAL_APPLICATION = 'trial-application',
  TRIAL_APPLICATION_DETAIL = 'trial-application-detail',
  TRIAL_APPLICATION_DOCUMENTS = 'trial-application-documents',
  TRIAL_APPLICATION_EVALUATION = 'trial-application-evaluation',
  TRIAL_APPLICATION_TASK = 'trial-application-task',
  TRIAL_APPLICATION_ASSIGNEES = 'trial-application-assignees',
  MEETINGS = 'meetings',
  MEETING_CREATE = 'meeting-create',
  MEETING_EDIT = 'meeting-edit',
  WORKFLOWS = 'workflows',
  WORKFLOW_CREATE = 'workflow-create',
  WORKFLOW_EDIT = 'workflow-edit',
  WORKFLOW_TASK_EDIT = 'workflow-task-edit',
  WORKFLOW_TASK_CREATE = 'workflow-task-create',
}
