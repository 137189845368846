import { TaskModel } from '@ctr-ecs/common/model/task-model'
import { TaskUserModel } from '@ctr-ecs/common/model/task-user-model'
import { PageableTasksQueryModel } from '@ctr-ecs/tasks/model/pageable-tasks-query-model'
import { Ref, unref } from 'vue'
import { useQuery, useQueryClient } from 'vue-query'
import { useMutation } from 'vue-query/esm'
import { RepositoryError } from '../common/model/exception/repository-exception'
import { PageableModel } from '../common/model/pageable-model'
import { useNotifications } from '../notifications/use/useNotifications'
import { CreateTaskModel } from './model/create-task-model'
import { UpdateTaskModel } from './model/update-task-model'
import { TasksRepository } from './repository'
import { TaskEvaluationModel } from '@ctr-ecs/tasks/model/task-evaluation-model'
import { UpdateTaskEvaluationModel } from '@ctr-ecs/tasks/model/update-task-evaluation-model'
import { GenerateWorkflowModel } from '@ctr-ecs/common/model/generate-workflow-model'
import { TaskDependencyModel } from '@ctr-ecs/common/model/task-dependency-model'
import { CommonWorkflowRepository } from '@ctr-ecs/common/repository'
import { TaskDependencyEvaluationModel } from '@ctr-ecs/common/model/task-dependency-evaluation-model'
import { TaskDependencyValidationModel } from '@ctr-ecs/common/model/task-dependency-validation-model'
import { TaskActivityModel } from '@ctr-ecs/tasks/model/task-activity-model'

let _repository: TasksRepository
let _workflowRepository: CommonWorkflowRepository

export function initialize(repository: TasksRepository, workflowRepository: CommonWorkflowRepository) {
  _repository = repository
  _workflowRepository = workflowRepository
}

export function useTasksQuery(pageableQuery: PageableQueryQueryParam<PageableTasksQueryModel>) {
  const queryKey = ['tasks', pageableQuery]
  const queryClient = useQueryClient()

  return useQuery<PageableModel<TaskDependencyValidationModel>, RepositoryError>(queryKey, () => {
    return _repository.getTasks(unref(pageableQuery))
  }, {
    staleTime: 1000 * 60,
    keepPreviousData: true,
    retry: 3,
    onError: (err) => {
      useNotifications().warning(err.message)
    },
    onSuccess(data) {
      // set data of each individual task query
      data.items.forEach(taskDependencyValidation => {
        // toString() required because vue-query serializes refs in query keys
        queryClient.setQueryData(['task', taskDependencyValidation.task.id.toString()], taskDependencyValidation.task)
      })
    }
  })
}

export function useTaskQuery(id: number | Ref<number>) {
  const queryKey = ['task', id]

  return useQuery<TaskModel, RepositoryError>(queryKey, () => {
    return _repository.getTask(unref(id))
  }, {
    staleTime: 1000 * 60,
    retry: 3,
    onError: (err) => {
      useNotifications().warning(err.message)
    }
  })
}

export function useCreateTaskMutation() {
  const queryClient = useQueryClient()

  return useMutation<TaskModel, RepositoryError, CreateTaskModel>('createTask', (task: CreateTaskModel) => {
    return _repository.createTask(task)
  }, {
    async onSuccess() {
      await queryClient.invalidateQueries(['tasks'])
    }
  })
}

export function useUpdateTaskMutation() {
  const queryClient = useQueryClient()

  return useMutation<TaskModel, RepositoryError, UpdateTaskModel>('mutateTask', (task: UpdateTaskModel) => {
    return _repository.updateTask(task)
  }, {
    async onSuccess(data, variables) {
      await queryClient.invalidateQueries(['tasks'])
      await queryClient.invalidateQueries(['task-activities', data.id.toString()])
      queryClient.setQueryData(['task', variables.id.toString()], data)
    }
  })
}

export function useUpdateTaskDoneMutation() {
  const queryClient = useQueryClient()

  return useMutation<TaskModel, RepositoryError, { taskId: number, done: boolean }>('mutateTaskDone', (args: {
    taskId: number,
    done: boolean
  }) => {
    return _repository.updateDone(args.taskId, args.done)
  }, {
    async onSuccess(data, variables) {
      await queryClient.invalidateQueries(['tasks'])
      await queryClient.invalidateQueries(['task-activities', data.id.toString()])
      queryClient.setQueryData(['task', variables.taskId.toString()], data)
    }
  })
}

export function useDeleteTaskMutation(id: number | Ref<number>) {
  const queryClient = useQueryClient()
  const queryKey = ['task', id]

  return useMutation<void, RepositoryError>(queryKey, () => {
    return _repository.deleteTask(unref(id))
  }, {
    retry: 2,
    onError: (err) => {
      useNotifications().warning(err.message)
    },
    async onSuccess() {
      await queryClient.invalidateQueries(['tasks'])
      await queryClient.invalidateQueries(['task', id])
      await queryClient.invalidateQueries(['task-activities', id.toString()])
    }
  })
}

export function useAssignableUsersQuery(ecId?: number | Ref<number>) {
  const queryKey = typeof ecId === 'undefined' ? 'task-assignable-users' : ['task-assignable-users', ecId]

  return useQuery<TaskUserModel[], RepositoryError>(queryKey, () => {
    return _repository.getAssignableUsers(unref(ecId))
  }, {
    staleTime: 1000 * 60 * 5,
    retry: 3,
    onError: (err) => {
      useNotifications().warning(err.message)
    }
  })
}

export function useTaskEvaluationQuery(taskId: number | Ref<number>) {
  const queryKey = ['task-evaluation', taskId.toString()]

  return useQuery<TaskEvaluationModel, RepositoryError>(queryKey, () => {
    return _repository.getTaskEvaluation(unref(taskId))
  }, {
    staleTime: 1000 * 60 * 5,
    retry: 3,
    onError: (err) => {
      useNotifications().warning(err.message)
    }
  })
}

export function useTaskPredecessorsQuery(taskId: number | Ref<number>) {
  const queryKey = ['task-predecessors', unref(taskId)]
  return useQuery<TaskDependencyModel[], RepositoryError>(queryKey, () => {
    return _repository.getPredecessors(unref(taskId))
  }, {
    staleTime: 1000 * 60,
    retry: 3,
    onError: (err) => {
      useNotifications().warning(err.message)
    }
  })
}

export function useTaskSuccessorsQuery(taskId: number | Ref<number>) {
  const queryKey = ['task-successors', unref(taskId)]
  return useQuery<TaskDependencyModel[], RepositoryError>(queryKey, () => {
    return _repository.getSuccessors(unref(taskId))
  }, {
    staleTime: 1000 * 60,
    retry: 3,
    onError: (err) => {
      useNotifications().warning(err.message)
    }
  })
}

export function useUpdateTaskEvaluationMutation() {
  const queryClient = useQueryClient()

  return useMutation<TaskEvaluationModel, RepositoryError, UpdateTaskEvaluationModel>('mutateTaskEvaluation', (task: UpdateTaskEvaluationModel) => {
    return _repository.updateEvaluation(task)
  }, {
    async onSuccess(data, variables) {
      await queryClient.invalidateQueries(['task-activities', variables.id.toString()])
      queryClient.setQueryData(['task-evaluation', variables.id.toString()], data)
    }
  })
}

export function useUpdateTaskPredecessorsMutation() {
  const queryClient = useQueryClient()

  return useMutation<TaskModel[], RepositoryError, { taskId: number, predecessorIds: number[] }>('updateTaskPredecessors', (data) => {
    return _repository.updatePredecessorsOfTask(data.taskId, data.predecessorIds)
  }, {
    async onSuccess(data, variables) {
      await queryClient.invalidateQueries(['task-predecessors', variables.taskId.toString()])
      await queryClient.invalidateQueries(['task-activities', variables.taskId.toString()])
    }
  })
}

export function useUpdateTaskSuccessorsMutation() {
  const queryClient = useQueryClient()

  return useMutation<TaskModel[], RepositoryError, { taskId: number, successorIds: number[] }>('updateTaskSuccessors', (data) => {
    return _repository.updateSuccessorsOfTask(data.taskId, data.successorIds)
  }, {
    async onSuccess(data, variables) {
      await queryClient.invalidateQueries(['task-successors', variables.taskId.toString()])
      await queryClient.invalidateQueries(['task-activities', variables.taskId.toString()])
    }
  })
}

export function useGenerateTasksViaWorkflowMutation() {
  const queryClient = useQueryClient()

  return useMutation<TaskModel[], RepositoryError, GenerateWorkflowModel>('mutateTaskViaWorkflow', (model) => {
    return _workflowRepository.generateTasks(model)
  }, {
    onSuccess(data) {
      data.forEach(task => queryClient.setQueryData(['task', task.id.toString()], task))
    },
    onError: (err) => {
      useNotifications().warning(err.message)
    }
  })
}

export function useTaskPrecedingEvaluationQuery(taskId: number | Ref<number>) {
  const queryKey = ['task-preceding-evaluations', unref(taskId)]
  return useQuery<TaskDependencyEvaluationModel[], RepositoryError>(queryKey, () => {
    return _repository.getPrecedingEvaluations(unref(taskId))
  }, {
    staleTime: 1000 * 60,
    retry: 3,
    onError: (err) => {
      useNotifications().warning(err.message)
    }
  })
}

export function useTaskActivitiesQuery(taskId: number | Ref<number>) {
  const queryKey = ['task-activities', unref(taskId)]
  return useQuery<TaskActivityModel[], RepositoryError>(queryKey, () => {
    return _repository.getActivities(unref(taskId))
  }, {
    retry: 3,
    onError: (err) => {
      useNotifications().warning(err.message)
    }
  })
}

export function useCTRSuccessorsTaskQuery(taskId: number | Ref<number>) {
  const queryKey = ['ctr-successors', unref(taskId)]
  return useQuery<TaskDependencyModel[], RepositoryError>(queryKey, () => {
    return _repository.getCTRECSTasksToChange(unref(taskId))
  }, {
    retry: 3,
    onError: (err) => {
      useNotifications().warning(err.message)
    }
  })
}

export function useSendReminderMutation(taskId: number | Ref<number>) {
  const queryClient = useQueryClient()

  return useMutation<void, RepositoryError, number>('sendReminder', (taskId) => {
    return _repository.sendReminderToAssignedUsers(taskId)
  }, {
    async onSuccess() {
      await queryClient.invalidateQueries(['task', taskId.toString()])
    }
  })
}