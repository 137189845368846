import adminEn from '@ctr-ecs/admin/i18n/en.json'
import commonEn from '@ctr-ecs/common/i18n/en.json'
import sessionsEn from '@ctr-ecs/meetings/i18n/en.json'
import tasksEn from '@ctr-ecs/tasks/i18n/en.json'
import trialsEn from '@ctr-ecs/trials/i18n/en.json'
import uiEn from '@ctr-ecs/ui/plugin/i18n/en.json'
import workflowEn from '@ctr-ecs/workflows/i18n/en.json'
// import hook

const en = {}
Object.assign(en, commonEn)
Object.assign(en, adminEn)
Object.assign(en, tasksEn)
Object.assign(en, trialsEn)
Object.assign(en, sessionsEn)
Object.assign(en, uiEn)
Object.assign(en, workflowEn)
// assign hook

export default en
