<template>
  <layout-manager>
    <router-view></router-view>

    <notification-group>
      <div class="fixed inset-0 flex flex-col px-4 py-6 pointer-events-none p-6 justify-start items-end">
        <notifications v-slot="{ notifications, close }">
          <dw-notification v-for="n in notifications" :key="n.id" @close="close(n.id)" :message="n.message" :type="n.type"/>
        </notifications>
      </div>
    </notification-group>
  </layout-manager>
</template>

<script lang="ts">
import LayoutManager from '@ctr-ecs/application/layout/layout-manager.vue'
import { setLocale } from '@vee-validate/i18n'
import { defineComponent, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { QueryClient, useQueryProvider } from 'vue-query'

export default defineComponent({
  components: { LayoutManager },
  props: {
    queryClient: {
      type: QueryClient,
      required: true
    }
  },
  setup(props) {
    const i18n = useI18n({ inheritLocale: true })

    useQueryProvider(props.queryClient)

    watch(i18n.locale, () => {
      if (import.meta.env.DEV) {
        console.debug('changed locale to ' + i18n.locale.value)
      }
      setLocale((i18n.locale.value as string).split('-')[0])
    }, { immediate: true })
  }
})
</script>
