import en from '@ctr-ecs/application/validation/messages/en.json'
import { localize } from '@vee-validate/i18n'
import { configure } from 'vee-validate'

export function registerI18nMessages() {
  configure({
    generateMessage: localize({
      en
    })
  })
}
