import { Ref, unref } from 'vue'
import { useQuery } from 'vue-query'
import { RepositoryError } from '@ctr-ecs/common/model/exception/repository-exception'
import { useNotifications } from '@ctr-ecs/notifications/use/useNotifications'
import { CommonTaskRepository } from '@ctr-ecs/common/repository'
import { TaskDependencyModel } from '@ctr-ecs/common/model/task-dependency-model'
import { TaskModel } from '@ctr-ecs/common/model/task-model'
import { MaybeRef } from '@vueuse/core'
import { ApplicationTasksQueryModel } from '@ctr-ecs/common/model/application-tasks-query-model'

let _repository: CommonTaskRepository

export function initialize(repository: CommonTaskRepository) {
  _repository = repository
}

export function useTasksPredecessorsQuery(taskIds: number[] | Ref<number[]>, opts: { enabled: boolean | Ref<boolean> }) {
  const queryKey = ['tasks-predecessors', taskIds]

  return useQuery<TaskDependencyModel[], RepositoryError>(queryKey, () => {
    return _repository.getTasksPredecessors(unref(taskIds))
  }, {
    staleTime: 1000 * 60,
    retry: 3,
    enabled: opts.enabled,
    onError: (err) => {
      useNotifications().warning(err.message)
    }
  })
}

export function useApplicationTasksQuery(request: MaybeRef<ApplicationTasksQueryModel>, opts: { enabled: boolean | Ref<boolean> }) {
  const queryKey = ['application-task', request]

  return useQuery<TaskModel[], RepositoryError>(queryKey, () => {
    return _repository.getApplicationTasks(unref(request))
  }, {
    staleTime: 1000 * 60,
    retry: 3,
    enabled: opts.enabled,
    onError: (err) => {
      useNotifications().warning(err.message)
    }
  })
}