import axios from 'axios'
import { PermissionsModel } from '../models/permissions-model'
import { UserWithPermissionsModel } from '../models/user-with-permissions-model'

export interface GatewaySettings {
  baseUrl: string,
  accessToken?: string
}

interface UserModel {
  id: string,
  email: string,
  name?: string,
}

interface UserProfileModel {
  user: UserModel,
  permissions: string[]
}

export class APIGateway {
  constructor(private settings: GatewaySettings) {
  }

  set accessToken(token: string | undefined) {
    this.settings.accessToken = token
  }

  async getMe(): Promise<UserWithPermissionsModel> {
    return APIGatewayMapper.mapUserProfileToUserWithPermissions((await axios.get<UserProfileModel>('/api/v1/users/me', {
      baseURL: this.settings.baseUrl,
      headers: { 'Authorization': 'Bearer ' + this.settings.accessToken }
    })).data)
  }
}

export class APIGatewayMapper {
  static mapUserProfileToUserWithPermissions(userProfile: UserProfileModel): UserWithPermissionsModel {
    return {
      id: userProfile.user.id,
      email: userProfile.user.email,
      name: userProfile.user.name,
      permissions: APIGatewayMapper.mapPermissionsArrayToPermissionsModel(userProfile.permissions)
    }
  }

  static mapPermissionsArrayToPermissionsModel(permissions: string[]): PermissionsModel {
    return {
      canEditTaskDependencies: permissions.includes('TASK_EDIT-DEPENDENCIES'),
      canFilterTasksByEthicsCommissions: permissions.includes('TASK_LIST-ALL'),
      canFilterTrialsByEthicsCommissions: permissions.includes('TRIALS_LIST-ALL'),
      canFilterTasksByAssignedUsers: permissions.includes('TASK_LIST-ALL') || permissions.includes('TASK_WITHIN-EC-LIST_ALL'),
      canCreateTask: permissions.includes('TASK_CREATE'),
      canDeleteTask: permissions.includes('TASK_DELETE'),
      canEditTaskApplication: permissions.includes('TASK_EDIT-APPLICATION'),
      canEditTaskAssignedTo: permissions.includes('TASK_EDIT-ASSIGNED-TO'),
      canEditTaskDescription: permissions.includes('TASK_EDIT-DESCRIPTION'),
      canEditTaskDescriptionOfAssignedTask: permissions.includes('TASK_ASSIGNED-TO-ME_EDIT-DESCRIPTION'),
      canEditTaskDueDate: permissions.includes('TASK_EDIT-DUEDATE'),
      canEditTaskEthicsCommission: permissions.includes('TASK_EDIT-ETHIC-COMMISSION'),
      canEditTaskMeeting: permissions.includes('TASK_EDIT-MEETING'),
      canEditTaskRequiredUsers: permissions.includes('TASK_EDIT-REQUIRED-ADDITIONAL-USERS'),
      canEditTaskRequiresSession: permissions.includes('TASK_EDIT-REQUIRES-SESSION'),
      canEditTaskTitle: permissions.includes('TASK_EDIT-TITLE'),
      canEditTaskTrial: permissions.includes('TASK_EDIT-TRIAL'),
      canMarkTaskAsDone: permissions.includes('TASK_MARK-AS-DONE'),
      canMarkTaskAsDoneWithinEc: permissions.includes('TASK_WITHIN-EC_MARK-AS-DONE'),
      canMarkTaskAsDoneAssignedToMe: permissions.includes('TASK_ASSIGNED-TO-ME_MARK-AS-DONE'),
      canEditTaskEvaluationAssignedToMe: permissions.includes('TASK_ASSIGNED-TO-ME_EDIT-EVALUATION'),
      canEditTaskEvaluationSameEc: permissions.includes('TASK_WITHIN-EC_EDIT-EVALUATION'),
      canEditTaskEvaluation: permissions.includes('TASK_EDIT-EVALUATION'),
      canSendReminder: permissions.includes('TASK_SEND_MANUAL_REMINDER'),

      canViewMeetings: permissions.includes('MEETING_LIST-ALL') || permissions.includes('MEETING_WITHIN-EC_LIST-ALL'),
      canCreateMeetings: permissions.includes('MEETING_CREATE') || permissions.includes('MEETING_WITHIN-EC_CREATE'),
      canCreateMeetingsWithAnyEthicsCommission: permissions.includes('MEETING_CREATE'),
      canUpdateMeetings: permissions.includes('MEETING_EDIT') || permissions.includes('MEETING_WITHIN-EC_EDIT'),
      canUpdateMeetingsWithAnyEthicsCommission: permissions.includes('MEETING_EDIT'),

      canAssignEthicsCommissionToTrial: permissions.includes('TRIALS_EC-EDIT'),
      canSynchronizeTrial: permissions.includes('TRIALS_SYNCHRONIZE-TRIAL'),
      canUploadApplicationDocuments: permissions.includes('TRIALS_UPLOAD-APPLICATION-DOCUMENTS'),
      canDeleteApplicationDocuments: permissions.includes('TRIALS_DELETE-APPLICATION-DOCUMENTS'),
      canEditTransitionalState: permissions.includes('TRIALS_EDIT-TRANSITIONAL'),
      canAssignUsersToApplication: permissions.includes('APPLICATION_ROLE_VIEW'),
      canViewApplicationEvaluation: permissions.includes('APPLICATION_EVALUATION_VIEW'),
      canViewApplicationTasks: permissions.includes('APPLICATION_TASKS_VIEW'),

      canDeleteConsiderations: permissions.includes('CONSIDERATIONS_DELETE'),
      canEditConsiderationTransferredToCtis: permissions.includes('CONSIDERATIONS_EDIT_TRANSFERRED-TO-CTIS'),
      canEditConsiderations: permissions.includes('CONSIDERATIONS_EDIT'),
      canEditDocumentCommentsTransferredToCtis: permissions.includes('DOCUMENT-COMMENTS_EDIT_TRANSFERRED-TO-CTIS'),

      canViewUsers: permissions.includes('USERS_VIEW'),
      canCreateUsers: permissions.includes('USERS_CREATE'),
      canEditUsers: permissions.includes('USERS_EDIT'),

      canGenerateTasksViaWorkflow: permissions.includes('WORKFLOW_GENERATE'),
      canViewWorkflows: permissions.includes('WORKFLOW_VIEW'),
      canFilterWorkflowsByEthicsCommissions: permissions.includes('WORKFLOW_LIST-ALL'),
      canEditWorkflows: permissions.includes('WORKFLOW_EDIT'),
      canEditWorkflowEthicsCommission: permissions.includes('WORKFLOW_EDIT-EC'),
      canDeleteWorkflows: permissions.includes('WORKFLOW_DELETE')
    }
  }
}
